import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const UserNoticeModal = ({ show, handleCloseNotice }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleCloseNotice} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t("MessageToUsers")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("notice")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseNotice}>
          {t("modal-close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserNoticeModal;
