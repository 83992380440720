import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function CanvasPopup(props) {
    const { t, i18n } = useTranslation(['translation']);
  return (
    <div>
      <Modal show={props.showCanvasPopupModal} onHide={() => props.setShowCanvasPopupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("app-noprojectnotice")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{t("app-open-start-project")}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShowCanvasPopupModal(false)}>
            {t("modal-close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              props.setUpload(true);
              props.setShowCanvasPopupModal(false);
            }}
          >
            {t("app-open-start-project")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              props.setAccount(true);
              props.setShowCanvasPopupModal(false);
            }}
          >
            {t("app-open-project")}
          </Button>
        </Modal.Footer>
      </Modal>

       </div>
  );
}

export default CanvasPopup;
