import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18nextConf';

const rootElement = document.getElementById('root');
const AppToRender = (
  <React.StrictMode>
    <Router>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </Router>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(AppToRender, rootElement);
} else {
  ReactDOM.render(AppToRender, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
