export const planLimits = {
    designer: {
      Free: {
        totalInteractions: 10, // Total interaction limit for free plan
        weeklyInteractions: 3  // Weekly interaction limit for free plan
      },
      Standard: {
        totalInteractions: 30,
        weeklyInteractions: 10
      },
      Pro: {
        totalInteractions: 50,
        weeklyInteractions: 15
      },
      Admin: {
        totalInteractions: "Unlimited",
        weeklyInteractions: "Unlimited"
      },
    }
  };

  export default planLimits