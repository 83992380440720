
import { v1 as uuid } from "uuid";

export const doorsDecorativeGlassArray = [ 
  {
    url: "/doors/decorativeGlass/background_Standard_326_948_NoDecGlass.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/background_Standard_326_711_NoDecGlass.png",
    key: uuid(),
  },
  
  {
    url: "/doors/decorativeGlass/Other_Buggati_Laiton_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Other_Amadeus_326_948.png",
    key: uuid(),
  }
  
 ];

    export default doorsDecorativeGlassArray;