import React from 'react'
import "../calibration/calibration.css"
import { useTranslation } from "react-i18next";

function CalibrationDisplay({ width, height, unit }) {
  const { t, i18n } = useTranslation(["translation"]);
  const measurementText = unit === 'inches' ? t('measurement-inch') : t('measurement-cm');
  
  return (
    <div className="dimension-display">
    {t("calibration-realwidth")} {width} {measurementText}
    <br/>{t("calibration-realheight")} {height} {measurementText}
  </div>
  )
}

export default CalibrationDisplay