import { fabric } from "fabric-with-erasing";
import { saveCanvasState } from "./helpers";
import { v1 as uuid } from "uuid";
import { getPsptFabricObj } from "./fabricPerspective";

//Function to add rectangle / triangle / circle shapes.
export function addShape(e, props) {
  var canvas = props.canvas;
  props.setIsOptionsPanelVisible(false);
  let type = e;
  let object;



  if (type === "rectangle") {
    object = new fabric.Rect({
      height: 75,
      width: 150,
      fill: props.itemColor,
    });



  } else if (type === "triangle") {
    object = new fabric.Triangle({
      width: 100,
      height: 100,
      fill: props.itemColor,
    });
  } else if (type === "circle") {
    object = new fabric.Circle({
      radius: 50,
      fill: props.itemColor,
    });
  }
  if (object) {
    object.set({
      id: uuid(),
      groupId: uuid(),
      left : canvas.getWidth() / 2,
      top:100,
  
      usePerspective: true,
    });

    if (type === "circle") {
      canvas.discardActiveObject().renderAll();
      canvas.add(object);
      canvas.bringToFront(object);
      props.setIsItemAdded(true);
      canvas.setActiveObject(object);
      canvas.requestRenderAll();
      saveCanvasState(canvas);
    } else {
      getPsptFabricObj(canvas, object).then((poly) => {
        canvas.discardActiveObject().renderAll();
        canvas.add(poly);
        canvas.bringToFront(poly);
        canvas.setActiveObject(poly);
        canvas.requestRenderAll();

        if (canvas.isPerspective) {
          object.visible = false;
          canvas.add(object);
        }
        saveCanvasState(canvas);
      });
    }
  }
}
