import React from 'react'
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "tippy.js/dist/tippy.css";
import "./itemsSelect/itemDisplaySelectionModal.css";
import { useTranslation } from "react-i18next";

function ItemSelection(props) {

  const { t, i18n } = useTranslation(["translation"]);

  const handleDropdownChange = (e) => {
    props.handlePanel(e);
  };

  return (
    <>
    <DropdownButton
        title={
          <img
            className='addItemsButton'
            src="./icons/addItemsButton.png"
            height="40px"
            alt="Items"
            title='Items'
          />
        }
        className="btn mt-1 btn-block pb-0 m-0"
        // id="dropdown-button-dark"
        variant="secondary"
        menuVariant="dark"
        size="sm"
        drop="end"
        
        onSelect={handleDropdownChange}
      >
        <div className='dropdownSection'>
        <div className='dropdownTitleSection'>{t("itemSelection-windows")}</div>
        <Dropdown.Item className='itemTypeTitle' eventKey="Casement">
        {t("itemSelection-casement")}
          <>
            <img
              className='addItemsIcons'
              src="./thumbnails/Window_Casement_Double.png"
              height={20}
              alt="Casement"
            />
          </>
        </Dropdown.Item>
        <Dropdown.Item className='itemTypeTitle' eventKey="Sash">
        {t("itemSelection-sash")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/Window_Sash_Double.png"
              height={20}
              alt="Sash"
            />
          </>
        </Dropdown.Item>
        <Dropdown.Item className='itemTypeTitle' eventKey="Slider">
        {t("itemSelection-slider")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/Window_Slider_Single.png"
              height={20}
              alt="Slider"
            />
          </>
        </Dropdown.Item>
        <Dropdown.Item className='itemTypeTitle' eventKey="Contemporary">
        {t("itemSelection-contemporary")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/Window_Contemporary_ThirdLeftDivMiddle.png"
              height={20}
              alt="Contemporary"
            />
          </>
        </Dropdown.Item>
        <Dropdown.Item className='itemTypeTitle' eventKey="Fixe">
        {t("itemSelection-fixed")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/Window_Fixed_HalfMoon.png"
              height={20}
              alt="Fixe"
            />
          </>
        </Dropdown.Item>
        <div className='dropdownTitleSection'>{t("itemSelection-doors")}</div>
        <Dropdown.Item className='itemTypeTitle'  eventKey="Single Door">
        {t("itemSelection-singleDoor")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/Single_Embossed1AGlassAt1NoSidelight.png"
              height={50}
              alt="Single Door"
            />
          </>
        </Dropdown.Item>
        
        <Dropdown.Item className='itemTypeTitle' eventKey="Double Door">
        {t("itemSelection-doubleDoor")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/double_Embossed1AGlassAt1NoSidelight.png"
              height={50}
              alt="Double Door"
            />
          </>
        </Dropdown.Item>

        <Dropdown.Item className='itemTypeTitle' eventKey="Garage Door">
        {t("itemSelection-garageDoor")}
          <>
            <img
            className='addItemsIcons'
              src="./thumbnails/GarageDoor_Classic_9x7.png"
              height={50}
              alt="Garage Door"
            />
          </>
        </Dropdown.Item>
        <div className='dropdownTitleSection'>{t("itemSelection-accessories")}</div>
        <Dropdown.Item className='itemTypeTitle' eventKey="Accessories">
  {t("itemSelection-accessories")}
    <>
      <img
        className='addItemsIcons'
        src="./thumbnails/shutter_standard_white.png" // Path to your accessories icon
        height={60}
        alt={t("itemSelection-accessories")}
      />
    </>
</Dropdown.Item>
      

</div>
        
      </DropdownButton>

      <div>

      </div>

  </>
);
}

export default ItemSelection;
