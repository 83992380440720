import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ContactFormModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch('/.netlify/functions/contactFormHandler', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData)
        });
  
        if (response.ok) {
          toast.success(t('contactForm.emailSentSuccess'));
          handleClose(); // Close the modal after successful submission
        } else {
          toast.error(t('contactForm.emailSentError'));
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error(t('contactForm.emailSentError'));
      }
    };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('contactForm.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>{t('contactForm.name')}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder={t('contactForm.namePlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('contactForm.email')}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t('contactForm.emailPlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('contactForm.company')}</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              placeholder={t('contactForm.companyPlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('contactForm.subject')}</Form.Label>
            <Form.Control
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder={t('contactForm.subjectPlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('contactForm.message')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t('contactForm.messagePlaceholder')}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {t('contactForm.send')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ContactFormModal;
