import { fabric } from "fabric-with-erasing";
import React, { useState, useEffect, useRef } from "react";
import { saveCanvasState } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import "../zoomAndPan/zoomAndPan.css";
import { Modal, Button } from 'react-bootstrap';

function ZoomAndPan(props) {
  const { t, i18n } = useTranslation(["translation"]);


  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchStartZoom, setTouchStartZoom] = useState(0);

  const [movementX, setMovementX] = useState(0);
  const [movementY, setMovementY] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const canvasWidth = props.canvasWidth;
  const canvasHeight = props.canvasHeight;
  const imageWidth = 5;
  const imageHeight = 20;

  const applyCenteredZoom = (canvas, newZoom) => {
    const oldZoom = canvas.getZoom();
    const viewport = canvas.viewportTransform;

  
    const zoomPoint = new fabric.Point(canvas.width / 2, canvas.height / 2);
  
    // Setting up new zoom
    const zoomX = zoomPoint.x - (zoomPoint.x - viewport[4]) * (newZoom / oldZoom);
    const zoomY = zoomPoint.y - (zoomPoint.y - viewport[5]) * (newZoom / oldZoom);
  
    viewport[0] = newZoom; // x zoom level
    viewport[3] = newZoom; // y zoom level
  
    viewport[4] = zoomX; // x pan level
    viewport[5] = zoomY; // y pan level
  
    canvas.setViewportTransform(viewport);
    canvas.renderAll();
  };
  
    const handleTouchStartZoom = (event) => {
    setTouchStartZoom(event.touches[0].clientX);
  };

  const handleTouchMoveZoom = (event) => {
    const touchX = event.touches[0].clientX;
    const deltaX = touchX - touchStartZoom;
    const newZoomLevel = zoomLevel + deltaX * 0.01;

    if (newZoomLevel >= 0.1 && newZoomLevel <= 3) {
      saveCanvasState(props.canvas, newZoomLevel);
      setZoomLevel(newZoomLevel);
      applyCenteredZoom(props.canvas, newZoomLevel);
    }
  };

  const handleTouchEndZoom = () => {
    setTouchStartZoom(0);
  };

  
  const handleTouchMoveX = (clientX) => {
    const deltaX = clientX - touchStartX;
    const updatedX = Math.min(
      Math.max(movementX + deltaX, -canvasWidth / 2 + imageWidth),
      canvasWidth / 2 - imageWidth
    );
    saveCanvasState(props.canvas);
    setMovementX(updatedX);
    props.canvas.relativePan(new fabric.Point(updatedX - movementX, 0));
  };
  
  const handleTouchEndX = () => {
    setTouchStartX(0);
  };
  
 
  const handleTouchMoveY = (clientY) => {
    const deltaY = clientY - touchStartY;
    const updatedY = Math.min(
      Math.max(movementY + deltaY, -canvasHeight / 2 + imageHeight),
      canvasHeight / 2 - imageHeight
    );
    saveCanvasState(props.canvas);
    setMovementY(updatedY);
    props.canvas.relativePan(new fabric.Point(0, updatedY - movementY));
  };
  
  const handleTouchEndY = () => {
    setTouchStartY(0);
  };

  const handleSliderChangeX = (event) => {
    const newX = parseInt(event.target.value);
    const maxMovementX = (canvasWidth - imageWidth) * 0.9;
    const updatedX = Math.min(
      Math.max(newX, -maxMovementX),
      maxMovementX
    );
    saveCanvasState(props.canvas);
    setMovementX(updatedX);
    props.canvas.relativePan(new fabric.Point(updatedX - movementX, 0));
  };

  const handleSliderChangeY = (event) => {
    const newY = parseInt(event.target.value);
    const maxMovementY = (canvasHeight - imageHeight) * 0.9;
    const updatedY = Math.min(
      Math.max(newY, -maxMovementY),
      maxMovementY
    );
    saveCanvasState(props.canvas);
    setMovementY(updatedY);
    props.canvas.relativePan(new fabric.Point(0, updatedY - movementY));
  };

  const handleSliderChangeZoom = (event) => {
    const newZoomLevel = parseFloat(event.target.value);
    saveCanvasState(props.canvas, newZoomLevel);
    setZoomLevel(newZoomLevel);
    applyCenteredZoom(props.canvas, newZoomLevel);
  };

  const handleResetSliders = () => {
    handleResetZoom();
    setMovementX(0);
    setMovementY(0);
    saveCanvasState(props.canvas);
    props.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };

  const handleResetZoom = () => {
    const defaultZoomLevel = 1;
    saveCanvasState(props.canvas, defaultZoomLevel);
    setZoomLevel(defaultZoomLevel);
    props.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    applyCenteredZoom(props.canvas, defaultZoomLevel);
  };

  useEffect(() => {
    handleResetZoom();
    handleResetSliders();
   
  }, [props.projectName]);

  return (
    <Modal backdrop={false} show={props.show} onHide={props.onHide} dialogClassName="zoom-pan-modal">
    <Modal.Header closeButton>
      <Modal.Title className="small-title">{t("app-zoomandpan")}</Modal.Title>
    </Modal.Header>
    
    <Modal.Body className="slider-container d-flex justify-content-between">

        <div className="slider-item">
          <input
            className="movement-x-slider"
            type="range"
            min={-canvasWidth / 2 + imageWidth}
            max={canvasWidth / 2 - imageWidth}
            value={movementX}
            onChange={handleSliderChangeX}
            onTouchStart={(e) => setTouchStartX(e.touches[0].clientX)}
            onTouchMove={(e) =>
              handleTouchMoveX(e.touches[0].clientX)
            }
            onTouchEnd={handleTouchEndX}
          />
          <label className="slider-label-x">{t("zoomAndPan-l-r")}</label>
        </div>
        <div className="slider-item">
          <input
            className="movement-y-slider"
            type="range"
            min={-canvasHeight / 2 + imageHeight}
            max={canvasHeight / 2 - imageHeight}
            value={movementY}
            onChange={handleSliderChangeY}
            onTouchStart={(e) => setTouchStartY(e.touches[0].clientY)}
            onTouchMove={(e) =>
              handleTouchMoveY(e.touches[0].clientY)
            }
            onTouchEnd={handleTouchEndY}
          />
          <label className="slider-label">{t("zoomAndPan-u-d")}</label>
        </div>
        <div className="slider-item">
          <input
            className="zoom-slider"
            type="range"
            min="0.1"
            max="3"
            step="0.1"
            value={zoomLevel}
            onChange={handleSliderChangeZoom}
            onTouchStart={handleTouchStartZoom}
            onTouchMove={handleTouchMoveZoom}
            onTouchEnd={handleTouchEndZoom}
          />
          <label className="slider-label">Zoom</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button  className="reset-button" onClick={handleResetSliders}>
          Reset
        </Button>
      </Modal.Footer>
    </Modal>

  );
}

export default ZoomAndPan;
