import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from "react-i18next";
import { accessoriesArray } from "../../components/itemSelectionView/inventory/accessoriesArray";

function AccessoriesSelection({ accessoryType, addItem, setIsAccessoryTypeSelected}) {
    const { t } = useTranslation();

    const filteredAccessories = accessoriesArray.filter(accessory => accessory.type === accessoryType);
  
    return (
        <>
       <div className='closeBtn'>
        <button className='btn btn-primary'
          onClick={() => {
            setIsAccessoryTypeSelected(false);
            document.body.style.overflow = 'auto';
          }}
        >
          {t('itemSelection-close')}
        </button>
      </div>
      <div className='selectionTitle'>{t('accessories-availableAccessories')}</div>
      <div className='list bg-transparent'>
        <ListGroup className='selectionListItems' variant='flush'>
            {filteredAccessories.length > 0 ? (
              filteredAccessories.map((accessory) => (
                <ListGroup.Item 
                  key={accessory.key} 
                  action 
                  onClick={() => addItem(accessory)}
                  className="bg-transparent accessory-item d-flex flex-column align-items-center"
                >
                    <div className="itemTypeTitle">{t(accessory.name)}</div> {/* Localized name */}
                    <img
                      src={process.env.PUBLIC_URL + accessory.url}
                      alt={t(accessory.name)} // Also localize alt text for accessibility
                      style={{ width: "50px", marginTop: "10px" }}
                    />
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item>{t("accessories-notfound")}</ListGroup.Item>
            )}
         </ListGroup>
         </div>
        </>
    );
}

export default AccessoriesSelection;
