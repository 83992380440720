import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EmailConfirmationSuccessModal = ({ show, onClose, setShowCanvasPopupModal }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(); // Presumably this would set 'show' to false in the parent component
    setShowCanvasPopupModal(true)
    window.history.pushState({}, document.title, "/");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('emailConfirmationSuccessTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('emailConfirmationSuccessMessage')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t('emailConfirmationSuccessButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailConfirmationSuccessModal;
