import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CollapsibleSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="collapsible-section">
            <button className="collapsible-title" onClick={() => setIsOpen(!isOpen)}>
                {title}
            </button>
            {isOpen && <div className="collapsible-content">{children}</div>}
        </div>
    );
};

const CollapsibleGallery = ({ items, titleKey }) => {
    const { t } = useTranslation();

    // Assuming items are already categorized
    return (
        <div className="gallery">
            {items.map((category, index) => (
                <CollapsibleSection key={index} title={t(category.title)}>
                    <div className="gallery-items">
                        {category.items.map((item, itemIndex) => (
                            <div key={itemIndex} className="gallery-item">
                                <LazyLoadImage
                                    src={item.url}
                                    alt={item.name || "Item"}
                                    effect="blur"
                                />
                                <p>{item.name}</p>
                            </div>
                        ))}
                    </div>
                </CollapsibleSection>
            ))}
        </div>
    );
};

export default CollapsibleGallery;
