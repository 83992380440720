// src/components/FacadeDesigner.js

import React, { useState, useRef, useEffect } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { useAuth, db } from "../../../base";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import planLimits from "../../../plans/plansLimits";
import './FacadeDesigner.css';

const FacadeDesigner = ({ projects, projectId, newProjectImage, resetNewProjectImage, userRole, isDesignerModalOpen, setDesignerModalOpen, handleAnalyticsEvent }) => {
  const { t } = useTranslation(["translation"]);
  const currentUser = useAuth();
  const textAreaRef = useRef(null);
  const responseContainerRef = useRef(null);
  const modalBodyRef = useRef(null);

  const [userInput, setUserInput] = useState('');
  const [assistantResponse, setAssistantResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [defaultQuestion, setDefaultQuestion] = useState('');
  const [isFirstInteraction, setIsFirstInteraction] = useState(true);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [imageFileId, setImageFileId] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const hints = t('hints', { returnObjects: true });
    if (hints && hints.length > 0) {
      setDefaultQuestion(hints[0]);
    }
  }, [t]);

  useEffect(() => {
    if (newProjectImage) {
      handleProjectSwitch(newProjectImage);
    } else if (projects && projectId) {
      const project = projects.find(p => p.id === projectId);
      if (project && project.projectJson) {
        const projectData = JSON.parse(project.projectJson);
        if (projectData && projectData.backgroundImage && projectData.backgroundImage.src) {
          console.log('Extracted URL from project:', projectData.backgroundImage.src);
          handleProjectSwitch(projectData.backgroundImage.src);
        }
      }
    }
  }, [projects, projectId, newProjectImage]);

  useEffect(() => {
    if (responseContainerRef.current) {
      responseContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assistantResponse]);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
    }
  }, [assistantResponse]);

  const uploadImage = async (imageUrl) => {
    try {
      setStatusMessage(t('assistant.statusUploading'));
      const toastId = toast.info(t('assistant.toastUploading'), { autoClose: false });

      const response = await fetch('/.netlify/functions/openAIImageUpload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      toast.update(toastId, { render: t('assistant.toastUploadComplete'), type: toast.TYPE.SUCCESS, autoClose: 5000 });
      return data.file_id;
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error(t('assistant.toastError'));
      setStatusMessage(t('assistant.statusError'));
      setIsLoading(false);
      throw error;
    }
  };

  const startConversation = async (userInput, fileId) => {
    try {
      setStatusMessage(t('assistant.statusAnalyzing'));
      const toastId = toast.info(t('assistant.toastAnalyzing'), { autoClose: false });

      const response = await fetch('/.netlify/functions/openAIConversation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userInput, fileId, assistantId: 'asst_BEjQnYuZGaZvdVy5XCwaZcj5' }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setThreadId(data.threadId);
      setRunId(data.runId);
      toast.update(toastId, { render: t('assistant.toastComplete'), type: toast.TYPE.SUCCESS, autoClose: 5000 });
      handleAnalyticsEvent('response', 'Designer', 'Designer Responded'); // Log event for response received
      return data.message;
    } catch (error) {
      console.error('Error starting conversation:', error);
      toast.dismiss();
      toast.error(t('assistant.toastError'));
      setStatusMessage(t('assistant.statusError'));
      setIsLoading(false);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const currentInput = userInput.trim();

      if (!currentInput && !isFirstInteraction) {
        throw new Error(t("assistant.noInput"));
      }

      const imageUrl = isFirstInteraction ? backgroundImageUrl : imageFileId ? imageFileId : null;
      if (!imageUrl && isFirstInteraction) {
        throw new Error(t("assistant.noImage"));
      }

      const question = currentInput || defaultQuestion;

      const plan = planLimits.designer[userData.planType] || { totalInteractions: 10, weeklyInteractions: 5 };

      if (userRole !== 'admin' && userRole !== 'mainAdmin') {
        if (userData.assistantInteractionCount >= plan.totalInteractions || (userData.weeklyInteractionCount || 0) >= plan.weeklyInteractions) {
          setAssistantResponse(t("assistant.limitReached"));
          setIsLoading(false);
          return;
        }
      }

      let uploadedImageFileId = imageFileId;
      if (isFirstInteraction) {
        uploadedImageFileId = await uploadImage(imageUrl);
        setImageFileId(uploadedImageFileId);
      }

      const responseMessage = await startConversation(question, uploadedImageFileId);
      setAssistantResponse(prev => `${prev}\n\n${responseMessage}`);
      setStatusMessage(t('assistant.statusComplete'));
      toast.success(t('assistant.toastComplete'));
      setIsFirstInteraction(false);

    } catch (error) {
      console.error("Error:", error);
      setAssistantResponse(prev => `${prev}\n\n${t("assistant.errorAi")}`);
      toast.error(t("assistant.errorNetwork"));
    } finally {
      setIsLoading(false);
      setUserInput('');
      updateInteractionCounts();
    }
  };

  const handleProjectSwitch = (newImageUrl) => {
    setIsFirstInteraction(true);
    setThreadId(null);
    setRunId(null);
    setImageFileId(null);
    setBackgroundImageUrl(newImageUrl);
    setUserInput('');
    setAssistantResponse('');
  };

  const handleHintClick = (hintText) => {
    setUserInput(hintText);
    textAreaRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const startNewConversation = async () => {
    setUserInput('');
    setAssistantResponse('');
    setIsLoading(false);
    handleProjectSwitch('');
    resetNewProjectImage();
    if (currentUser) {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, { threadId: null, runId: null });
    }
  };

  const updateInteractionCounts = async (threadId, runId) => {
    if (!currentUser || userRole === 'mainAdmin' || userRole === 'admin') {
      return;
    }
    const userRef = doc(db, "users", currentUser.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const weeklyInteractionCount = (userData.weeklyInteractionCount || 0) + 1;
      const assistantInteractionCount = (userData.assistantInteractionCount || 0) + 1;
      console.log("Updating interaction counts:", { weeklyInteractionCount, assistantInteractionCount, threadId, runId });
      await updateDoc(userRef, { weeklyInteractionCount, assistantInteractionCount, threadId, runId });
    }
  };

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomPart = Math.random().toString(36).substr(2, 9);
    return `guest_${timestamp}_${randomPart}`;
  };

  const convertTextUrlsToLinks = (text) => {
    const urlRegex = /www\.[\w\-\.]+(\.com|\.ca)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="http://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const createMarkup = (htmlContent) => {
    const processedContent = convertTextUrlsToLinks(htmlContent);
    return { __html: DOMPurify.sanitize(processedContent) };
  };

  const handleModalOpen = () => {
    setDesignerModalOpen(true);
    handleAnalyticsEvent('click', 'Designer', 'Designer Triggered'); // Log event when modal is opened
  };

  return (
    <>
      {!isDesignerModalOpen && !isMobile && (
        <div className="floating-button" onClick={handleModalOpen}>
          <img
            src="/avatarDesignerFacialIcon.png"
            alt="Facade Designer Icon"
            className="designer-icon"
            style={{ width: '40px', height: "auto", marginRight: '8px', verticalAlign: 'middle' }}
          />
          <span>{t('assistant.VirtualDesigner')}</span>
        </div>
      )}
      <Modal show={isDesignerModalOpen} onHide={() => setDesignerModalOpen(false)}>
        <Modal.Header closeButton>
          <div className="modal-header-content">
            <Modal.Title>{t("assistant.VirtualDesigner")}</Modal.Title>
            <div className="interaction-counts">
              {`${userData.weeklyInteractionCount || 0} ${t('assistant.outOf')} ${planLimits.designer[userData.planType]?.weeklyInteractions || 'N/A'} ${t('assistant.weekly')}`}
              <br />
              {`${userData.assistantInteractionCount || 0} ${t('assistant.outOf')} ${planLimits.designer[userData.planType]?.totalInteractions || 'N/A'} ${t('assistant.monthly')}`}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef}>
          <div className='avatarTitle'>
            <h4>{t("assistant.avatarTitle")} </h4>
          </div>
          <div className="avatar-container" style={{ textAlign: 'center', paddingBottom: "16px" }}>
            <img src={"/avatarDesigner.png"} alt="Facade Designer Avatar" style={{ width: '200px', margin: '0 auto' }} />
          </div>
          <div className='avatarTitle'>
            <p>{t("assistant.hintExamples")}</p>
          </div>
          <div className="hints-grid">
            {t('hints', { returnObjects: true }).map((hint, index) => (
              <div key={index} className="hint-box" onClick={() => handleHintClick(hint)}>
                {hint}
              </div>
            ))}
          </div>
          <textarea
            ref={textAreaRef}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            disabled={isLoading}
            placeholder={t("assistant.placeHolder")}
          />
          <div className="spinner-container">
            <Button onClick={handleSubmit} disabled={isLoading}>{t("assistant.Submit")}</Button>
            <Button onClick={startNewConversation} disabled={isLoading} variant="secondary" style={{ marginLeft: '10px' }}>
              {t("assistant.NewConversation")}
            </Button>
            {isLoading && (
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">{t('assistant.loading')}</span>
              </Spinner>
            )}
          </div>
          {isLoading ? (
            <>
              <p>{statusMessage}</p>
            </>
          ) : (
            <>
              <p>{statusMessage}</p>
              <div ref={responseContainerRef} dangerouslySetInnerHTML={{ __html: assistantResponse }}></div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FacadeDesigner;
