
export const handlesAndSilsArray = [

      {
        name: "Single Door",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Single.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Single.png",
        type: 1,
      },  
      {
        name: "Single Door E",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Single.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Single.png",
        type: 1,
      }, 

      {
        name: "Single Door AI",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Single.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Single.png",
        type: 1,
      }, 

      {
        name: "Single Door Embossed1AGlassAt1NoSidelight",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Single.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Single.png",
        type: 1,
      }, 

      {
        name: "Single Door Left SideLight",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleLSidelight.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleLSidelight.png",
        type: 1,
      },  

      {
        name: "Single Door Left SideLight Full",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleLSidelightFull.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleLSidelightFull.png",
        type: 1,
      },  

      {
        name: "Single Door Right SideLight",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleRSidelight.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleRSidelight.png",
        type: 1,
      },  
      {
        name: "Single Door Left SideLight AI",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleLSidelight.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleLSidelight.png",
        type: 1,
      },  

      {
        name: "Single Door Right SideLight Full",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleRSidelightFull.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleRSidelightFull.png",
        type: 1,
      },  

      {
        name: "Single Door Right Left SideLight AI",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleLRSidelight.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleLRSidelight.png",
        type: 1,
      },  

      {
        name: "Single Door Right Left SideLight",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleLRSidelight.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleLRSidelight.png",
        type: 1,
      },  

      {
        name: "Single Door Right Left SideLight Full",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_SingleLRSidelightFull.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_SingleLRSidelightFull.png",
        type: 1,
      },  

      {
        name: "Double Door",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Double.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Double.png",
        type: 1,
      },  
      {
        name: "Double Door E",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Double.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Double.png",
        type: 1,
      }, 
      {
        name: "Double Door AI",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Double.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Double.png",
        type: 1,
      },  
      {
        name: "Double Door E AI",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_Double.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_Double.png",
        type: 1,
      }, 
      {
        name: "Double Door Right Left SideLight",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_DoubleRLSidelight.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_DoubleRLSidelight.png",
        type: 1,
      },  

      {
        name: "Double Door Right Left SideLight Full",
        handleUrl: "/doors/handles/SteelDoor_Handle_Web_DoubleRLSidelightFull.png",
        silUrl: "/doors/sils/SteelDoor_Sill_Web_DoubleRLSidelightFull.png",
        type: 1,
      },  

 ];

export default handlesAndSilsArray;
