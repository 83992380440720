import { fabric } from "fabric-with-erasing";

var Perspective = /** @class */ (function () {
    function Perspective(ctxd, image) {
        // check the arguments
        if (!ctxd || !ctxd.strokeStyle) {
            return;
        }
        if (!image || !image.width || !image.height) {
            return;
        }
        // prepare a <canvas> for the image
        var cvso = document.createElement("canvas");
        cvso.width = Math.round(image.width);
        cvso.height = Math.round(image.height);
        var ctxo = cvso.getContext("2d");
        ctxo.drawImage(image, 0, 0, cvso.width, cvso.height);
        // prepare a <canvas> for the transformed image
        var cvst = document.createElement("canvas");
        cvst.width = ctxd.canvas.width;
        cvst.height = ctxd.canvas.height;
        var ctxt = cvst.getContext("2d");
        this.ctxd = ctxd;
        this.cvso = cvso;
        this.ctxo = ctxo;
        this.ctxt = ctxt;
    }
    Perspective.prototype.draw = function (q, isClear = false) {
        var topLeftX = q.topLeftX, topLeftY = q.topLeftY, topRightX = q.topRightX, topRightY = q.topRightY, bottomRightX = q.bottomRightX, bottomRightY = q.bottomRightY, bottomLeftX = q.bottomLeftX, bottomLeftY = q.bottomLeftY;
        // compute the dimension of each side
        var dims = [
            Math.sqrt(Math.pow(topLeftX - topRightX, 2) + Math.pow(topLeftY - topRightY, 2)),
            Math.sqrt(Math.pow(topRightX - bottomRightX, 2) +
                Math.pow(topRightY - bottomRightY, 2)),
            Math.sqrt(Math.pow(bottomRightX - bottomLeftX, 2) +
                Math.pow(bottomRightY - bottomLeftY, 2)),
            Math.sqrt(Math.pow(bottomLeftX - topLeftX, 2) +
                Math.pow(bottomLeftY - topLeftY, 2)), // left side
        ];
        //
        var ow = this.cvso.width;
        var oh = this.cvso.height;
        // specify the index of which dimension is longest
        var base_index = 0;
        var max_scale_rate = 0;
        var zero_num = 0;
        for (var i = 0; i < 4; i++) {
            var rate = 0;
            if (i % 2) {
                rate = dims[i] / ow;
            }
            else {
                rate = dims[i] / oh;
            }
            if (rate > max_scale_rate) {
                base_index = i;
                max_scale_rate = rate;
            }
            if (dims[i] == 0) {
                zero_num++;
            }
        }
        if (zero_num > 1) {
            return;
        }
        //
        var step = 2;
        var cover_step = step * 5;
        //
        var ctxo = this.ctxo;
        var ctxt = this.ctxt;
        ctxt.clearRect(0, 0, ctxt.canvas.width, ctxt.canvas.height);
        if (base_index % 2 == 0) {
            // top or bottom side
            var ctxl = this.create_canvas_context(ow, cover_step);
            ctxl.globalCompositeOperation = "copy";
            var cvsl = ctxl.canvas;
            for (var y = 0; y < oh; y += step) {
                var r = y / oh;
                var sx = topLeftX + (bottomLeftX - topLeftX) * r;
                var sy = topLeftY + (bottomLeftY - topLeftY) * r;
                var ex = topRightX + (bottomRightX - topRightX) * r;
                var ey = topRightY + (bottomRightY - topRightY) * r;
                var ag = Math.atan((ey - sy) / (ex - sx));
                var sc = Math.sqrt(Math.pow(ex - sx, 2) + Math.pow(ey - sy, 2)) / ow;
                ctxl.setTransform(1, 0, 0, 1, 0, -y);
                ctxl.drawImage(ctxo.canvas, 0, 0);
                //
                ctxt.translate(sx, sy);
                ctxt.rotate(ag);
                ctxt.scale(sc, sc);
                ctxt.drawImage(cvsl, 0, 0);
                //
                ctxt.setTransform(1, 0, 0, 1, 0, 0);
            }
        }
        else if (base_index % 2 == 1) {
            // right or left side
            var ctxl = this.create_canvas_context(cover_step, oh);
            ctxl.globalCompositeOperation = "copy";
            var cvsl = ctxl.canvas;
            for (var x = 0; x < ow; x += step) {
                var r = x / ow;
                var sx = topLeftX + (topRightX - topLeftX) * r;
                var sy = topLeftY + (topRightY - topLeftY) * r;
                var ex = bottomLeftX + (bottomRightX - bottomLeftX) * r;
                var ey = bottomLeftY + (bottomRightY - bottomLeftY) * r;
                var ag = Math.atan((sx - ex) / (ey - sy));
                var sc = Math.sqrt(Math.pow(ex - sx, 2) + Math.pow(ey - sy, 2)) / oh;
                ctxl.setTransform(1, 0, 0, 1, -x, 0);
                ctxl.drawImage(ctxo.canvas, 0, 0);
                //
                ctxt.translate(sx, sy);
                ctxt.rotate(ag);
                ctxt.scale(sc, sc);
                ctxt.drawImage(cvsl, 0, 0);
                //
                ctxt.setTransform(1, 0, 0, 1, 0, 0);
            }
        }
        // set a clipping path and draw the transformed image on the destination canvas.
        if (isClear) {
            this.ctxd.clearRect(0, 0, this.ctxd.canvas.width, this.ctxd.canvas.height);
        }
        this.ctxd.save();
        this.ctxd.drawImage(ctxt.canvas, 0, 0);
        this._applyMask(this.ctxd, q);
        this.ctxd.restore();
    };
    Perspective.prototype.create_canvas_context = function (w, h) {
        var canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        var ctx = canvas.getContext("2d");
        return ctx;
    };
    Perspective.prototype._applyMask = function (ctx, q) {
        ctx.beginPath();
        ctx.moveTo(q.topLeftX, q.topLeftY);
        ctx.lineTo(q.topRightX, q.topRightY);
        ctx.lineTo(q.bottomRightX, q.bottomRightY);
        ctx.lineTo(q.bottomLeftX, q.bottomLeftY);
        ctx.closePath();
        ctx.globalCompositeOperation = "destination-in";
        ctx.fill();
        ctx.globalCompositeOperation = "source-over";
    };
    return Perspective;
}());

function getObjectSizeWithStroke(object) {
    var stroke = new fabric.Point(
        object.strokeUniform ? 1 / object.scaleX : 1,
        object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
        y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);

    return fabric.util.transformPoint(
        { x: x, y: y },
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
        )
    );
}

function actionHandler(eventData, transform, x, y) {
    var polygon = transform.target,
        currentControl = polygon.controls[polygon.__corner],
        mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
        polygonBaseSize = getObjectSizeWithStroke(polygon),
        size = polygon._getTransformedDimensions(0, 0),
        finalPointPosition = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
        };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
}

function anchorWrapper(anchorIndex, fn, pspt) {
    return function (eventData, transform, x, y) {
        var fabricObject = transform.target,
            absolutePoint = fabric.util.transformPoint({
                x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
            }, fabricObject.calcTransformMatrix()),
            actionPerformed = fn(eventData, transform, x, y),
            newDim = fabricObject._setPositionDimensions({}),
            polygonBaseSize = getObjectSizeWithStroke(fabricObject),
            newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
            newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;

        fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

        drawContext(pspt, fabricObject);

        fabricObject.canvas.renderAll();

        return actionPerformed;
    }
}


function imageProcess(src) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
    })
}

async function toggleEditPoly(canvas, poly, pspt) {
    poly.edit = canvas.isPerspective;

    if (poly.edit && !poly.flipX && !poly.flipY) {
        poly.transparentCorners = false;
        poly.cornerStyle = 'circle';
        poly.cornerColor = 'red';
        poly.controls = poly.points.reduce(function (acc, point, index) {
            acc['p' + index] = new fabric.Control({
                positionHandler: polygonPositionHandler,
                actionHandler: anchorWrapper(index > 0 ? index - 1 : poly.points.length - 1, actionHandler, pspt),
                actionName: 'modifyPolygon',
                pointIndex: index
            });
            return acc;
        }, {});
    } else {
        poly.transparentCorners = true;
        poly.cornerColor = fabric.Object.prototype.cornerColor;
        poly.cornerStyle = 'rect';
        poly.controls = fabric.Object.prototype.controls;
    }
    canvas.renderAll();
}

function getInitPoints(width, height, type) {
    var initPoints;
    if (type === "triangle") {
        initPoints = [
            { x: width / 2, y: 0 },
            { x: width, y: height },
            { x: 0, y: height },
        ];
    } else {
        initPoints = [
            { x: 0, y: 0 },
            { x: width, y: 0 },
            { x: width, y: height },
            { x: 0, y: height }
        ];
    }
    return initPoints;
}

function drawContext(pspt, poly) {
    if (!poly.originObject) return
    if (poly.originObject.type == "triangle" || poly.originObject.type === "rect") return;

    const windowType = poly.windowType ? poly.windowType : "";
    var globalPoints = [...poly.points];

    const minSubX = Math.min(...[globalPoints[0].x, globalPoints[1].x, globalPoints[2].x, globalPoints[3].x]);
    const minSubY = Math.min(...[globalPoints[0].y, globalPoints[1].y, globalPoints[2].y, globalPoints[3].y]);

    pspt.draw({
        topLeftX: globalPoints[0].x - minSubX,
        topLeftY: globalPoints[0].y - minSubY,
        topRightX: globalPoints[1].x - minSubX,
        topRightY: globalPoints[1].y - minSubY,
        bottomRightX: globalPoints[2].x - minSubX,
        bottomRightY: globalPoints[2].y - minSubY,
        bottomLeftX: globalPoints[3].x - minSubX,
        bottomLeftY: globalPoints[3].y - minSubY,
    }, windowType.includes("Fixed"));
}

async function updatePoly(canvas, poly) {
    canvas.isPerspective = canvas.isPerspective !== undefined ? canvas.isPerspective : false;
    var fabricObject = poly.originObject;
    fabricObject.left = -fabricObject.width * 2;
    fabricObject.visible = true;

    var patternSourceCanvas = document.createElement('canvas');
    patternSourceCanvas.width = canvas.width * 1.5;
    patternSourceCanvas.height = canvas.height * 1.5;

    var image = await imageProcess(fabricObject.toDataURL("image/png"));
    image.width *= poly.tmpScaleX * 2;
    image.height *= poly.tmpScaleY * 2;
    fabricObject.left = 0;
    fabricObject.visible = false;

    var patternContext = patternSourceCanvas.getContext("2d");
    var pspt = new Perspective(patternContext, image);

    drawContext(pspt, poly);

    var pattern = new fabric.Pattern({
        source: patternSourceCanvas,
        repeat: "no-repeat",
    });
    poly.set("fill", poly.originObject.type === "triangle" || poly.originObject.type === "rect" ? poly.originObject.fill : pattern)
    canvas.renderAll()
    toggleEditPoly(canvas, poly, pspt);
}

export async function getPsptFabricObj(canvas, fabricObject) {
    canvas.isPerspective = canvas.isPerspective !== undefined ? canvas.isPerspective : false;
    if (!canvas.isPerspective) {
        return Promise.resolve(fabricObject);
    }
    const { left, top, scaleX, scaleY, originX, originY } = fabricObject;
    fabricObject.set({
        originX: "left",
        originY: "top",
        left: 0,
        top: 0,
        scaleY: 1,
        scaleX: 1,
    });

    var poly = new fabric.Polygon(
        getInitPoints(fabricObject.width * scaleX, fabricObject.height * scaleY, fabricObject.type),
        {
            edit: true,
            hasBorders: true,
            fill: null,
            objectCaching: false,
            originY: originY,
            originX: originX,
            top: top,
            left: left,
            scaleX: 1,
            scaleY: 1,
            usePerspective: true,
            relatedGroupId: fabricObject.groupId,
            tmpScaleX: scaleX,
            tmpScaleY: scaleY,
            itemColor: fabricObject.itemColor,
            itemUrl: fabricObject.itemUrl,
            windowType: fabricObject.windowType,
            originObject: fabricObject,
        }
    );

    await updatePoly(canvas, poly);

    return poly;
}

export const setObjectPerspective = async (canvas, obj, ignoreType = false) => {
    if (!obj) obj = canvas.getActiveObject();
    if (obj && obj.usePerspective) {
        if (obj.type === "polygon") {
            await updatePoly(canvas, obj);
        } else if (ignoreType && canvas.isPerspective) {
            const poly = await getPsptFabricObj(canvas, obj);
            canvas.discardActiveObject().renderAll();
            canvas.add(poly);
            canvas.bringToFront(poly);
            canvas.setActiveObject(poly);
            obj.visible = false;
            canvas.add(obj);
            canvas.renderAll();
        }
    }
}

export const getRelatedObject = (canvas, parentObject) => {
    let object = null;
    canvas.getObjects().forEach(o => {
        if (o.groupId === parentObject.relatedGroupId) {
            object = o;
        }
    });
    return object;
}