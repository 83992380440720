function sidesSizeAndPosition(glassToFind, aiDetectedDimensions = null, window) {

  const cutOuts = {
    Sidelight2B: {
      heightSR: 711,
      topSR: 86,
      widthSR: 118,
      leftSR: 828,
      RLheightSR: 711,
      RLtopSR: 94,
      RLwidthSR: 118,
      RLleftSR: 966,
      DDRLheightSR: 711,
      DDRLtopSR: 94,
      DDRLwidthSR: 118,
      DDRLleftSR: 1163,
      heightSL: 711,
      topSL: 86,
      widthSL: 118,
      leftSL: 254,
      RLheightSL: 711,
      RLtopSL: 94,
      RLwidthSL: 118,
      RLleftSL: 115,
      DDRLheightSL: 711,
      DDRLtopSL: 94,
      DDRLwidthSL: 118,
      DDRLleftSL: -80,
      cutoutSize: "118_711",
    },
    Sidelight2A: {
      heightSR: 533,
      topSR: 86,
      widthSR: 118,
      leftSR: 828,
      RLheightSR: 533,
      RLtopSR: 94,
      RLwidthSR: 118,
      RLleftSR: 966,
      DDRLheightSR: 533,
      DDRLtopSR: 94,
      DDRLwidthSR: 118,
      DDRLleftSR: 1163,
      heightSL: 533,
      topSL: 83,
      widthSL: 118,
      leftSL: 254,
      RLheightSL: 533,
      RLtopSL: 94,
      RLwidthSL: 118,
      RLleftSL: 115,
      DDRLheightSL: 533,
      DDRLtopSL: 94,
      DDRLwidthSL: 118,
      DDRLleftSL: -80,
      cutoutSize: "118_533",
    },

    Sidelight1A: {
      heightSR: 950,
      topSR: 90,
      widthSR: 118,
      leftSR: 828,
      RLheightSR: 940,
      RLtopSR: 94,
      RLwidthSR: 118,
      RLleftSR: 966,
      DDRLheightSR: 940,
      DDRLtopSR: 94,
      DDRLwidthSR: 118,
      DDRLleftSR: 1166,
      heightSL: 948,
      topSL: 94,
      widthSL: 118,
      leftSL: 255,
      RLheightSL: 940,
      RLtopSL: 94,
      RLwidthSL: 118,
      RLleftSL: 115,
      DDRLheightSL: 940,
      DDRLtopSL: 94,
      DDRLwidthSL: 118,
      DDRLleftSL: -86,
      cutoutSize: "118_948",
    },
    SidelightCatalogue: {
      heightSR: 950,
      topSR: 90,
      widthSR: 118,
      leftSR: 828,
      RLheightSR: 940,
      RLtopSR: 94,
      RLwidthSR: 118,
      RLleftSR: 966,
      DDRLheightSR: 940,
      DDRLtopSR: 94,
      DDRLwidthSR: 118,
      DDRLleftSR: 1164,
      heightSL: 940,
      topSL: 94,
      widthSL: 118,
      leftSL: 255,
      RLheightSL: 940,
      RLtopSL: 94,
      RLwidthSL: 118,
      RLleftSL: 115,
      DDRLheightSL: 940,
      DDRLtopSL: 94,
      DDRLwidthSL: 118,
      DDRLleftSL: -81,
      cutoutSize: "118_948",
    },

  };
  const isAI = glassToFind.endsWith("AI");
  const baseName = isAI ? glassToFind.slice(0, -2) : glassToFind; // Remove "AI" suffix if present


  const defaultProperties = cutOuts[baseName] ?? {
    heightSR: 950,
    topSR: 90,
    widthSR: 118,
    leftSR: 828,
    heightSL: 948,
    topSL: 94,
    widthSL: 118,
    leftSL: 255,
    cutoutSize: "118_948",
  }
  if (isAI && aiDetectedDimensions) {

    const { aiWidth, aiHeight, aiTop, aiLeft } = aiDetectedDimensions;

    const backgroundImageScaleFactor = aiDetectedDimensions.scaleX

    function determineDoorDimensions(window) {
      let dimensions = {
        width: null,
        height: null,
      };

      if (window.includes("Double_Door")) {
        dimensions.height = 1226;
        dimensions.width = 1043;
      } else if (window.includes("Single_Door_Right_Left_SideLight")) {
        dimensions.height = 1290;
        dimensions.width = 1140;
      } else if (window.includes("Single_Door_Left_SideLight")) {
        dimensions.height = 1286;
        dimensions.width = 852;
      } else if (window.includes("Single_Door") && !baseName.includes("SideLight")) {
        dimensions.height = 1268;
        dimensions.width = 573;
      } else {
        // Default dimensions
        dimensions.height = 1268;
        dimensions.width = 573; // Assuming single door width and height as default
      }

      return dimensions;
    }

    const doorDimensions = determineDoorDimensions(window);

    const canvasCenter = 600;

    const doorScaleY = aiHeight / doorDimensions.height;
    const doorScaleX = aiWidth / doorDimensions.width;

    let RLfinalPositionLeftSL;
    let RLfinalPositionTopSL;
    let RLfinalPositionLeftSR;
    let RLfinalPositionTopSR;

    if (doorDimensions.width === 1140) {

      const leftPositionOnCanvas = canvasCenter - (doorDimensions.width / 2)

      const relativeDecorativeGlassPositionSL = defaultProperties.RLleftSL - leftPositionOnCanvas
      RLfinalPositionLeftSL = relativeDecorativeGlassPositionSL * doorScaleX

      const relativeDecorativeGlassPositionSR = defaultProperties.RLleftSR - leftPositionOnCanvas
      RLfinalPositionLeftSR = relativeDecorativeGlassPositionSR * doorScaleX


      RLfinalPositionTopSL = defaultProperties.RLtopSL * (aiWidth * backgroundImageScaleFactor / doorDimensions.width)
      RLfinalPositionTopSR = defaultProperties.RLtopSR * (aiWidth * backgroundImageScaleFactor / doorDimensions.width)

      const adjustedWidthSL = defaultProperties.RLwidthSL;
      const adjustedHeightSL = defaultProperties.RLheightSL;
      const adjustedWidthSR = defaultProperties.RLwidthSR;
      const adjustedHeightSR = defaultProperties.RLheightSR;


      const adjustedProperties = {
        RLheightSL: adjustedHeightSL,
        RLtopSL: RLfinalPositionTopSL,
        RLwidthSL: adjustedWidthSL,
        RLleftSL: RLfinalPositionLeftSL,
        RLheightSR: adjustedHeightSR,
        RLtopSR: RLfinalPositionTopSR,
        RLwidthSR: adjustedWidthSR,
        RLleftSR: RLfinalPositionLeftSR
      };

      return {
        ...adjustedProperties,
        // ...additionalProperties,
      };
    }

    const leftPositionOnCanvas = canvasCenter - (doorDimensions.width / 2)

    const relativeDecorativeGlassPositionSL = defaultProperties.leftSL - leftPositionOnCanvas
    const finalPositionLeft = relativeDecorativeGlassPositionSL * doorScaleX


    const adjustedWidth = defaultProperties.widthSL;
    const adjustedTop = defaultProperties.topSL * (aiWidth * backgroundImageScaleFactor / doorDimensions.width)
    const adjustedHeight = defaultProperties.heightSL;
    // const adjustedLeft = aiWidth * defaultProperties.leftSL / doorDimensions.width * doorScaleX * 2;


    const adjustedProperties = {
      heightSL: adjustedHeight,
      topSL: adjustedTop,
      widthSL: adjustedWidth,
      leftSL: finalPositionLeft
    };

    return {
      ...adjustedProperties,
    };
  }


  // Return default properties if AI-detected dimensions are not provided
  return defaultProperties;

}

export default sidesSizeAndPosition;
