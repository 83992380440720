import { v1 as uuid } from "uuid";

export const doorsArray = [

  {
    url: "/doors/single/Single_Door_Embossed1AGlassAt1NoSidelight.png",
    key: uuid(),
    name: "Single_Door",
  },
  // {
  //   url: "/doors/single/Single_Door_EmbossedAAGlassAt1NoSidelightB.png",
  //   key: uuid(),
  //   name: "Single_Door",
  // },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
    name: "Single_Door_Right_SideLight",
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
    name: "Single_Door_Right_SideLight_Full",
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
    name: "Single_Door_Left_SideLight",
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
    name: "Single_Door_Left_SideLight_Full",
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
    name: "Single_Door_Right_Left_SideLight",
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
    name: "Single_Door_Right_Left_SideLight_Full",
  },
  {
    url: "/doors/double/Double_Door_Embossed1AGlassAt1NoSidelight.png",
    key: uuid(),
    name: "Double_Door",
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
    name: "Double_Door_Right_Left_SideLight",
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
    name: "Double_Door_Right_Left_SideLight_Full",
  },
  
];

export default doorsArray;
