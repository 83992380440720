import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { storage, db } from "../../base";
import { doc, setDoc, collection, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { ref, listAll, deleteObject } from "firebase/storage";
import { Modal, Form, Button, Tab, Nav, Table, InputGroup, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AdminModal = () => {
  const [clientName, setClientName] = useState("");
  const [image, setImage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [link, setLink] = useState("");
  const [region, setRegion] = useState("");
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [rotationLapse, setRotationLapse] = useState(0);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const { t } = useTranslation(["translation"]);

  // Toggle Admin Modal
  const toggleAdminModal = () => {
    setShowAdminModal(!showAdminModal);
  };

  // Fetch unconfirmed users
  const fetchUnconfirmedUsers = async () => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("emailConfirmed", "==", false));
    const querySnapshot = await getDocs(q);
    const unconfirmedUsers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(unconfirmedUsers);
  };

  // Search users by name or email
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    const searchTerms = term ? term.split(/\s+/).filter(Boolean) : []; // Ensure searchTerms is always an array
    setSearchTerm(searchTerms);
  };

  const filteredUsers = users.filter(user => {
    return searchTerm.length === 0 || searchTerm.every(term => 
      (user.name && user.name.toLowerCase().includes(term)) || 
      (user.email && user.email.toLowerCase().includes(term))
    );
  });

  // Handle User Deletion
  const deleteUserItems = async (userId) => {
    try {
      // Firestore: Delete projects associated with the user ID
      const projectsRef = collection(db, "Projects");
      const q = query(projectsRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      const deleteProjectPromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deleteProjectPromises);

      // Delete user images and folders
      const userFolderRef = ref(storage, `usersProjectsImages/${userId}`);
      await deleteFolderContents(userFolderRef);

      console.log('All user items deleted successfully');
    } catch (error) {
      console.error("Error deleting user items:", error);
    }
  };

  const deleteFolderContents = async (folderRef) => {
    const { items, prefixes } = await listAll(folderRef);

    // Delete all files in the current folder
    const deleteFilesPromises = items.map((itemRef) => deleteObject(itemRef));
    await Promise.all(deleteFilesPromises);

    // Recursively delete files in subfolders
    const deleteFoldersPromises = prefixes.map((prefix) => deleteFolderContents(prefix));
    await Promise.all(deleteFoldersPromises);
  };

  // Fetch projects older than 60 days or with null savedTimestamp
  const fetchOldProjects = async () => {
    try {
      const projectsRef = collection(db, "Projects");

      // Ensure the filterDate is set correctly
      if (!filterDate) return;

      const oldProjects = [];

      // Query for projects where savedTimestamp is less than the given date string
      const q1 = query(
        projectsRef,
        where("savedTimestamp", "<", filterDate)
      );

      // Query for projects where savedTimestamp is null
      const q2 = query(
        projectsRef,
        where("savedTimestamp", "==", null)
      );

      // Execute both queries and merge the results
      const [querySnapshot1, querySnapshot2] = await Promise.all([getDocs(q1), getDocs(q2)]);

      querySnapshot1.forEach((doc) => {
        const projectData = doc.data();
        oldProjects.push({ id: doc.id, ...projectData });
      });

      querySnapshot2.forEach((doc) => {
        const projectData = doc.data();
        oldProjects.push({ id: doc.id, ...projectData });
      });

      setProjects(oldProjects);
      setSelectedProjects([]); // Clear selected projects when new results are loaded
    } catch (error) {
      console.error("Error fetching old projects:", error);
    }
  };

  // Handle Date Change
  const handleDateChange = (e) => {
    // Get the selected date
    const selectedDate = e.target.value;

    // Convert the selected date to ISO 8601 string at midnight (start of the day)
    const formattedDate = new Date(selectedDate).toISOString().split('T')[0] + "T00:00:00.000Z";

    // Set the filterDate state to this formatted string
    setFilterDate(formattedDate);
  };

  // Handle Project Selection for Deletion
  const handleProjectSelection = (projectId) => {
    setSelectedProjects(prevSelected => {
      if (prevSelected.includes(projectId)) {
        return prevSelected.filter(id => id !== projectId);
      } else {
        return [...prevSelected, projectId];
      }
    });
  };

  // Delete Selected Projects
  const deleteSelectedProjects = async () => {
    try {
      const selectedToDelete = projects.filter(project => selectedProjects.includes(project.id));
      await Promise.all(selectedToDelete.map(async (project) => {
        await deleteProjectImagesAndDocuments(project.id, project.imageName);
      }));

      // Remove the deleted projects from state
      setProjects(prevProjects => prevProjects.filter(project => !selectedProjects.includes(project.id)));
      setSelectedProjects([]);
      toast.success("Selected projects and associated images deleted successfully!");
    } catch (error) {
      console.error("Error deleting selected projects:", error);
      toast.error("Error deleting selected projects");
    }
  };

  // Delete Project Images and Documents
  const deleteProjectImagesAndDocuments = async (projectId, imageName) => {
    try {
      // Possible file name patterns to search for
      const possiblePatterns = [
        `${imageName}`,                // File with no extension
        `${imageName}_resized.png`,    // Resized PNG file
        `${imageName}_webp.webp`       // WebP file
      ];
  
      for (const pattern of possiblePatterns) {
        await searchAndDeleteImages(pattern);
      }
  
      // Delete the project document after images are deleted
      const projectDocRef = doc(db, "Projects", projectId);
      await deleteDoc(projectDocRef);
      console.log(`Deleted project: ${projectId}`);
    } catch (error) {
      console.error("Error deleting project and images:", error);
    }
  };
  

  // Search and Delete Images
  const searchAndDeleteImages = async (fileNamePattern) => {
    const rootRef = ref(storage);
  
    // Recursively search for the image in all folders and subfolders
    const deleteFilesPromises = [];
  
    const listAndDelete = async (folderRef) => {
      const { items, prefixes } = await listAll(folderRef);
  
      // Check each file in the folder
      items.forEach((itemRef) => {
        if (itemRef.name === fileNamePattern) {
          deleteFilesPromises.push(deleteObject(itemRef).then(() => {
            console.log(`Deleted file: ${itemRef.fullPath}`);
          }).catch(error => {
            console.error(`Error deleting file: ${itemRef.fullPath}`, error);
          }));
        }
      });
  
      // Recursively check subfolders
      await Promise.all(prefixes.map((prefix) => listAndDelete(prefix)));
    };
  
    await listAndDelete(rootRef);
    await Promise.all(deleteFilesPromises);
  };
  

  // Handle Image Change for Banner Management
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    } else {
      toast.error("Please select a valid image file.");
    }
  };

  // Handle Banner Submission
  const handleSubmit = async () => {
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`banners/main_banner_${clientName}`);
    await imageRef.put(image);

    const imageUrl = await imageRef.getDownloadURL();

    const clientDocRef = doc(db, 'banners', clientName);
    await setDoc(clientDocRef, {
      name: clientName,
      isActive,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      imageUrl,
      link,
      rotationLapse,
      region
    });

    resetForm();
  };

  // Reset the form fields
  const resetForm = () => {
    setClientName("");
    setImage(null);
    setIsActive(false);
    setStartDate("");
    setEndDate("");
    setLink("");
    setRotationLapse(0);
    setRegion("");
  };

  // Load unconfirmed users on mount
  useEffect(() => {
    fetchUnconfirmedUsers();
  }, []);

  return (
    <>
      <Button onClick={toggleAdminModal}>{t('admin')}</Button>

      <Modal
        show={showAdminModal}
        onHide={toggleAdminModal}
        dialogClassName="admin-modal"
        contentClassName="admin-modal-content"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('adminPanel')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="admin-tabs" defaultActiveKey="userManagement">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="userManagement">{t('userManagement')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bannerManagement">{t('bannerManagement')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="projectManagement">{t('projectManagement')}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              {/* User Management */}
              <Tab.Pane eventKey="userManagement">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder={t('searchUsers')}
                    aria-label="Search Users"
                    aria-describedby="basic-addon2"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </InputGroup>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{t('userName')}</th>
                      <th>{t('userEmail')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map(user => (
                      <tr key={user.id}>
                        <td>{user.userName}</td>
                        <td>{user.email}</td>
                        <td>
                          <Button variant="danger" onClick={() => deleteUserItems(user.id)}>
                            {t('delete')}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab.Pane>

              {/* Banner Management */}
              <Tab.Pane eventKey="bannerManagement">
                <Form>
                  <Form.Group controlId="formClientName">
                    <Form.Label>{t('clientName')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Client Name"
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formRegion">
                    <Form.Label>{t('clientRegion')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Client Region"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formFile">
                    <Form.Label>{t('image')}</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleImageChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formLink">
                    <Form.Label>{t('linkUrl')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link URL"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formIsActive">
                    <Form.Check
                      type="checkbox"
                      label={t('isActive')}
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formStartDate">
                    <Form.Label>{t('startDate')}</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formEndDate">
                    <Form.Label>{t('endDate')}</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formRotationLapse">
                    <Form.Label>{t('rotationLapse')}</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Rotation Lapse in seconds"
                      value={rotationLapse}
                      onChange={(e) => setRotationLapse(e.target.value)}
                    />
                  </Form.Group>

                  <Button variant="primary" onClick={handleSubmit}>
                    {t('submit')}
                  </Button>
                </Form>
              </Tab.Pane>

              {/* Project Management */}
              <Tab.Pane eventKey="projectManagement">
                <Form.Group controlId="filterDate">
                  <Form.Label>{t('selectDate')}</Form.Label>
                  <Form.Control
                    type="date"
                    value={filterDate}
                    onChange={handleDateChange}
                  />
                </Form.Group>
                <Button onClick={fetchOldProjects}>{t('fetchOldProjects')}</Button>
                <Button variant="danger" onClick={deleteSelectedProjects} disabled={selectedProjects.length === 0}>
                  {t('deleteSelectedProjects')}
                </Button>

                <h5>{t('oldProjects')}</h5>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          onChange={(e) => setSelectedProjects(e.target.checked ? projects.map(p => p.id) : [])}
                          checked={selectedProjects.length === projects.length && projects.length > 0}
                        />
                      </th>
                      <th>{t('projectId')}</th>
                      <th>{t('userId')}</th>
                      <th>{t('savedTimestamp')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map(project => (
                      <tr key={project.id}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={selectedProjects.includes(project.id)}
                            onChange={() => handleProjectSelection(project.id)}
                          />
                        </td>
                        <td>{project.id}</td>
                        <td>{project.userId || t('noName')}</td>
                        <td>{project.savedTimestamp ? new Date(project.savedTimestamp).toLocaleString() : t('noTimestamp')}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleAdminModal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminModal;
