import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { doc, getDoc } from 'firebase/firestore'; // Make sure to import these
import { db } from '../../base'; // Update the path to your Firebase config
import "../userAccount/accountModal.css"
import { Route, Routes, Link, useLocation } from 'react-router-dom';

Modal.setAppElement('#root'); // Set the element with ID 'root' as the app element

const AccountModal = ({ isOpen, onRequestClose, currentUser, handleLogOut }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {

        const fetchUserData = async () => {
            if (!currentUser || !isOpen) return;

            setLoading(true);
            const userDocRef = doc(db, 'users', currentUser.uid);

            try {
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                } else {
                    setError('No user data found.');
                }
            } catch (err) {
                setError('Failed to fetch user data.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [isOpen, currentUser]);

    const logout = () => {
        // Implement logout logic here
        onRequestClose(); // Close modal after logging out
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Account Information"
            className="account-modal"
            overlayClassName="account-modal-overlay"
        >
            <h2>{t('account.account')}</h2>
            {userData && (
                <>
                    <div><strong>{t('account.userName')}:</strong> {userData.userName}</div>
                    <div><strong>{t('account.userPlan')}:</strong> {userData.planType}</div>
                    {userData.companyName && (
                        <div><strong>{t('account.companyName')}:</strong> {userData.companyName}</div>
                    )}
                    <div>
                        <Link to="/cookies" className="link">{t("consent.betaConsent")}</Link>
                        <Link to="/terms" className="link">{t("consent.betaTerms")}</Link>
                    </div>

                        <Button onClick={handleLogOut} className="button btn-sm">{t('Logout')}</Button>

                </>
            )}

                <Button onClick={onRequestClose} className="button btn-sm">{t('Close')}</Button>

        </Modal>
    );
};

export default AccountModal;
