
export const hasUserConsented = () => {
    return document.cookie.includes("user_consent=true") || document.cookie.includes("user_consent_beta=true");
  };
  
  export const handleAnalyticsEvent = (eventType, eventCategory, eventLabel) => {
    if (hasUserConsented()) {
        // console.log(hasUserConsented())
      window.gtag('event', eventType, {
        'event_category': eventCategory,
        'event_label': eventLabel,
      });
    }
  };

  
  