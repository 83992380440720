import { v1 as uuid } from "uuid";

export const transumsArray = [ 
  {
    name: "Transum 160",
    url: "/transums/Single_Door_Embossed_326_948_Rectangular_Transum_160_Lite.png",
    key: uuid(),
  },

];

export default transumsArray;