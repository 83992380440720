import { v1 as uuid } from "uuid";

export const windowsOptionsArray = [

  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Third_Right_NoGrid.png",
    key: uuid(),
  },
{
  name: "No Grid",
  url: "/windows/sliders/possibleGrids/background_Standard_Slider_Single_NoGrid.png",
  key: uuid(),
},
{
  name: "No Grid",
  url: "/windows/sliders/possibleGrids/background_Standard_Slider_Triple_NoGrid.png",
  key: uuid(),
},
{
  name: "No Grid",
  url: "/windows/sliders/possibleGrids/background_Standard_Slider_Third_Right_NoGrid.png",
  key: uuid(),
},
{
  name: "No Grid",
  url: "/windows/sliders/possibleGrids/background_Standard_Slider_Third_Left_NoGrid.png",
  key: uuid(),
},
{
  name: "No Grid",
  url: "/windows/sliders/possibleGrids/background_Standard_Slider_QuarterHalfQuarter_NoGrid.png",
  key: uuid(),
},
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Third_Left_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Quarter_Half_Quarter_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Quintuple_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Triple_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Single_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Double_NoGrid.png",
    key: uuid(),
  },
    {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/background_Standard_Sash_Quadruple_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Third_Right_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Third_Left_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Quarter_Half_Quarter_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Quint_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Triple_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Single_NoGrid.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Double_NoGrid.png",
    key: uuid(),
  },
    {
    name: "No Grid",
    url: "/windows/casement/possibleGrids/background_Standard_Casement_Quad_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid 1",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid1_GridOnly.png",
    key: uuid(),
  },

  {
    name: "Grid 2",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid17_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 13",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid13_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 14",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid14_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 15",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid15_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 16",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid16_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 17",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid17_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 18",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid18_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 19",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid19_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 21",
    url: "/windows/casement/possibleGrids/Window_Casement_Quad_Grid21_GridOnly.png",
    key: uuid(),
  },

  {
    name: "Grid 1",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid17_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 13",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid13_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 14",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid14_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 15",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid15_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 16",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid16_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 17",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid17_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 18",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid18_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 19",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid19_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 21",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid21_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 20",
    url: "/windows/casement/possibleGrids/Window_Casement_Triple_Grid20_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 1",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 13",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid13_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 14",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid14_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 15",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid15_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 16",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid16_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 17",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid17_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 18",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid18_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 19",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid19_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 20",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid20_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 21",
    url: "/windows/casement/possibleGrids/Window_Casement_Single_Grid21_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 21",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid21_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 20",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid20_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 19",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid19_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 18",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid18_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 17",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid17_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 16",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid16_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 15",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid15_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 14",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid14_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 13",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid13_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4_",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid2_GridOnly.png",
    key: uuid(),
  },

  {
    name: "Grid 1",
    url: "/windows/casement/possibleGrids/Window_Casement_Double_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Quadruple_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Quadruple_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Quadruple_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Quadruple_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Quarter_Half_Quarter_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Quarter_Half_Quarter_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Quarter_Half_Quarter_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Quarter_Half_Quarter_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Quinte_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Quinte_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Quinte_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Quinte_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Left_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Left_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Left_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Left_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Right_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Right_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Right_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Right_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t1",
    url: "/windows/sash/possibleGrids/Window_Sash_Triple_Divider_Top_One_Third_GridT1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "No Grid",
    url: "/windows/sash/possibleGrids/Window_Sash_Triple_Divider_Top_One_Third_NoGrid.png",
    key: uuid(),
  },
  {
    name: "Grid t2",
    url: "/windows/sash/possibleGrids/Window_Sash_Triple_Divider_Top_One_Third_GridT2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid t3",
    url: "/windows/sash/possibleGrids/Window_Sash_Triple_Divider_Top_One_Third_GridT3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sash/possibleGrids/Window_Sash_Quarter_Half_Quarter_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sash/possibleGrids/Window_Sash_Quarter_Half_Quarter_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sash/possibleGrids/Window_Sash_Single_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sash/possibleGrids/Window_Sash_Double_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Left_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Left_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Right_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sash/possibleGrids/Window_Sash_Third_Right_Grid4_GridOnly.png",
    key: uuid(),
  },
  
  {
    name: "Grid 1",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sliders/possibleGrids/Window_Slider_Single_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 1",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 13",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid13_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sliders/possibleGrids/Window_Slider_Quarter_Half_Quarter_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 1",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 10",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid10_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 11",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid11_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 12",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid12_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sliders/possibleGrids/Window_Slider_Triple_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 1",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 2",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid2_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Left_Grid9_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 1",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid1_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 3",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid3_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 4",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid4_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 5",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid5_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 6",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid6_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 7",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid7_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 8",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid8_GridOnly.png",
    key: uuid(),
  },
  {
    name: "Grid 9",
    url: "/windows/sliders/possibleGrids/Window_Slider_Third_Right_Grid9_GridOnly.png",
    key: uuid(),
  },
];

export default windowsOptionsArray;
