import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import "../calibration/calibration.css"
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Optional for styling

const Calibration = ({ setShowCalibrationOverlay, deselectActiveObject, setCalibrationMode, onUnitToggle, onDimensionToggle, setCalibrationDisplay, setIsCalibrating, onKnownLengthChange }) => {
  const { t, i18n } = useTranslation(["translation"]);

  const [knownLength, setKnownLength] = useState(10);  // known lenght'
  const [dimension, setDimension] = useState('width');  // or 'height'
  const [unit, setUnit] = useState('inches');  // or 'cm'
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 428);
  const measurementText = unit === 'inches' ? t('measurement-inch') : t('measurement-cm');

  const dimensionText = dimension === 'width' ? t('dimension-width') : t('dimension-height');
  const knownDimensionText = dimension === 'width' ? t('calibration-knownwidth') : t('calibration-knownheight');


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 428);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleKnownLengthChange = (event) => {
    const newLength = parseInt(event.target.value, 10);
    onKnownLengthChange(newLength);
    setKnownLength(newLength)
    deselectActiveObject();
  };

  // const handleDimensionChange = () => {
  //   const newDimension = dimension === 'width' ? 'height' : 'width';
  //   setDimension(newDimension);
  //   onDimensionToggle(newDimension)
  // };

  const handleChangeDimension = (dimensionValue) => {
    
    setDimension(dimensionValue);
    onDimensionToggle(dimensionValue);
  };

  const handleUnitChange = (newUnit) => {
    setUnit(newUnit);
    onUnitToggle(newUnit);
  };


  const handleClose = () => {
    setShowCalibrationOverlay(false)
    const event = new Event('overlayClosed');
    window.dispatchEvent(event);
    setCalibrationMode(false)
    // To close the overlay and inform App component to deselect
    setIsCalibrating(false); // Or whatever you use to close the overlay
    setCalibrationDisplay(true)

  }

  return (
    <div id="overlay" className="calibration-mode" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
      {/* Title */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h3 className="toolTitle" id="overlay-title">
          {t("calibration-mode")}
        </h3>
      </div>

      {/* Known Length */}
      <Tippy content={t("calibration-tip1")}>
        <div className='calibration'>
          <label htmlFor="known-length" id="overlay-title">{knownDimensionText}</label>
          <input
            type="range"
            id="known-length"
            min="1"
            max="100"
            value={knownLength}
            onChange={handleKnownLengthChange}
          />
          <span>{knownLength} {measurementText}</span>
        </div>
      </Tippy>

     {/* Dimension Toggle */}
<Tippy content={t("calibration-tip1")}>
  <div className="d-flex flex-wrap align-items-center justify-content-between" data-toggle="buttons">
    {/* Dimension Buttons */}
    <div className="d-flex">
      <button
        className={`btn ${dimension === 'height' ? 'btn-primary' : 'btn-secondary'} `}
        onClick={() => handleChangeDimension('height')}
      >
        <img
          className='heightButton'
          src="./icons/height.png"
          height="40px"
          alt="Items"
          title={t('dimension-height')}
          style={{ filter: 'invert(100%)' }}
        />
      </button>
      <button
        className={`btn ${dimension === 'width' ? 'btn-primary' : 'btn-secondary'} ml-2`}
        onClick={() => handleChangeDimension('width')}
      >
        <img
          className='widthButton'
          src="./icons/width.png"
          height="40px"
          alt="Width"
          title={t('dimension-width')}
          style={{ filter: 'invert(100%)' }}
        />
      </button>
    </div>
    
    {/* Unit Buttons */}
    <div className="d-flex align-items-center">
      <button
        className={`btn ${unit === 'inches' ? 'btn-primary' : 'btn-secondary'}`}
        onClick={() => handleUnitChange('inches')}
      >
        Inches
      </button>
      <button
        className={`btn ${unit === 'cm' ? 'btn-primary' : 'btn-secondary'} ml-2`}
        onClick={() => handleUnitChange('cm')}
      >
        CM
      </button>
    </div>
  </div>
</Tippy>



      <button className='btn btn-primary buttonDone' onClick={handleClose}>{t("toolsSelection-done")}</button>

    </div>

  );
};

export default Calibration;
