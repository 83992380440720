

import { v1 as uuid } from "uuid";


export const objectsArray = [ 

  
  {
    name: "NoDecGlass",
    url: "/doors/decorativeGlass/background_Standard_326_948_NoDecGlass.png",
    key: uuid(),
  },
  {
    name: "NoDecGlass",
    url: "/doors/decorativeGlass/background_Standard_326_711_NoDecGlass.png",
    key: uuid(),
  },

  {
    url: "/doors/decorativeGlass/Novatech_Masterline_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Masterline_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Masterline_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Laiton_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Zinc_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Laiton_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Liano_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Laiton_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Patine_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Masterline_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Patine_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Laiton_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Liano_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_patine_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Laiton_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Masterline_326_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Zinc_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_sable_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Louisbourg_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Laiton_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Zenith_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chinchilla_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Pure_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Laiton_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Louisbourg_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Zinc_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Verso_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Laiton_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Zinc_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Laiton_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Patine_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Zenith_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nobel_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Pure_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Louisbourg_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Belmont_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Verso_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Zinc_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Liano_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Laiton_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Zinc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Laiton_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Liano_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Zinc_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_Right_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Zenith_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Sable_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Sable_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Sable_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Sable_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nobel_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Winchester_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_Left_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Laiton_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Laiton_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mistral_Patine_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Patine_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Opal_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nuando_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Evangeline_Zinc_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_Right_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Zinc_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Belmont_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Verso_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Patine_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Liano_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Zinc_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_Left_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kallima_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Zenith_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Laiton_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Portrait_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Q550_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Pure_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Louisbourg_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Opal_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nobel_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Q550_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nuando_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Versum_326_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Q550_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Mystique_Zinc_Demi-lune_326_147.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Kira_326_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Pure_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Zenith_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Sable_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Louisbourg_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Opal_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chinchilla_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Zinc_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Q550_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Zinc_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Verso_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_326_133.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Discretion_blanc_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Ophir_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_Right_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Patine_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Louisbourg_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chinchilla_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Zenith_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Opal_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_Left_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Distinction_demi-lune_326_147.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Discretion_Gris_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Soft_178_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Transit_326_133.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Patine_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chinchilla_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Belmont_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Zinc_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Verso_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Opal_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Zinc_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_326_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Discretion_blanc_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Laiton_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Zinc_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Avenue_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Opal_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Discretion_blanc_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Zinc_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Discretion_blanc_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cathedrale_Patine_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Verso_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Discretion_gris_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Attraction_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_205_1170.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nobel_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Zinc_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Fluid_178_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Rythme_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Belmont_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_Patine_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Avenue_326_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Arum_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Allure_Laiton_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_326_133.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_118_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Edge_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Chanelle_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nobel_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_326_252.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_demi-lune_326_147.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_326_211.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Nobel_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Bistro_cambree_Patine_326_118.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Cachet_Patine_cambre_326_118.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_326_118.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Celeste_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Equation_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Alys_cambre_326_118.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_337_172.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Avenue_118_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Avenue_118_533.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Carrelage_distinction_326_711.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Azur_326_178.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_CARR_CAAL_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Carrelage_Champetre_326_948.png",
    key: uuid(),
  },
  {
    url: "/doors/decorativeGlass/Novatech_Carrelage_Champetre_326_711.png",
    key: uuid(),
  }

];

// Your array of objects
// const objectsArray = [
//   {
//     url: "/doors/decorativeGlass/Novatech_Carrelage_Champetre_326_711.png",
//     key: uuid(),
//   },
//   // ... (other objects)
// ];

// Create a new array with "name" property
const novatechDecorativeGlassArray = objectsArray.map((obj) => {
  // Use regex to extract the name from the URL
  const match = obj.url.match(/\/doors\/decorativeGlass\/Novatech_(.*?)_\d+_\d+\.png/);

  if (match && match[1]) {
    obj.name = match[1].replace(/_/g, ' '); // Remove underscores and store as "name"
  } else {
    obj.name = 'NoDecGlass'; // Set name as empty string if pattern not found
  }

  return obj;
});

    export default novatechDecorativeGlassArray;