import React from "react";
// import { lazy } from "react";
import allModelsDoorsArray from "../../components/itemSelectionView/inventory/allModelsDoorsArray";
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import Box from "@mui/material/Box";
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from "react-i18next";
import "../doorsSelectionView/doorsSelectionView.css"

function DoorsSelectionView(props) {

  const { t } = useTranslation(["translation"]);

  let doorType;
  if (props.typeName){

  doorType = props.typeName.substring(0, 20);

  }



  function findDoorName() {
    switch (doorType) {
      case "Single_Door_E":
        return "Single_Door_E";

      case "Single_Door":
        return "Single_Door_E";

      case "Single_Door_Right_Si":
        return "Single_Door_Right_SideLight";

      case "Single_Door_Left_Sid":
        return "Single_Door_Left_SideLight";

      case "Single_Door_Right_Le":
        return "Single_Door_Right_Left_SideLight";

      case "Double_Door":
        return "Double_Door_E";

      case "Double_Door_E":
        return "Double_Door_E";

      case "Double_Door_Right_Le":
        return "Double_Door_Right_Left_SideLight";

        default:
      return "Single_Door_E";
    }
    
  }

  function onClickEvent(doorUrl) {
    props.addItem(doorUrl, findDoorName(), null, false);


    props.setIsAvaillableDoorsPanelVisible(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <>
      <div className="closeBtn">
        <button className="btn btn-primary"
          onClick={() => {
            props.setIsAvaillableDoorsPanelVisible(false);
            props.setIsAccessoriesPanelVisible(false);
            document.body.style.overflow = 'auto'
          }}
        >{t("doorSelection-close")}
        </button>
      </div>
      <div className="selectionTitle">{t("doorSelection-availlableDoors")}</div>
      <div className="list bg-transparent">
         <ListGroup className="doorsListItems" variant="flush" >
            {props.typeName &&
              allModelsDoorsArray.map((item) => 
                item.url.includes(findDoorName()) && !item.url.includes("8ft") && !item.url.includes("6ft") ? (
                
                    <ListGroup.Item key={item.key} className="bg-transparent">
                        <div style={{ position: 'relative', cursor: 'pointer', padding: '10px' }} onClick={() => onClickEvent(item.url)}>
                            <img
                                src={item.url}
                                alt={findDoorName()}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '160px',
                                    border: '5px solid transparent' // Adjusted border style
                                }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(0,0,0,0)', // Transparent overlay
                                    zIndex: 2
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                            ></div>
                        </div>
                    </ListGroup.Item>
        
                
                  
                ) : (
                  null
                )
              )}
</ListGroup>
      </div>
    </>
  );
}

export default DoorsSelectionView;
