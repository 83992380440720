import React from 'react';
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon
import i18n from 'i18next';

const LoadingToast = ({ message = i18n.t("UpdatingProject" )}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <FaSpinner style={{ marginRight: '8px', animation: 'spin 1s linear infinite' }} />
    <span>{message}</span>
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

export default LoadingToast;
