import React, { useState, useEffect } from "react";
import doorsArray from "../itemSelectionView/inventory/doorsArray";
import windowsArray from "../itemSelectionView/inventory/windowsArray";
import garageDoorsArray from "../itemSelectionView/inventory/garageDoorsArray"
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";
import { storage, getDownloadURL, ref } from "../../base";
import "../itemSelectionView/itemSelectionView.css"

function ItemSelectionView(props) {

  const { t } = useTranslation(["translation"]);
  // const [isLoading, setIsLoading] = useState(true);


  function arrayChoice() {
    switch (props.itemToSearch) {
      case "Single Door":
      case "Single Door Right SideLight":
      case "Single Door Right SideLight Full":
      case "Single Door Left SideLight":
      case "Single Door Left SideLight Full":
      case "Single Door Right Left SideLight":
      case "Single Door Right Left SideLight Full":
      case "Double Door":
      case "Double Door Right Left SideLight":
      case "Double Door Right Left SideLight Full":
        return doorsArray;

      case "Casement":
      case "Sash":
      case "Slider":
      case "Contemporairy":
      case "Fixe":
        return windowsArray;

      case "Garage Door":
        return garageDoorsArray;

      default:
        return windowsArray;
    }
  }


  function onClickEvent(item, tName) {


    const storageRef = storage.ref();
    const imageRef = ref(storageRef, item.url); // Assuming the URL structure in Firebase matches the local structure
    getDownloadURL(imageRef)
      .then(highQualityURL => {
        document.body.style.overflow = 'auto';

        if (!tName.replaceAll('_', ' ').includes('Door')) {
          props.addItem(highQualityURL, tName, null, false);
          document.body.style.overflow = 'auto';
          props.setIsPanelVisible(false);
        } else if (tName.replaceAll('_', ' ').includes('Garage Door')) {
          props.addItem(highQualityURL, tName, null, false);
        } else {
          props.handleDoorsView(tName, highQualityURL);
        }
      });
  }

  return (
    <>
      <div className='closeBtn'>
        <button className='btn btn-primary'
          onClick={() => {
            props.setIsPanelVisible(false);
            document.body.style.overflow = 'auto';
          }}
        >
          {t('itemSelection-close')}
        </button>
      </div>
      <div className='selectionTitle'>{t('itemSelection-configurations')}</div>
      <div className='list bg-transparent'>
        <ListGroup className='selectionListItems' variant='flush'>
          {props.itemToSearch &&
            arrayChoice().map((item, index) => {
              const imgName = item.name.replaceAll('_', ' ');

              if (
                imgName.includes(props.itemToSearch) &&
                !item.url.includes('Full') &&
                !item.url.includes('SidelightB')
              ) {
                // Using local low-quality images from the 'public' folder for immediate display
                const localImagePath = process.env.PUBLIC_URL + item.url;

                return (
                  <ListGroup.Item key={item.key} className='bg-transparent'>
                    <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => onClickEvent(item, item.name)}>
                      <img
                        src={localImagePath}
                        alt={imgName}
                        title={imgName}
                        style={{ maxWidth: '100%', maxHeight: '160px' }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0,0,0,0)', // Transparent overlay
                          zIndex: 2
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      ></div>
                    </div>
                  </ListGroup.Item>
                );

              }

              return null;
            })}
        </ListGroup>
      </div>
    </>
  );
}

export default ItemSelectionView;
