import React from "react";
import { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "tippy.js/dist/tippy.css";
import "./transumSelectionView.css";

function Transum(props) {

  let doorOrWindow = props.typeName.includes("Door") ? true : false;

  const handleDropdownChange = (e) => {
  var isSelect = props.checkForSeletedItems();
    var rType = props.checkSelectedObjectType();
    var isMix = rType.isMix;
    var isSame = rType.isSame;
    var type = rType.type;

    if (!e && !isSelect) {
      alert("Please add an item");
      {
        return;
      }
    } else if (!isSelect) {
      alert("Please select an item");
      {
        return;
      }
    } else if (e && !isSelect) {
      alert("Please select an item");
      {
        return;
      }
    } else if (!isSame && isMix) {
      alert("Cannot change options, please select same type of items");
      return;
    } else if (isSelect) {

    }

    props.handleOptionsPanel(e);

  };



  return (
    <>
      <DropdownButton
        title={
          <img
            className="optionsButton"
            src="./icons/optionsButton.png"
            height="40px"
            alt="Options"
            title="Transums"
          />
        }
        id="dropdown-button-dark"
        variant="secondary"
        menuVariant="dark"
        size="sm"
        onSelect={handleDropdownChange}
        drop="end"
        className="btn mt-1 btn-block pb-0 m-0"
      >
        {!doorOrWindow && (
          <Dropdown.Item eventKey={props.typeName}>
            Transums
            <>
              <img
                src="./thumbnails/Window_Casement_Double_Grid1.png"
                height={20}
                alt="Grid"
                style={{
                  paddingLeft: 10,
                }}
              />
            </>
          </Dropdown.Item>
        )}
        {doorOrWindow && (
          <Dropdown.Item eventKey={props.typeName}>
            Decorative Glass
            <>
              <img
                src="./thumbnails/Vitrail_Novatech_Bolero_22x48_Thumbnail.png"
                height={20}
                alt="Decorative Glass"
                style={{
                  paddingLeft: 10,
                }}
              />
            </>
          </Dropdown.Item>
        )}
        <Dropdown.Item disabled eventKey="background">
          Background To-do transum{" "}
          <>
            <img
              src="./thumbnails/Window_Slider_Single.png"
              height={20}
              alt="Background"
              style={{
                paddingLeft: 10,
              }}
            />
          </>
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
  }

export default Transum