import { v1 as uuid } from "uuid";

export const garageDoorsArray = [
  {
    url: "garageDoors/GarageDoor_Classic_9x7.png",
    key: uuid(),
    name: "Garage_Door_Single",
  },
  {
    url: "garageDoors/GarageDoor_Classic_9x7_Clear.png",
    key: uuid(),
    name: "Garage_Door_Single_With_Glass",
  },
  {
    url: "/garageDoors/GarageDoor_Classic_16x7.png",
    key: uuid(),
    name: "Garage_Door_Double",
  },
  {
    url: "/garageDoors/GarageDoor_Classic_16x7_Clear.png",
    key: uuid(),
    name: "Garage_Door_Double_With_Glass",
  }
]


export default garageDoorsArray;