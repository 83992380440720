import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  storage,
  useAuth,
  db,
  auth,
  deleteObject,
  ref,
  uploadString,
  uploadBytes,
  getStorage,
  listAll,
} from "../../base";
import { Form, Modal, Button, Collapse } from "react-bootstrap";
import "../account/account.css";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import "../loader.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Edit,
  CloudDownload,
  Delete,
  Build,
  OpenInFull,
} from "@mui/icons-material";

function Account(props) {
  const [loading, setLoading] = useState(true);
  // const [projects, setProjects] = useState([]);
  const [lastProjectId, setLastProjectId] = useState("");
  const [projectUrl, setProjectUrl] = useState("");
  const [projectName, setProjectName] = useState("");
  const [closeUpload, setCloseUpload] = useState(false);
  const [closeAccount, setCloseAccount] = useState(true);
  const [imageURL, setImageURL] = useState("");
  const [imageName, setImageName] = useState("");
  const [canvasJson, setCanvasJson] = useState({});
  const [isProjectList, setIsProjectList] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  const [userName, setUserName] = useState("");
  const [userPlan, setUserPlan] = useState("");
  const [companyName, setCompanyName] = useState("");

  const projectImageRef = useRef();

  const { t } = useTranslation(["translation"]);

  const currentUser = useAuth();

  const loggeInUser = auth.currentUser.uid;

  const projectNameRef = useRef();
  const isMountedRef = useRef(true);

  
  async function handleOpen(id) {
    props.onShowLoader();
    props.setProjectId(id);
    props.clearCanvas();

    try {
        // Fetch the project directly from Firestore instead of using possibly outdated local state
        const docRef = doc(db, "Projects", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const projectData = docSnap.data();

            if (projectData.projectJson && projectData.projectJson.backgroundImage) {
                setImageURL(projectData.projectJson.backgroundImage.src);
            }

            // Load the project onto the canvas
            await props.loadProject(projectData.projectJson, projectData.scaleFactor);

            // Update the scale factor if available
            if (projectData.scaleFactor !== undefined) {
                props.setScaleFactor(projectData.scaleFactor);
              }

            // Only continue if the component is still mounted
            if (isMountedRef.current) {
                setCanvasJson(projectData.projectJson);

                // Ensure canvasProjectImage is applied
                if (projectData.canvasProjectImage && projectData.canvasProjectImage !== "MISSING_IMAGE_URL") {
                    setImageURL(projectData.canvasProjectImage);
                } else {
                    console.warn("Missing or invalid canvas project image URL");
                }

                // Now set other properties
                await props.closeAccount(false);
                await props.handleProjectName(projectData.projectName);

                props.onHideLoader();
                document.body.style.overflow = "auto";
            }
        } else {
            console.error("No project found with ID:", id);
            props.onHideLoader();
        }
    } catch (error) {
        console.error("An error occurred while opening the project:", error);
        props.onHideLoader();
    }
}




  async function handleDelete(id) {
    if (window.confirm("Are you sure you want to delete this project?\nYou cannot undo this action")) {
      try {
        // Fetch the project from Firestore.
        const docRef = doc(db, "Projects", id);
        const docData = await getDoc(docRef);

        if (!docData.exists) {
          console.error("No project found");
          return; // Exit early if the document is not found.
        }

        let imageName = docData.data().imageName;
        if (!imageName) {
          console.error("Project name not found");
          return;
        }

        console.log("image name", imageName)

        // Construct the base name for the image using the new naming convention.
        const baseName = `${imageName}`;

        const baseNameUnderscore = baseName.replaceAll(" ", "_");

        // Construct paths for the images in various folders.
        const originalImagePath = `usersProjectsImages/${loggeInUser}/canvasImages/${baseNameUnderscore}`;
        const canvasTreatedImagePath = `usersProjectsImages/${loggeInUser}/canvasImages/canvasImagesTreated/${baseNameUnderscore}.png`;
        const canvasResizedImagePath = `usersProjectsImages/${loggeInUser}/canvasImages/canvasImagesResized/${baseNameUnderscore}_resized.png`;
        const canvasWebPImagePath = `usersProjectsImages/${loggeInUser}/canvasImages/canvasImagesWebP/${baseNameUnderscore}_webp.webp`;
        // Delete from canvasImages
        const originalImageRef = ref(storage, originalImagePath);
        await deleteObject(originalImageRef);

        // Delete from canvasImagesTreated
        const canvasTreatedImageRef = ref(storage, canvasTreatedImagePath);
        await deleteObject(canvasTreatedImageRef);

        // Delete from canvasImagesResized
        const canvasResizedImageRef = ref(storage, canvasResizedImagePath);
        await deleteObject(canvasResizedImageRef);

          // Delete from canvasImagesResized
          const canvasWebPImageRef = ref(storage, canvasWebPImagePath);
          await deleteObject(canvasWebPImageRef);
  

        // Delete the project document from Firestore.
        await deleteDoc(docRef);
        props.setEditComplete(true)

        props.closeAccount(false);

        // Check if the project to be deleted is the current one, if so, clear the canvas.
        const currentProject = props.projects.find((project) => project.id === id);
        if (currentProject) {
          props.clearCanvas();
          props.setProjectName("");
        }
      } catch (error) {
        console.error("Error deleting project:", error);
      }
    }
  }



  async function handleEdit(id) {

    props.onShowLoader();

    const jsonProjectFile = props.projects.find((js) => js.id === id);

    const imageFile = props.projects.find((im) => im.id === id);

    const name = props.projects.find((na) => na.id === id);

    const projectName = prompt("Enter a New Name", name.projectName);

    // Check if the user canceled the prompt
    if (projectName === null) {
      props.onHideLoader();
      return;
    }

    const docRef = doc(db, "Projects", id);
    const payload = {
      projectName: projectName.replaceAll(" ", "_") || name.projectName,
      userId: loggeInUser,
      imageURL: jsonProjectFile.imageURL,
      projectJson: jsonProjectFile.projectJson,
    };
    await updateDoc(docRef, payload);
    props.handleProjectName(projectName);
    props.setEditComplete(true)

    props.onHideLoader();
  }

  
  const getResizedImageURL = (project) => {
    if (project && project.savedTimestamp) {
      if (project.imageURL && project.imageURL !== "MISSING_IMAGE_URL") {
        const resizedURL = project.imageURL.replace(/\.png$/, "_resized.png");
        return resizedURL.replace(
          "/canvasImages/",
          "/canvasImages/canvasImagesResized/"
        );
      } else if (project.projectJson && project.projectJson.canvasProjectImage && project.projectJson.canvasProjectImage !== "MISSING_IMAGE_URL") {
        const resizedURL = project.projectJson.canvasProjectImage.replace(/\.png$/, "_resized.png");
        return resizedURL.replace(
          "/canvasImages/",
          "/canvasImages/canvasImagesResized/"
        );
      } else {
        console.error("Project image URL missing or invalid for project:", project.projectName);
        return "";
      }
    } else {
      return project.imageURL;
    }
  };
  
  
  

  useEffect(() => {
    if (!props.setIsProjectList) projectNameRef.current.focus();
  });

  useEffect(() => {
    return () => {
      isMountedRef.current = false; // Component will unmount
      props.onHideLoader();
      // document.body.style.overflow = "auto";
    };
  }, []); // Empty dependency array ensures this runs on mount and unmount only


  return (
    <Modal
      show={true}
      onHide={() => {
        props.closeAccount(false);
        document.body.style.overflow = "auto";
      }}
      backdrop="static"
      keyboard={false}
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("account.account")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isDownloading && <div className="loader">{t("account.loading")}</div>}

        {!props.isProjectList && props.projectName && (
          <div className="enterName">
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                {!props.projectName && (
                  <Form.Label>{t("account.enterName")}</Form.Label>
                )}
                {props.projectName && (
                  <Form.Control
                    type="text"
                    value={props.projectName.replaceAll("_", " ")}
                    ref={projectNameRef}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                )}
              </Form.Group>
            </Form>
        
          </div>
        )}



          <div className="currentProject">
          <h3>{t("account.savedProjects")}</h3>
          <hr />
          <div className="projectDetails">
            {props.projects.map((project) => (
              <Fragment key={project.id}>
                {project.userId === loggeInUser && (
                  <ul>
                    <li>
                      <div className="projectTitle">
                        <h4>{project.projectName.replaceAll("_", " ")}</h4>
                      </div>
                      <div className="projectImageContainer">
                        <img
                          className="projectImage"
                          src={getResizedImageURL(project)}
                          ref={projectImageRef}
                          onClick={() => handleOpen(project.id)}
                          alt={project.projectName}
                        />
                      </div>
                      <div className="listButttons">
                        <Button
                          variant="secondary"
                          className="openProject"
                          onClick={() => handleOpen(project.id)}
                        >
                          <OpenInFull
                            fontSize="small"
                            style={{ marginRight: "5px" }}
                          />
                          {t("account.openProject")}
                        </Button>
                        <Button
                          variant="secondary"
                          className="editName"
                          onClick={() => handleEdit(project.id)}
                        >
                          <Edit
                            fontSize="small"
                            style={{ marginRight: "5px" }}
                          />
                          {t("account.editName")}
                        </Button>
                        <Button
                          variant="secondary"
                          className="editName"
                          onClick={() =>
                            props.downloadpng(
                              project.imageURL,
                              project.projectName
                            )
                          }
                        >
                          <CloudDownload
                            fontSize="small"
                            style={{ marginRight: "5px" }}
                          />
                          {t("account.downloadPng")}
                        </Button>
                        <Button
                          variant="danger"
                          className="deleteProject"
                          onClick={() => handleDelete(project.id)}
                        >
                          <Delete
                            fontSize="small"
                            style={{ marginRight: "5px" }}
                          />
                          {t("account.deleteProject")}
                        </Button>
                        <div className="projectDivider"></div>
                      </div>
                    </li>
                  </ul>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Account;
