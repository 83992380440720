function sizeAndPosition(glassToFind, aiDetectedDimensions = null) {

  const isAI = glassToFind.endsWith("AI");
  const baseName = isAI ? glassToFind.slice(0, -2) : glassToFind;


  const cutOuts = {
    Single_Door_EmbossedM4GlassAt1234NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedM3GlassAt123NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedM2GlassAt123NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedM1GlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedLLGlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedKSGlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedFBGlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedF2GlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedCSGlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },

    Single_Door_EmbossedBBGlassAt1NoSidelightB: {
      height: 930,
      top: 568,
      width: 300,
      left: 602,
      cutoutSize: "326_948",
    },
    Single_Door_EmbossedFantasiaNoSidelight: {
      height: 948,
      top: 90,
      width: 330,
      left: 430,
      cutoutSize: "326_948",
    },
    Single_Door_EmbossedLineaGlassNoSidelight: {
      height: 1000,
      top: 100,
      width: 118,
      left: 676,
      cutoutSize: "118_948",
    },
    Single_Door_EmbossedShakerGlassAt4NoSidelight: {
      height: 200,
      top: 70,
      width: 360,
      left: 438,
      cutoutSize: "326_211",
    },
    Single_Door_EmbossedPlazaNoSidelight: {
      height: 948,
      top: 86,
      width: 326,
      left: 440,
      cutoutSize: "326_948",
    },
    Single_Door_EmbossedDevilleNoSidelight: {
      height: 820,
      top: 53,
      width: 350,
      left: 428,
      cutoutSize: "326_800",
    },
    Single_Door_EmbossedCorriente4NoSidelight: {
      height: 170,
      top: 86,
      width: 350,
      left: 438,
      cutoutSize: "326_178",
      top1: 355,
      top2: 600,
      top3: 900,
    },
    Single_Door_EmbossedCorriente5NoSidelight: {
      height: 133,
      top: 86,
      width: 326,
      left: 438,
      cutoutSize: "326_133",
      top1: 300,
      top2: 500,
      top3: 720,
      top4: 920,
    },
    Single_Door_EmbossedCorriente4NoSidelight2: {
      height: 170,
      top: 80,
      width: 326,
      left: 438,
      cutoutSize: "326_178",
      top1: 339,
      top2: 607,
      top3: 870,
    },
    Single_Door_Embossed2BGlassAt2NoSidelight: {
      height: 711,
      top: 90,
      width: 326,
      left: 440,
      cutoutSize: "326_711",
    },
    Single_Door_Embossed3AGlassAt3NoSidelight: {
      height: 252,
      top: 84,
      width: 300,
      left: 438,
      cutoutSize: "326_252",
    },
    Single_Door_Embossed3BGlassAt3NoSidelight2: {
      height: 211,
      top: 81,
      width: 300,
      left: 431,
      cutoutSize: "326_211",
    },
    Single_Door_Embossed3BGlassAt3NoSidelight: {
      height: 211,
      top: 71,
      width: 300,
      left: 440,
      cutoutSize: "326_211",
    },
    Single_Door_Embossed1AGlassAt1NoSidelight: {
      height: 948,
      top: 90,
      width: 310,
      left: 441,
      cutoutSize: "326_948",
    },
    //   Single_Door_Embossed1AGlassAt1NoSidelightAI: {
    //   height: 0, 
    //   // top: 0, 
    //   width: 0, 
    //   // left: 0,
    //   cutoutSize: "326_948",
    // },
    Single_Door_EmbossedAAGlassAt1NoSidelightB: {
      height: 948,
      top: 102,
      width: 310,
      left: 438,
      cutoutSize: "326_948",
    },
    Single_Door_Embossed2AGlassAt2NoSidelight: {
      height: 533,
      top: 90,
      width: 326,
      left: 440,
      cutoutSize: "326_533",
    },
    Single_Door_Embossed3AGlassAt123NoSidelight: {
      height: 252,
      top: 86,
      width: 300,
      left: 437,
      cutoutSize: "326_252",
      top1: 445,
      top2: 804,
    },

    Single_Door_Embossed2AGlassAt2SidelightFull: {
      height: 533,
      top: -14,
      width: 300,
      left: 602,
      cutoutSize: "326_533",
    },
    Single_Door_Right_SideLight_Embossed2AGlassAt2Sidelight1A: {
      height: 533,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed2AGlassAt2Sidelight2A: {
      height: 533,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed2BGlassAt2Sidelight2B: {
      height: 711,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed2BGlassAt2SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed2BGlassAt2Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed2BNoGlassSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed2AGlassAt2Sidelight1A: {
      height: 533,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed2AGlassAt2SidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed2ANoGlassSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed2ANoGlassSidelight2A: {
      height: 533,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_533",
    },
    Single_Door_Left_SideLight_EmbossedFantasiaSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_EmbossedPlazaSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_EmbossedPlazaSidelight1A: {
      height: 948,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_948",
    },
    Single_Door_Right_SideLight_EmbossedFantasiaSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Right_SideLight_EmbossedPlazaSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Right_SideLight_EmbossedPlazaSidelight1A: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_EmbossedGendarmeSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_948",
    },
    Single_Door_Right_SideLight_OsoWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_OsoWithSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_OsoWithSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_OsoWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_VogWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_VogWithSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_VogWithSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 565,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_VogWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_EmbossedGendarmeSidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Left_SideLight_LineaWithSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_LineaWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_LineaWithSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_LineaWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_FlatWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_FlatWithSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 312,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed2ANoGlassSidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "0_0",
    },
    Single_Door_Right_SideLight_Embossed2ANoGlassSidelight2A: {
      height: 533,
      top: 70,
      width: 118,
      left: 312,
      cutoutSize: "118_533",
    },
    Single_Door_Right_SideLight_Embossed2AGlassAt2Sidelight2A: {
      height: 533,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_533",
    },
    Single_Door_Right_SideLight_Embossed2AGlassAt2SidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_533",
    },
    Single_Door_Right_SideLight_Embossed2BNoGlassSidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "0_0",
    },
    Single_Door_Right_SideLight_Embossed2BGlassAt2Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_Embossed2BGlassAt2SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_EmbossedDevilleSidelightCatalogue: {
      height: 948,
      top: 90,
      width: 326,
      left: 312,
      cutoutSize: "326_948",
    },
    Single_Door_Embossed6AGlassAt3456NoSidelight: {
      height: 533,
      top: 90,
      width: 300,
      left: 441,
      cutoutSize: "326_533",
    },
    Single_Door_Embossed5AGlassAt5NoSidelight: {
      height: 118,
      top: 90,
      width: 300,
      left: 440,
      cutoutSize: "326_118",
    },
    Single_Door_Embossed6BGlassAt3456NoSidelight: {
      height: 711,
      top: 91,
      width: 300,
      left: 438,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_Embossed2BGlassAt2Sidelight2B: {
      height: 711,
      top: 90,
      width: 326,
      left: 310,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_Embossed2BNoGlassSidelight2B: {
      height: 711,
      top: 90,
      width: 118,
      left: 310,
      cutoutSize: "118_711",
    },
    Single_Door_Left_SideLight_Embossed2BNoGlassSidelight2B: {
      height: 711,
      top: 90,
      width: 118,
      left: 310,
      cutoutSize: "118_711",
    },
    Single_Door_Right_SideLight_EmbossedCorriente4Sidelight1A: {
      height: 178,
      top: 86,
      width: 326,
      left: 310,
      cutoutSize: "326_178",
      top1: 355,
      top2: 600,
      top3: 900,
    },
    Single_Door_Right_SideLight_EmbossedCorriente4SidelightFull: {
      height: 178,
      top: 86,
      width: 326,
      left: 310,
      cutoutSize: "326_178",
      top1: 355,
      top2: 600,
      top3: 900,
    },
    Single_Door_Left_SideLight_EmbossedCorriente4Sidelight1A: {
      height: 178,
      top: 86,
      width: 326,
      left: 560,
      cutoutSize: "326_178",
      top1: 355,
      top2: 600,
      top3: 900,
    },
    Single_Door_Left_SideLight_EmbossedCorriente4SidelightFull: {
      height: 178,
      top: 86,
      width: 326,
      left: 560,
      cutoutSize: "326_178",
      top1: 355,
      top2: 600,
      top3: 900,
    },
    Single_Door_Right_SideLight_EmbossedCorriente5Sidelight1A: {
      height: 133,
      top: 92,
      width: 326,
      left: 310,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
    },
    Single_Door_Right_SideLight_EmbossedCorriente5Sidelight1A: {
      height: 133,
      top: 92,
      width: 326,
      left: 310,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
    },
    Single_Door_Right_SideLight_EmbossedCorriente5SidelightFull: {
      height: 133,
      top: 92,
      width: 326,
      left: 310,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
    },
    Single_Door_Left_SideLight_EmbossedCorriente5Sidelight1A: {
      height: 133,
      top: 92,
      width: 326,
      left: 560,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
    },
    Single_Door_Left_SideLight_EmbossedCorriente5SidelightFull: {
      height: 133,
      top: 92,
      width: 326,
      left: 560,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
    },
    Single_Door_Right_SideLight_Embossed6BGlassAt3456Sidelight2B: {
      height: 711,
      top: 90,
      width: 326,
      left: 310,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_Embossed6BGlassAt3456Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 310,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_Embossed6BGlassAt3456SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 310,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed1AGlassAt1Sidelight1A: {
      height: 940,
      top: 94,
      width: 326,
      left: 567,
      cutoutSize: "326_948",
    },
    Single_Door_Right_SideLight_Embossed1ANoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed1ANoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 565,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed2ANoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed2BNoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed3ANoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed3ANoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed3AGlassAt123Sidelight1A: {
      height: 218,
      top: 80,
      width: 311,
      left: 314,
      cutoutSize: "326_252",
      top1: 445,
      top2: 804,
    },
    Single_Door_Right_SideLight_Embossed3AGlassAt123SidelightFull: {
      height: 218,
      top: 80,
      width: 311,
      left: 314,
      cutoutSize: "326_252",
      top1: 445,
      top2: 804,
    },
    Single_Door_Left_SideLight_Embossed3AGlassAt123Sidelight1A: {
      height: 218,
      top: 80,
      width: 311,
      left: 564,
      cutoutSize: "326_252",
      top1: 445,
      top2: 804,
    },
    Single_Door_Left_SideLight_Embossed3AGlassAt123SidelightFull: {
      height: 218,
      top: 80,
      width: 311,
      left: 562,
      cutoutSize: "326_252",
      top1: 445,
      top2: 810,
    },
    Single_Door_Right_SideLight_Embossed3AGlassAt3Sidelight1A: {
      height: 218,
      top: 90,
      width: 311,
      left: 315,
      DLeft: 695,
      cutoutSize: "326_252",
    },
    Single_Door_Right_SideLight_Embossed3AGlassAt3SidelightFull: {
      height: 252,
      top: 80,
      width: 326,
      left: 315,
      cutoutSize: "326_252",
    },
    Single_Door_Left_SideLight_Embossed3AGlassAt3Sidelight1A: {
      height: 252,
      top: 80,
      width: 326,
      left: 564,
      DLeft: 695,
      cutoutSize: "326_252",
    },
    Single_Door_Left_SideLight_Embossed3AGlassAt3SidelightFull: {
      height: 252,
      top: 80,
      width: 326,
      left: 564,
      DLeft: 695,
      cutoutSize: "326_252",
    },

    Single_Door_Left_SideLight_Embossed3BGlassAt3Sidelight1A: {
      height: 211,
      top: 67,
      width: 326,
      left: 560,
      cutoutSize: "326_211",
    },
    Single_Door_Left_SideLight_Embossed6BGlassAt3456Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 560,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed6BGlassAt3456SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 560,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed6BGlassAt3456Sidelight2A: {
      height: 711,
      top: 86,
      width: 326,
      left: 560,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed6BGlassAt3456Sidelight2B: {
      height: 711,
      top: 86,
      width: 326,
      left: 560,
      cutoutSize: "326_711",
    },
    Single_Door_Left_SideLight_Embossed6AGlassAt3456Sidelight1A: {
      height: 533,
      top: 90,
      width: 326,
      left: 560,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed6AGlassAt3456SidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 560,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed6AGlassAt3456Sidelight2A: {
      height: 533,
      top: 86,
      width: 326,
      left: 560,
      cutoutSize: "326_533",
    },
    Single_Door_Right_SideLight_Embossed6AGlassAt3456Sidelight2A: {
      height: 533,
      top: 86,
      width: 326,
      left: 310,
      cutoutSize: "326_533",
    },
    Single_Door_Right_SideLight_Embossed6AGlassAt3456Sidelight1A: {
      height: 533,
      top: 86,
      width: 326,
      left: 310,
      cutoutSize: "326_533",
    },
    Single_Door_Right_SideLight_Embossed6AGlassAt3456SidelightFull: {
      height: 533,
      top: 86,
      width: 326,
      left: 310,
      cutoutSize: "326_533",
    },
    Single_Door_Left_SideLight_Embossed3BGlassAt3SidelightFull: {
      height: 211,
      top: 67,
      width: 326,
      left: 560,
      cutoutSize: "326_211",
    },
    Single_Door_Right_SideLight_Embossed3BGlassAt3SidelightFull: {
      height: 211,
      top: 67,
      width: 326,
      left: 310,
      cutoutSize: "326_211",
    },
    Single_Door_Right_SideLight_Embossed3BGlassAt3Sidelight1A: {
      height: 211,
      top: 67,
      width: 326,
      left: 310,
      cutoutSize: "326_211",
    },


    Single_Door_Right_SideLight_Embossed3BNoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed3BNoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed6CNoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed6CNoGlassSidelight1A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed6CNoGlassSidelight3A: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "0_0",
    },
    Single_Door_Left_SideLight_Embossed6CNoGlassSidelightFlat: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Right_SideLight_Embossed6CNoGlassSidelightFlat: {
      height: 948,
      top: 94,
      width: 118,
      left: 828,
      cutoutSize: "118_948",
    },
    Single_Door_Left_SideLight_Embossed1AGlassAt1SidelightFull: {
      height: 940,
      top: 94,
      width: 326,
      left: 567,
      cutoutSize: "326_948",
    },
    Single_Door_Right_Left_SideLight_Embossed3AGlassAt123Sidelight1A: {
      height: 252,
      top: 80,
      width: 326,
      left: 436,
      cutoutSize: "326_252",
      top1: 445,
      top2: 804,
    },
    Single_Door_Right_Left_SideLight_Embossed3AGlassAt3Sidelight1A: {
      height: 252,
      top: 80,
      width: 326,
      left: 436,
      cutoutSize: "326_252",
    },
    Single_Door_Right_Left_SideLight_Embossed3AGlassAt3SidelightFull: {
      height: 252,
      top: 80,
      width: 326,
      left: 436,
      cutoutSize: "326_252",
    },
    Single_Door_Right_Left_SideLight_Embossed3ANoGlassSidelight1A: {
      height: 948,
      top: 80,
      width: 118,
      left: 436,
      cutoutSize: "118_948",
    },
    Single_Door_Right_Left_SideLight_Embossed3ANoGlassSidelightFull: {
      height: 252,
      top: 80,
      width: 326,
      left: 436,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_Embossed3ANoGlassSidelightFull: {
      height: 252,
      top: 80,
      width: 326,
      left: 436,
      cutoutSize: "0_0",
    },
    Single_Door_Right_Left_SideLight_Embossed3BGlassAt3Sidelight1A: {
      height: 211,
      top: 65,
      width: 326,
      left: 436,
      cutoutSize: "326_211",
    },
    Single_Door_Right_Left_SideLight_Embossed3BGlassAt3SidelightFull: {
      height: 211,
      top: 65,
      width: 326,
      left: 436,
      cutoutSize: "326_211",
    },
    Single_Door_Right_Left_SideLight_Embossed3BNoGlassSidelight1A: {
      height: 948,
      top: 65,
      width: 118,
      left: 436,
      cutoutSize: "118_948",
    },
    Single_Door_Right_Left_SideLight_Embossed3BNoGlassSidelightFull: {
      height: 211,
      top: 65,
      width: 326,
      left: 436,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_Embossed3BNoGlassSidelightFull: {
      height: 211,
      top: 65,
      width: 326,
      left: 436,
      cutoutSize: "0_0",
    },
    Single_Door_Right_Left_SideLight_Embossed3AGlassAt123SidelightFull: {
      height: 252,
      top: 80,
      width: 326,
      left: 436,
      cutoutSize: "326_252",
      top1: 445,
      top2: 804,
    },
    Single_Door_Right_Left_SideLight_Embossed1AGlassAt1SidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 436,
      cutoutSize: "326_948",
    },
    Single_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A: {
      height: 948,
      top: 90,
      width: 326,
      left: 429,
      cutoutSize: "326_948",
    },
    Single_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight1A: {
      height: 533,
      top: 90,
      width: 326,
      left: 429,
      cutoutSize: "326_533",
    },
    Single_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight2A: {
      height: 533,
      top: 90,
      width: 326,
      left: 429,
      cutoutSize: "326_533",
    },
    Single_Door_Right_Left_SideLight_Embossed2AGlassAt2SidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 429,
      cutoutSize: "326_533",
    },
    Single_Door_Right_Left_SideLight_Embossed2ANoGlassSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "118_948",
    },
    Single_Door_Right_Left_SideLight_Embossed2ANoGlassSidelight2A: {
      height: 533,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "118_533",
    },
    Single_Door_Right_Left_SideLight_Embossed2ANoGlassSidelightFull: {
      height: 533,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_Embossed2ANoGlassSidelightFull: {
      height: 533,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "0_0",
    },
    Single_Door_Right_Left_SideLight_Embossed1ANoGlassSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "118_948",
    },
    Double_Door_Right_Left_SideLight_Embossed1ANoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_Embossed2BNoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "0_0",
    },
    Single_Door_Right_Left_SideLight_Embossed2BNoGlassSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "118_948",
    },
    Single_Door_Right_Left_SideLight_Embossed2BNoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "0_0",
    },

    Single_Door_Right_Left_SideLight_Embossed2BNoGlassSidelight2B: {
      height: 711,
      top: 90,
      width: 118,
      left: 429,
      cutoutSize: "118_711",
    },
    Single_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_711",
    },
    Single_Door_Right_Left_SideLight_Embossed2BGlassAt2SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_711",
    },
    Single_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight2B: {
      height: 711,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_711",
    },
    Single_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight1A: {
      height: 533,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_533",
    },
    Single_Door_Right_Left_SideLight_Embossed6AGlassAt3456SidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_533",
    },
    Single_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight2A: {
      height: 533,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_533",
    },
    Double_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight1A: {
      height: 533,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_533",
      DLeft: 688,
      DDRLLeft: 685,
    },
    Double_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight2A: {
      height: 533,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_533",
      DLeft: 688,
      DDRLLeft: 685,
    },
    Double_Door_Right_Left_SideLight_Embossed6AGlassAt3456SidelightFull: {
      height: 533,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_533",
      DLeft: 688,
      DDRLLeft: 685,
    },
    Double_Door_Right_Left_SideLight_Embossed6BGlassAt3456SidelightFull: {
      height: 711,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_711",
      DLeft: 688,
      DDRLLeft: 688,
    },
    Double_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight1A: {
      height: 711,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_711",
      DLeft: 688,
      DDRLLeft: 685,
    },
    Double_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight2B: {
      height: 711,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_711",
      DLeft: 688,
      DDRLLeft: 688,
    },
    Single_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_711",
    },
    Single_Door_Right_Left_SideLight_Embossed6CNoGlassSidelight1A: {
      height: 948,
      top: 90,
      width: 118,
      left: 435,
      cutoutSize: "118_948",
    },
    Single_Door_Right_Left_SideLight_Embossed6CNoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 435,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_Embossed6CNoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 435,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_EmbossedCambreeLambrisNoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 435,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_EmbossedCambreeNoGlassSidelightFull: {
      height: 948,
      top: 90,
      width: 118,
      left: 435,
      cutoutSize: "0_0",
    },
    Double_Door_Right_Left_SideLight_Embossed6CNoGlassSidelight3A: {
      height: 948,
      top: 90,
      width: 118,
      left: 435,
      cutoutSize: "0_0",
    },
    Single_Door_Right_Left_SideLight_Embossed6BGlassAt3456SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_711",
    },
    Single_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight2B: {
      height: 711,
      top: 90,
      width: 326,
      left: 435,
      cutoutSize: "326_711",
    },
    Single_Door_Right_SideLight_Embossed1AGlassAt1Sidelight1A: {
      height: 950,
      top: 90,
      width: 330,
      left: 310,
      cutoutSize: "326_948",
    },
    Single_Door_Right_SideLight_Embossed1AGlassAt1SidelightFull: {
      height: 950,
      top: 90,
      width: 330,
      left: 310,
      cutoutSize: "326_948",
    },

    //Double doors

    Double_Door_EmbossedShakerGlassAt4NoSidelight: {
      height: 211,
      top: 81,
      width: 326,
      left: 181,
      cutoutSize: "326_211",
      DLeft: 693,
    },
    Double_Door_Right_Left_SideLight_EmbossedShakerGlassAt4Sidelight1A: {
      height: 211,
      top: 81,
      width: 326,
      left: 181,
      cutoutSize: "326_211",
      DLeft: 693,
      DDLeft: 693,
      DDRLLeft: 693,
    },
    Double_Door_Right_Left_SideLight_FlatWithSidelight1A: {
      height: 211,
      top: 81,
      width: 326,
      left: 181,
      cutoutSize: "118_948",
      DLeft: 693,
      DDLeft: 693,
      DDRLLeft: 693,
    },
    Double_Door_Right_Left_SideLight_EmbossedTaoSlabSidelight1A: {
      height: 211,
      top: 81,
      width: 326,
      left: 181,
      cutoutSize: "326_948",
      DLeft: 693,
      DDLeft: 693,
      DDRLLeft: 693,
    },
    Double_Door_Right_Left_SideLight_EmbossedShakerGlassAt4SidelightFull: {
      height: 211,
      top: 81,
      width: 326,
      left: 181,
      cutoutSize: "326_211",
      DLeft: 693,
      DDLeft: 693,
      DDRLLeft: 693,
    },
    Double_Door_Right_Left_SideLight_EmbossedShakerNoGlassSidelightFull: {
      height: 211,
      top: 81,
      width: 326,
      left: 181,
      cutoutSize: "0_0",
      DLeft: 693,
      DDLeft: 693,
      DDRLLeft: 693,
    },

    Double_Door_EmbossedPlazaNoSidelight: {
      height: 960,
      top: 86,
      width: 326,
      left: 188,
      cutoutSize: "326_948",
      DLeft: 695,
    },

    Double_Door_EmbossedLineaGlassNoSidelight: {
      height: 948,
      top: 120,
      width: 118,
      left: 407,
      cutoutSize: "118_948",
      DLeft: 672,
    },
    Double_Door_EmbossedOsoGlassNoSidelight: {
      height: 948,
      top: 120,
      width: 118,
      left: 407,
      cutoutSize: "118_948",
      DLeft: 675,
    },
    Single_Door_EmbossedOsoGlassNoSidelight: {
      height: 948,
      top: 120,
      width: 118,
      left: 676,
      cutoutSize: "118_948",
    },

    Double_Door_EmbossedFantasiaNoSidelight: {
      height: 948,
      top: 90,
      width: 330,
      left: 188,
      cutoutSize: "326_948",
      DLeft: 695,
    },

    Double_Door_EmbossedDevilleNoSidelight: {
      height: 820,
      top: 53,
      width: 350,
      left: 188,
      cutoutSize: "326_800",
      DLeft: 695,
    },

    Double_Door_EmbossedCorriente4NoSidelight: {
      height: 170,
      top: 86,
      width: 350,
      left: 188,
      cutoutSize: "326_178",
      top1: 355,
      top2: 600,
      top3: 900,
      DLeft: 695,
    },
    Double_Door_Right_Left_SideLight_EmbossedCorriente4Sidelight1A: {
      height: 178,
      top: 86,
      width: 326,
      left: 185,
      cutoutSize: "326_178",
      DLeft: 695,
      DDRLLeft: 690,
      top1: 355,
      top2: 600,
      top3: 900,
    },
    Double_Door_EmbossedCorriente5NoSidelight: {
      height: 133,
      top: 92,
      width: 326,
      left: 182,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
      DLeft: 697,
    },
    Double_Door_Embossed1AGlassAt1NoSidelight: {
      height: 930,
      top: 94,
      width: 300,
      left: 188,
      cutoutSize: "326_948",
      DLeft: 695,
    },
    Double_Door_Embossed1AGlassAt1NoSidelightAI: {
      height: 930,
      // top: 94,
      width: 300,
      // left: 188,
      cutoutSize: "326_948",
      // DLeft: 695,
    },
    Double_Door_Embossed2AGlassAt2NoSidelight: {
      height: 533,
      top: 94,
      width: 300,
      left: 188,
      cutoutSize: "326_533",
      DLeft: 695,
    },
    Double_Door_Embossed2BGlassAt2NoSidelight: {
      height: 711,
      top: 94,
      width: 300,
      left: 188,
      cutoutSize: "326_711",
      DLeft: 695,
    },
    Double_Door_Embossed3AGlassAt123NoSidelight: {
      height: 252,
      top: 94,
      top1: 442,
      top2: 790,
      width: 300,
      left: 188,
      cutoutSize: "326_252",
      DLeft: 695,
    },
    Double_Door_Embossed3AGlassAt123NoSidelightNew: {
      height: 252,
      top: 94,
      top1: 442,
      top2: 790,
      width: 300,
      left: 188,
      cutoutSize: "326_252",
      DLeft: 695,
    },
    Double_Door_Embossed3AGlassAt3NoSidelight: {
      height: 252,
      top: 94,
      width: 300,
      left: 186,
      cutoutSize: "326_252",
      DLeft: 695,
    },
    Double_Door_Embossed3BGlassAt3NoSidelight2: {
      height: 211,
      top: 81,
      width: 300,
      left: 183,
      cutoutSize: "326_211",
      DLeft: 695,
    },
    Double_Door_Embossed3BGlassAt3NoSidelight: {
      height: 211,
      top: 81,
      width: 300,
      left: 183,
      cutoutSize: "326_211",
      DLeft: 695,
    },
    Double_Door_Embossed5AGlassAt5NoSidelight: {
      height: 118,
      top: 81,
      width: 300,
      left: 183,
      cutoutSize: "326_118",
      DLeft: 695,
    },
    Double_Door_Embossed6AGlassAt3456NoSidelight: {
      height: 533,
      top: 90,
      width: 300,
      left: 183,
      cutoutSize: "326_533",
      DLeft: 695,
    },
    Double_Door_Embossed6BGlassAt3456NoSidelight: {
      height: 711,
      top: 91,
      width: 300,
      left: 183,
      cutoutSize: "326_711",
      DLeft: 695,
    },
    Double_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A: {
      height: 948,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_948",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight1A: {
      height: 533,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_533",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed3AGlassAt123Sidelight1A: {
      height: 252,
      top: 94,
      top1: 442,
      top2: 790,
      width: 326,
      left: 182,
      cutoutSize: "326_252",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_EmbossedCorriente5Sidelight1A: {
      height: 133,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_EmbossedCorriente5SidelightFull: {
      height: 133,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_133",
      top1: 295,
      top2: 498,
      top3: 699,
      top4: 905,
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_EmbossedCorriente4Sidelight1A: {
      height: 133,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_178",
      top1: 355,
      top2: 624,
      top3: 900,
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_EmbossedCorriente4SidelightFull: {
      height: 133,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_178",
      top1: 355,
      top2: 624,
      top3: 900,
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed3AGlassAt3Sidelight1A: {
      height: 252,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_252",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed3ANoGlassSidelight1A: {
      height: 252,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_252",
      DLeft: 688,
      DDRLLeft: 685,
    },
    Double_Door_Right_Left_SideLight_Embossed3AGlassAt3SidelightFull: {
      height: 252,
      top: 94,
      width: 326,
      left: 182,
      cutoutSize: "326_252",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed3AGlassAt123SidelightFull: {
      height: 252,
      top: 94,
      top1: 442,
      top2: 790,
      width: 326,
      left: 182,
      cutoutSize: "326_252",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight1A: {
      height: 711,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_711",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed3BGlassAt3Sidelight1A: {
      height: 211,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_211",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight2B: {
      height: 711,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_711",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed2BGlassAt2SidelightFull: {
      height: 711,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_711",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight2A: {
      height: 533,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_533",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed2AGlassAt2SidelightFull: {
      height: 533,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_533",
      DLeft: 695,
      DDRLLeft: 690,
    },
    Double_Door_Right_Left_SideLight_Embossed1AGlassAt1SidelightFull: {
      height: 948,
      top: 90,
      width: 326,
      left: 185,
      cutoutSize: "326_948",
      DLeft: 695,
      DDRLLeft: 690,

    },
  };

  let adjustedProperties;


   let defaultProperties = cutOuts[baseName] ?? 
   {
    height: 948,
    top: 96,
    width: 310,
    left: 441,
    cutoutSize: "326_948",
  };

  function determineDoorDimensions(baseName) {
    let dimensions = {
      width: null,
      height: null,
    };

    if (baseName.includes("Double_Door")) {
      dimensions.height = 1226;
      dimensions.width = 1043;
    } else if (baseName.includes("Single_Door_Right_Left_SideLight")) {
      dimensions.height = 1290;
      dimensions.width = 1140;
    } else if (baseName.includes("Single_Door_Left_SideLight")) {
      dimensions.height = 1286;
      dimensions.width = 852;
    } else if (baseName.includes("Single_Door") && !baseName.includes("SideLight")) {
      dimensions.height = 1268;
      dimensions.width = 573;
    } else {
      // Default dimensions
      dimensions.height = 1268;
      dimensions.width = 573; // Assuming single door width and height as default
    }

    return dimensions;
  }



  if (isAI && aiDetectedDimensions) {

    const { aiWidth, aiHeight } = aiDetectedDimensions;
    const doorDimensions = determineDoorDimensions(baseName);
    const doorScaleX = aiWidth / doorDimensions.width;
    const doorScaleY = aiHeight / doorDimensions.height;

     adjustedProperties = {
        height: defaultProperties.height * doorScaleY,
        top: (defaultProperties.top - (600 - doorDimensions.height / 2)) * doorScaleY,
        width: defaultProperties.width * doorScaleX,
        left: (defaultProperties.left - (600 - doorDimensions.width / 2)) * doorScaleX,
        DLeft: (defaultProperties.DLeft - (600 - doorDimensions.width / 2)) * doorScaleX
    };

    
    return adjustedProperties;
}
  
if(isAI)

defaultProperties = adjustedProperties

  // Return default properties if AI-detected dimensions are not provided
  return defaultProperties;


}

export default sizeAndPosition;
