import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CombinedTermsOfUse = () => {
  const { i18n } = useTranslation();

  if (i18n.language === 'en') {
  return (      
<div>
  <h1>Last updated on November 5, 2023</h1>

  <h2>Introduction</h2>
  <p>
    The following terms and conditions apply to this website and transactions related to our products and services. You may also be bound by additional contracts concerning your relationship with us or any products or services you receive from us. Should there be any conflict between the terms of those additional contracts and these terms and conditions, the additional contracts will prevail.
  </p>

  <h2>Binding Agreement</h2>
  <p>
    By registering on this site, accessing it, or using it in any other way, you hereby agree to be bound by the terms and conditions set forth below. Mere use of this website implies knowledge and acceptance of these terms and conditions. In certain special cases, we may also ask you to provide your explicit consent.
  </p>

  <h2>Electronic Communication</h2>
  <p>
    By using this website or communicating with us electronically, you agree and acknowledge that we may communicate with you electronically on our website or by sending you an email. You agree that all agreements, notices, disclosures, and other communications we provide to you electronically satisfy any legal requirement, including but not limited to the requirement that such communications be in writing.
  </p>

  <h2>Intellectual Property</h2>
  <p>
    We or our licensees own and control all the copyright and other intellectual property rights on the website and the data, information, and other resources displayed or accessible on the website.
  </p>

  <h3>4.1 All Rights Reserved</h3>
  <p>
    Unless otherwise specified in specific content, no license or any other right is granted to you under copyrights, trademarks, patents, or other intellectual property rights. This means you may not use, copy, reproduce, execute, display, distribute, incorporate into any electronic medium, modify, reverse-engineer, decompile, transfer, upload, transmit, monetize, sell, market, or commercialize any of the resources on this website in any form, without our prior written permission, except and only to the extent otherwise stipulated in mandatory laws (such as the right to quote).
  </p>

  <h2>Newsletters</h2>
  <p>
    Notwithstanding the above, you may electronically share our newsletter with others who may be interested in visiting our website.
  </p>

  <h2>Third-Party Ownership</h2>
  <p>
    Our website may include hyperlinks or other references to third-party websites. We do not monitor or review the content of third-party websites linked to this website. Products or services offered by other websites are subject to the terms and conditions of those third parties. Opinions expressed or materials appearing on such websites are not necessarily shared or endorsed by us.
  </p>

  <p>
    We are not responsible for the privacy practices or the content of these websites. You assume all risks associated with the use of these websites and any third-party services. We accept no responsibility for any loss or damage, for any reason, resulting from the disclosure of your personal information to third parties.
  </p>

  <h2>Responsible Use</h2>
  <p>
    By visiting our website, you agree to use it only for purposes that are permitted by these terms and conditions, any additional contracts with us, and by applicable laws and generally accepted online practices and industry guidelines. You must not use our website or services to use, publish, or distribute any material which consists of (or is linked to) malicious software; use data collected from our website for any direct marketing activity, or conduct any systematic or automated data collection activities on or in relation to our website.
  </p>

  <p>
    It is strictly prohibited to engage in any activity that causes, or could cause, damage to the website or interferes with the performance, availability, or accessibility of the website.
  </p>

  <div>
  <h2>8. Registration</h2>
  <p>
    You can open an account on our website. During this process, you may be asked to choose a password. You are responsible for maintaining the confidentiality of passwords and account information and agree not to share your passwords, account information, or secure access to our website or services with anyone else. You should not allow any other person to use your account to access the website as you are responsible for all activities that occur through the use of your passwords or accounts. You must inform us immediately if you become aware of the disclosure of your password.
  </p>
  <p>
    Upon account closure, you will not attempt to open a new account without our permission.
  </p>

  <h2>9. Subscription and Account Integrity</h2>
  <p>Each subscription to Facade Web App is for single-user access and is personal to the subscriber. Sharing login credentials with others is strictly prohibited. By agreeing to these terms, you acknowledge that the account and password must only be used by the individual to whom they are assigned. Any unauthorized sharing of subscription details, including but not limited to, user accounts, passwords, or associated license keys, may result in suspension or termination of service without notice. It is the account holder's responsibility to safeguard their account information and to ensure that any employee access to the service is through a legally obtained, individual user account as part of the business's subscription plan. We reserve the right to monitor usage patterns to identify and prevent any form of account misuse or fraudulent activity. Compliance with these terms ensures a secure and fair use environment for all users.
    
  </p>

  <h2>10. Idea Submission</h2>
  <p>
    Do not send any ideas, inventions, copyrighted works, or other information that could be considered your own intellectual property that you wish to present to us unless we have previously signed an agreement regarding intellectual property or a non-disclosure agreement. If you disclose it to us in the absence of such a written agreement, you grant us a global, irrevocable, non-exclusive, and royalty-free license to use, reproduce, store, adapt, publish, translate, and distribute your content on any existing or future media.
  </p>

  <h2>11. Termination of Use</h2>
  <p>
    We may, at our sole discretion, at any time modify or discontinue access, temporarily or permanently, to the website or any service therein. You agree that we are not responsible to you or any third party for any modification, suspension, or discontinuation of your access or use of the website or any content you may have shared on the website. You are not entitled to any compensation or other payment, even if certain features, settings, and/or any content to which you have contributed or relied upon are permanently lost. You should not bypass or evade, or attempt to bypass or evade, access restrictions to our website.
  </p>
</div>
<div>
  <h2>12. Warranties and Liability</h2>
  <p>
    Nothing in this section will limit or exclude any implied warranty by law that it would be illegal to limit or exclude. This website and all its content are provided "as is" and "as available" and may contain inaccuracies or typographical errors. We expressly disclaim any warranty of any kind, whether express or implied, as to the availability, accuracy, or completeness of the content. We do not guarantee the following:
  </p>
  <ul>
    <li>That this website or our products or services will meet your needs;</li>
    <li>That this website will be available uninterrupted, timely, secure, or error-free;</li>
    <li>That the quality of any product or service purchased or obtained by you through this website will meet your expectations.</li>
  </ul>
  <p>
    Nothing on this website constitutes or is intended to constitute legal, financial, or medical advice of any kind. If you require advice, you should consult an appropriate professional.
  </p>
  <p>
    The following provisions of this section will apply to the maximum extent permitted by applicable law and will neither limit nor exclude our liability concerning any matter for which it would be illegal or unlawful for us to limit or exclude our liability. Under no circumstances will we be responsible for any direct or indirect damages (including damages for loss of profits or revenue, the loss or corruption of data, software or databases, or the loss or damage to property or data) suffered by you or any third party, resulting from your access to our website or its use.
  </p>
  <p>
    Except where any additional contract expressly stipulates otherwise, our maximum liability to you for any damages arising from or related to the website or any product and service marketed or sold through the website, regardless of the form of the legal action that imposes liability (be it contractual, equity, negligence, intentional misconduct, tort, or otherwise) will be limited to the total price you have paid us to purchase these products or services or use the website. This limit will apply collectively to all your claims, actions, and causes of action of every kind and nature.
  </p>

  <h2>13. Privacy</h2>
  <p>
    To access our website and/or our services, you may be asked to provide certain information about yourself as part of the registration process. You agree that all information you provide will always be accurate, correct, and up to date.
  </p>
  <p>
    We take your personal data seriously and are committed to protecting your privacy. We will not use your email address for unsolicited mail. The emails we send you are only related to the provision of agreed-upon products or services.
  </p>
  <p>
    We have developed a policy to address all your privacy concerns. For more information, please consult our privacy statement and cookie policy.
  </p>

  <h2>14. Export Restrictions / Legal Compliance</h2>
  <p>
    Access to the website from territories or countries where the content or purchase of products or services sold on the website is illegal is prohibited. You may not use this website in violation of Canada's export laws and regulations.
  </p>
</div>
<div>
  <h2>15. Assignment</h2>
  <p>
    You may not assign, transfer, or subcontract any of your rights and/or obligations under these terms and conditions, in whole or in part, to a third party without our prior written consent. Any presumed assignment in violation of this section shall be null and void.
  </p>

  <h2>16. Violation of These Terms and Conditions</h2>
  <p>
    Without prejudice to our other rights under these terms and conditions, if you violate these terms and conditions in any way, we may take the measures we deem appropriate to address the violation, including temporarily or permanently suspending your access to the website, contacting your internet service provider to request that they block your access to the website, and/or taking legal action against you.
  </p>

  <h2>17. Indemnification</h2>
  <p>
    You agree to indemnify, defend, and hold us harmless from all claims, liabilities, damages, losses, and expenses related to your violation of these terms and conditions and applicable laws, including intellectual property rights and privacy rights. You will promptly reimburse us for damages, losses, costs, and expenses related to or arising from these claims.
  </p>

  <h2>18. Waiver</h2>
  <p>
    The failure to enforce any of the provisions stated in these terms and conditions and any agreement, or to exercise any option to terminate, shall not be construed as a waiver of such provisions and shall not affect the validity of these terms and conditions or any agreement or any part thereof, nor the right thereafter to enforce each and every provision.
  </p>

  <h2>19. Language</h2>
  <p>
    These terms and conditions will be translated exclusively into French. All notifications and correspondence will be written exclusively in this language.
  </p>

  <h2>20. Entire Agreement</h2>
  <p>
    These terms and conditions, along with our privacy statement and cookie policy, constitute the entire agreement between you and Applications Renotrend Inc. concerning your use of this website.
  </p>

  <h2>21. Updating These Terms and Conditions</h2>
  <p>
    We may from time to time update these terms and conditions. The date indicated at the beginning of these terms and conditions is the most recent revision date. We will inform you in writing of any changes or updates, and the revised terms and conditions will come into effect on the date we inform you. Your continued use of this website after the posting of changes or updates will be considered notification of your acceptance to abide by and be bound by these terms and conditions. To request a previous version of these terms and conditions, please contact us.
  </p>

  <h2>22. Choice of Law and Jurisdiction</h2>
  <p>
    These terms and conditions are governed by the laws of Canada. Any dispute relating to these terms and conditions will be subject to the jurisdiction of the courts of Canada. If a part or provision of these terms and conditions is deemed by a court or other authority to be invalid and/or unenforceable under applicable law, that part or provision will be amended, deleted, and/or applied to the fullest extent possible to give effect to the intent of these terms and conditions. The other provisions will not be affected.
  </p>

  <h2>23. Contact Information</h2>
  <p>
    This website is owned and operated by Applications Renotrend Inc.
  </p>
  <p>
    You can contact us about these terms and conditions through this email <a href="mailto:facadewebapp@renotrend.com">Information</a>.
  </p>

  <h2>24. Download</h2>
  <p>
    You may also download our terms and conditions in PDF format.
  </p>
</div>

</div>
);
} else if (i18n.language === 'fr') {
  return (
    <div>
      <h2>1. Introduction</h2>
      <p>
        Les présentes conditions générales ont été mises à jour pour la dernière fois le 05 novembre 2023.
      </p>
    
      <h2>2. Obligatoire</h2>
      <p>
        En vous inscrivant sur ce site, en y accédant ou en l’utilisant de toute autre manière, vous acceptez par les présentes d’être lié par les conditions générales énoncées ci-dessous. La simple utilisation de ce site web implique la connaissance et l’acceptation des présentes conditions générales. Dans certains cas particuliers, nous pouvons également vous demander de donner votre accord explicite.
      </p>
    
      <h2>3. Communication électronique</h2>
      <p>
        En utilisant ce site web ou en communiquant avec nous par des moyens électroniques, vous acceptez et reconnaissez que nous pouvons communiquer avec vous par voie électronique sur notre site web ou en vous envoyant un e-mail, et vous acceptez que tous les accords, notifications, publications et autres communications que nous vous fournissons par voie électronique répondent à toute exigence légale, y compris mais sans s’y limiter, l’exigence que ces communications soient faites par écrit.
      </p>
    
      <h2>4. Propriété intellectuelle</h2>
      <p>
        Nous ou nos licenciés possédons et contrôlons tous les droits d’auteur et autres droits de propriété intellectuelle sur le site web et les données, informations et autres ressources affichées ou accessibles sur le site web.
      </p>
    
      <h3>4.1 Tous droits réservés</h3>
      <p>
        À moins qu’un contenu spécifique n’en décide autrement, aucune licence ni aucun autre droit ne vous est accordé en vertu des droits d’auteur, des marques, des brevets ou d’autres droits de propriété intellectuelle. Cela signifie que vous n’utiliserez, copierez, reproduirez, exécuterez, afficherez, distribuerez, intégrerez dans un support électronique, modifierez, rétro-ingénierez, décompilerez, transférerez, téléchargerez, transmettrez, monétiserez, vendrez, marchandiserez ou commercialiserez aucune des ressources de ce site web sous quelque forme que ce soit, sans notre autorisation écrite préalable, sauf et uniquement dans la mesure où il en est stipulé autrement dans des règlements de droit impératif (tels que le droit de citer).
      </p>
    
      <h2>5. Lettre d’informations</h2>
      <p>
        Nonobstant ce qui précède, vous pouvez transmettre notre lettre d’informations sous forme électronique à d’autres personnes qui pourraient être intéressées par la visite de notre site web.
      </p>
    
      <h2>6. Propriété de tierce partie</h2>
      <p>
        Notre site web peut inclure des hyperliens ou d’autres références aux sites web de tierces parties. Nous ne surveillons ni n’examinons le contenu des sites web de tierces parties qui sont liés à ce site web. Les produits ou services offerts par d’autres sites web sont soumis aux conditions générales applicables de ces tiers. Les opinions exprimées ou les éléments apparaissant sur ces sites ne sont pas nécessairement partagés ou approuvés par nous.
      </p>
    
      <p>
        Nous ne sommes pas responsables des pratiques de confidentialité ou du contenu de ces sites. Vous assumez tous les risques liés à l’utilisation de ces sites web et de tout services de tierce parties. Nous n’accepterons aucune responsabilité pour toute perte ou dommage, quelle qu’en soit la cause, résultant de la divulgation de vos informations personnelles à des tiers.
      </p>
    
      <h2>7. Utilisation responsable</h2>
      <p>
        En visitant notre site web, vous acceptez de l’utiliser uniquement aux fins prévues et autorisées par les présentes conditions générales, par tout contrat supplémentaire conclu avec nous, et par les lois et règlements applicables, ainsi que par les pratiques en ligne et les directives industrielles généralement acceptées. Vous ne devez pas utiliser notre site web ou nos services pour utiliser, publier ou distribuer tout élément qui consiste en (ou est lié à) un logiciel malveillant ; utiliser les données collectées sur notre site web pour toute activité de marketing direct, ou mener toute activité de collecte de données systématique ou automatisée sur ou en relation avec notre site web.
      </p>
    
      <p>
        Il est strictement interdit de s’engager dans toute activité qui cause, ou pourrait causer, des dommages au site web ou qui interfère avec la performance, la disponibilité ou l’accessibilité du site web.
      </p>
      <div>
      <h2>8. Inscription</h2>
      <p>
        Vous pouvez ouvrir un compte sur notre site web. Au cours de ce processus, il peut vous être demandé de choisir un mot de passe. Vous êtes responsable du maintien de la confidentialité des mots de passe et des informations de compte et vous acceptez de ne pas partager vos mots de passe, les informations de compte ou l’accès sécurisé à notre site web ou à nos services avec toute autre personne. Vous ne devez autoriser aucune autre personne à utiliser votre compte pour accéder au site web car vous êtes responsable de toutes les activités qui se produisent par l’utilisation de vos mots de passe ou comptes. Vous devez nous informer immédiatement si vous avez connaissance de la divulgation de votre mot de passe.
      </p>
    
      <p>
        Après la cloture du compte, vous ne tenterez pas d’ouvrir un nouveau compte sans notre autorisation.
      </p>
      <h2>9. Abonnement et Intégrité du Compte</h2>
      <p>Chaque abonnement à Facade Web App permet un accès individuel et est personnel à l'abonné. Il est strictement interdit de partager les identifiants de connexion avec d'autres personnes. En acceptant ces conditions, vous reconnaissez que le compte et le mot de passe doivent être utilisés uniquement par la personne à qui ils sont attribués. Tout partage non autorisé des détails de l'abonnement, y compris, mais sans s'y limiter, les comptes utilisateurs, les mots de passe ou les clés de licence associées, peut entraîner la suspension ou la résiliation du service sans préavis. Il est de la responsabilité du titulaire du compte de protéger les informations de son compte et de s'assurer que tout accès des employés au service se fait par le biais d'un compte utilisateur individuel légalement obtenu dans le cadre du plan d'abonnement de l'entreprise. Nous nous réservons le droit de surveiller les modèles d'utilisation pour identifier et prévenir toute forme de mauvaise utilisation du compte ou d'activité frauduleuse. Le respect de ces conditions assure un environnement d'utilisation sécurisé et équitable pour tous les utilisateurs.

      </p>
      <h2>10. Soumission d’idée</h2>
      <p>
        N’envoyez aucune idée, invention, œuvre d’auteur ou autre information pouvant être considérée comme votre propre propriété intellectuelle que vous souhaiteriez nous présenter, sauf si nous avons préalablement signé un accord concernant la propriété intellectuelle ou un accord de non-divulgation. Si vous nous le divulguez en l’absence d’un tel accord écrit, vous nous accordez une licence mondiale, irrévocable, non exclusive et libre de redevance pour utiliser, reproduire, stocker, adapter, publier, traduire et distribuer votre contenu sur tout support existant ou futur.
      </p>
    
      <h2>11. Fin de l’utilisation</h2>
      <p>
        Nous pouvons, à notre seule discrétion, à tout moment modifier ou interrompre l’accès, temporairement ou de façon permanente, au site web ou à tout service qui s’y trouve. Vous acceptez que nous ne soyons pas responsables envers vous ou une tierce partie de toute modification, suspension ou interruption de votre accès ou utilisation du site web ou de tout contenu que vous avez pu partager sur le site web. Vous n’aurez droit à aucune compensation ou autre paiement, même si certaines fonctionnalités, certains réglages et/ou tout contenu auquel vous avez contribué ou sur lequel vous vous êtes appuyé sont définitivement perdus. Vous ne devez pas contourner ou détourner, ou tenter de contourner ou de détourner, les mesures de restriction d’accès à notre site web.
      </p>
    
      <h2>12. Garanties et responsabilité</h2>
      <p>
        Rien dans cette section ne limitera ou n’exclura une garantie implicite de la loi qu’il serait illégal de limiter ou d’exclure. Ce site web et tout son contenu sont fournis « en l’état » et « selon disponibilité » et peuvent contenir des inexactitudes ou des erreurs typographiques. Nous déclinons expressément toute garantie de quelque nature que ce soit, expresse ou implicite, quant à la disponibilité, l’exactitude ou l’exhaustivité du contenu. Nous ne garantissons pas ceci :
      </p>
    
      <ul>
        <li>que ce site web ou nos produits ou services répondront à vos besoins ;</li>
        <li>ce site web sera disponible de manière ininterrompue, opportune, sûre ou sans erreur ;</li>
        <li>la qualité de tout produit ou service acheté ou obtenu par vous par l’intermédiaire de ce site web répondra à vos attentes.</li>
      </ul>
    
      <p>
        Rien sur ce site web ne constitue ou n’est censé constituer un conseil juridique, financier ou médical de quelque nature que ce soit. Si vous avez besoin de conseils, vous devriez consulter un professionnel approprié.
      </p>
    
      <p>
        Les dispositions suivantes de la présente section s’appliqueront dans la mesure maximale permise par le droit applicable et ne limiteront ni n’excluront notre responsabilité en ce qui concerne toute question pour laquelle il serait illégal ou illicite pour nous de limiter ou d’exclure notre responsabilité. En aucun cas nous ne serons responsables des dommages directs ou indirects (y compris les dommages pour perte de profits ou de revenus, la perte ou la corruption de données, de logiciels ou de bases de données, ou la perte ou l’endommagement de biens ou de données) subis par vous ou par une tierce partie, résultant de votre accès à notre site web ou de son utilisation.
      </p>
    
      <p>
        Sauf dans la mesure où tout contrat supplémentaire stipule expressément le contraire, notre responsabilité maximale envers vous pour tout dommage découlant de ou lié au site web ou à tout produit et service commercialisé ou vendu par le biais du site web, quelle que soit la forme de l’action en justice qui impose la responsabilité (qu’elle soit contractuelle, d’équité, de négligence, de conduite intentionnelle, délictuelle ou autre) sera limitée au prix total que vous nous avez payé pour acheter ces produits ou services ou utiliser le site web. Cette limite s’appliquera globalement à toutes vos réclamations, actions et causes d’action de toute nature et de tout type.
      </p>    
   </div>
    <div>
      <h2>13. Confidentialité</h2>
      <p>
        Pour accéder à notre site web et/ou à nos services, il peut vous être demandé de fournir certaines informations vous concernant dans le cadre de la procédure d’inscription. Vous acceptez que toutes les informations que vous fournissez soient toujours exactes, correctes et à jour.
      </p>
    
      <p>
        Nous prenons vos données personnelles au sérieux et nous nous engageons à protéger votre vie privée. Nous n’utiliserons pas votre adresse e-mail pour du courrier non sollicité. Les e-mails que nous vous envoyons sont uniquement liés à la fourniture de produits ou de services convenus.
      </p>
    
      <p>
        Nous avons élaboré une politique pour répondre à toutes vos préoccupations en matière de protection de la vie privée. Pour plus d’informations, veuillez consulter notre <NavLink to="/terms">
        déclaration de confidentialité
        </NavLink> ainsi que notre <NavLink to="/cookies">
        politique de cookies
        </NavLink>.
      </p>
    
      <h2>14. Restrictions à l’exportation / Conformité juridique</h2>
      <p>
        L’accès au site web à partir de territoires ou de pays où le contenu ou l’achat des produits ou services vendus sur le site web est illégal est interdit. Vous ne pouvez pas utiliser ce site web en violation des lois et règlements d’exportation de Canada.
      </p>
    
      <h2>15. Affectation</h2>
      <p>
        Vous ne pouvez céder, transférer ou sous-traiter aucun de vos droits et/ou obligations en vertu des présentes conditions générales, en tout ou en partie, à une tierce partie sans notre consentement écrit préalable. Toute cession présumée en violation de la présente section sera nulle et non avenue.
      </p>
    
      <h2>16. Violation des présentes conditions générales</h2>
      <p>
        Sans préjudice de nos autres droits en vertu des présentes conditions générales, si vous violez les présentes conditions générales de quelque manière que ce soit, nous pouvons prendre les mesures que nous jugeons appropriées pour traiter la violation, y compris la suspension temporaire ou permanente de votre accès au site web, la prise de contact avec votre fournisseur d’accès Internet pour lui demander de bloquer votre accès au site web, et/ou engager une action en justice contre vous.
      </p>
    
      <h2>17. Indemnisation</h2>
      <p>
        Vous acceptez de nous indemniser, de nous défendre et de nous tenir à l’écart de toutes les réclamations, responsabilités, dommages, pertes et dépenses liés à votre violation des présentes conditions générales et des lois applicables, y compris les droits de propriété intellectuelle et les droits à la vie privée. Vous nous rembourserez rapidement les dommages, pertes, coûts et dépenses liés à ces réclamations ou en découlant.
      </p>
    
      <h2>18. Dérogation</h2>
      <p>
        Le fait de ne pas appliquer l’une des dispositions énoncées dans les présentes conditions générales et dans tout accord, ou de ne pas exercer une option de résiliation, ne doit pas être interprété comme une renonciation à ces dispositions et n’affecte pas la validité des présentes conditions générales ou de tout accord ou de toute partie de celui-ci, ni le droit par la suite d’appliquer chacune des dispositions.
      </p>
    
      <h2>19. Langue</h2>
      <p>
        Les présentes conditions générales seront traduites exclusivement en Français. Toutes les notifications et la correspondance seront rédigés exclusivement dans cette langue.
      </p>
    
      <h2>20. Accord intégral</h2>
      <p>
        Ces conditions générales, ainsi que notre <NavLink to="/cookies">
        politique de cookies
        </NavLink>, constituent l’intégralité de l’accord entre vous et Applications Renotrend Inc. concernant votre utilisation de ce site web.
      </p>
    </div>
    <div>
      <h2>21. Mise à jour des présentes conditions générales</h2>
      <p>
        Nous pouvons de temps en temps mettre à jour ces conditions générales. La date indiquée au début des présentes conditions générales est la date de révision la plus récente. Nous vous informerons par écrit de toute modification ou mise à jour, et les conditions générales révisées entreront en vigueur à la date à laquelle nous vous en informerons. Votre utilisation continue de ce site web après la publication de modifications ou de mises à jour sera considérée comme une notification de votre acceptation de respecter et d’être lié par ces conditions générales. Pour demander une version antérieure de ces conditions générales, veuillez nous contacter.
      </p>
    
      <h2>22. Choix de la loi et de la juridiction</h2>
      <p>
        Les présentes conditions générales sont régies par les lois de Canada. Tout litige relatif aux présentes conditions générales sera soumis à la juridiction des tribunaux de Canada. Si une partie ou une disposition des présentes conditions générales est jugée par un tribunal ou une autre autorité comme étant invalide et/ou inapplicable en vertu du droit applicable, cette partie ou disposition sera modifiée, supprimée et/ou appliquée dans la plus large mesure possible afin de donner effet à l’intention des présentes conditions générales. Les autres dispositions ne seront pas affectées.
      </p>
    
      <h2>23. Information de contact</h2>
      <p>
        Ce site web est détenu et exploité par Applications Renotrend Inc.
      </p>
    
      <p>
        Vous pouvez nous contacter au sujet de ces conditions générales par le biais de notre courriel <a href="mailto:facadewebapp@renotrend.com">Information</a>.
      </p>
    
      <h2>24. Téléchargement</h2>
      <p>
        Vous pouvez également télécharger nos conditions générales au format PDF.
      </p>
    </div>
    
    </div>
    );
  } else {
    return null; // Or a default case
  }
};

export default CombinedTermsOfUse;