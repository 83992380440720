import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useAuth } from "../base";
import { useTranslation } from "react-i18next";
import AdminModal from "../components/admin/AdminModal"
import AdminNewsModal from "../components/admin/AdminNewsModal"
import { Link } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import AccountModal from '../components/userAccount/AccountModal';

function SiteNav(props) {
  const { t } = useTranslation(["translation"]);
  const currentUser = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDesignerClick = () => {
    if (currentUser) {
      props.setDesignerModalOpen(true);
    } else {
      props.showConnectionRequiredToast(t);
    }
  };

  return (
    <>
  
      <Navbar
        className="siteBackgroundColor siteNavBar"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <div className="header-container">
           
            <div className="brand-and-slogan-container">
              <Navbar.Brand className="siteName">
                <Link to="/">
                  Façade Web App (Beta-2)
                </Link>
              </Navbar.Brand>
              <div className="slogan">{t("slogan")}</div>
  
              {props.userRole === 'mainAdmin' && <AdminModal />}
              {props.userRole === 'mainAdmin' && <AdminNewsModal />}
            </div>
  
            <div className="renotrendLink">
              {props.projectName && isMobile && (
                <Nav.Link onClick={handleDesignerClick}>
                  <img
                    src="/avatarDesignerFacialIcon.png"
                    alt="Facade Designer Icon"
                    className="designer-icon"
                    style={{ width: '40px', height: "auto", marginRight: '8px', verticalAlign: 'middle' }}
                  />
                  <span className="designer-text">{t("assistant.VirtualDesigner")}</span>
                </Nav.Link>
              )}
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div>
  
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {currentUser && (
                <>
                  <Nav.Link
                    eventKey="upload"
                    onClick={() => {
                      currentUser ? props.closeUpload(true) : props.closeSignup(true);
                    }}
                  >
                    {t("start-project")}
                  </Nav.Link>
                  <Nav.Link
                    eventKey="4"
                    onClick={() => {
                      props.closeAccount(true);
                      props.setIsProjectList(true);
                    }}
                  >
                    {t("view-projects")}
                  </Nav.Link>
                </>
              )}
            </Nav>
            <Nav className="navEnd">
              {!currentUser ? (
                <>
                  <Nav.Link
                    title="Start here"
                    id="user-dropdown"
                    className="userDropdown"
                    eventKey={4}
                    onClick={() => {
                      props.setLogin(false);
                      props.setIsLoginOut(false);
                      props.closeSignup(true);
                    }}
                  >
                    {t("signup")}
                  </Nav.Link>
                  <Nav.Link
                    title="Start here"
                    id="user-dropdown"
                    className="userDropdown"
                    eventKey={5}
                    onClick={() => {
                      props.setLogin(true);
                      props.setIsLoginOut(false);
                      props.closeSignup(true);
                    }}
                  >
                    {t("login")}
                  </Nav.Link>
                </>
              ) : (
                <div className="profile-icon-container">
                  <Button variant="outline-secondary" onClick={() => props.setIsAccountModalOpen(true)}>
                    <AccountCircle style={{ color: '#fff', fontSize: '1.5rem' }} />
                  </Button>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
  
        {/* Modal Component */}
        <AccountModal
          isOpen={props.isAccountModalOpen}
          onRequestClose={() => props.setIsAccountModalOpen(false)}
          handleLogOut={props.handleLogOut}
          currentUser={currentUser}
        />
      </Navbar>
    </>
  );
}  

export default SiteNav;
