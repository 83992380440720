import { v1 as uuid } from "uuid";

export const windowsArray = [
  {
    url: "/windows/casement/Window_Casement_Single.png",
    key: uuid(),
    name: "Casement_Single",
  },
  {
    url: "/windows/casement/Window_Casement_Double.png",
    key: uuid(),
    name: "Casement_Double",
  },
  {
    url: "/windows/casement/Window_Casement_Triple.png",
    key: uuid(),
    name: "Casement_Triple",
  },
  {
    url: "/windows/casement/Window_Casement_Quad.png",
    key: uuid(),
    name: "Casement_Quad",
  },
  {
    url: "/windows/casement/Window_Casement_Quint.png",
    key: uuid(),
    name: "Casement_Quint",
  },
  {
    url: "/windows/casement/Window_Casement_QuarterHalfQuarter.png",
    key: uuid(),
    name: "Casement_Quarter_Half_Quarter",
  },
  {
    url: "/windows/casement/Window_Casement_ThirdLeft.png",
    key: uuid(),
    name: "Casement_Third_Left",
  },
  {
    url: "/windows/casement/Window_Casement_ThirdRight.png",
    key: uuid(),
    name: "Casement_Third_Right",
  },
  {
    url: "/windows/sash/Window_Sash_Single.png",
    key: uuid(),
    name: "Sash_Single",
  },
  {
    url: "/windows/sash/Window_Sash_Double.png",
    key: uuid(),
    name: "Sash_Double",
  },
  {
    url: "/windows/sash/Window_Sash_Triple.png",
    key: uuid(),
    name: "Sash_Triple",
  },
  {
    url: "/windows/sash/Window_Sash_QuarterHalfQuarter.png",
    key: uuid(),
    name: "Sash_Quarter_Half_Quarter",
  },
  {
    url: "/windows/sash/Window_Sash_Quad.png",
    key: uuid(),
    name: "Sash_Quadruple",
  },
  {
    url: "/windows/sash/Window_Sash_Quint.png",
    key: uuid(),
    name: "Sash_Quintuple",
  },
  {
    url: "/windows/sash/Window_Sash_ThirdRight.png",
    key: uuid(),
    name: "Sash_Third_Right",
  },
  {
    url: "/windows/sash/Window_Sash_ThirdLeft.png",
    key: uuid(),
    name: "Sash_Third_Left",
  },
  {
    url: "/windows/sash/Window_Sash_DoubleDividerOneThird.png",
    key: uuid(),
    name: "Sash_Double_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_QuadDividerOneThird.png",
    key: uuid(),
    name: "Sash_Quadruple_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_QuarterHalfQuarterDividerOneThird.png",
    key: uuid(),
    name: "Sash_Quarter_Half_Quarter_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_QuintDividerOneThird.png",
    key: uuid(),
    name: "Sash_Quinte_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_SingleDividerOneThird.png",
    key: uuid(),
    name: "Sash_Single_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_ThirdLeftDividerOneThird.png",
    key: uuid(),
    name: "Sash_Third_Left_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_ThirdRightDividerOneThird.png",
    key: uuid(),
    name: "Sash_Third_Right_Divider_Top_One_Third",
  },
  {
    url: "/windows/sash/Window_Sash_TripleDividerOneThird.png",
    key: uuid(),
    name: "Sash_Triple_Divider_Top_One_Third",
  },
  {
    url: "/windows/sliders/Window_Slider_Single.png",
    key: uuid(),
    name: "Slider_Single",
  },
  {
    url: "/windows/sliders/Window_Slider_Triple.png",
    key: uuid(),
    name: "Slider_Triple",
  },
  {
    url: "/windows/sliders/Window_Slider_QuarterHalfQuarter.png",
    key: uuid(),
    name: "Slider_Quarter_Half_Quarter",
  },
  {
    url: "/windows/sliders/Window_Slider_ThirdRight.png",
    key: uuid(),
    name: "Slider_Third_Right",
  },
  {
    url: "/windows/sliders/Window_Slider_ThirdLeft.png",
    key: uuid(),
    name: "Slider_Third_Left",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdLeftDivMiddle.png",
    key: uuid(),
    name: "Contemporary_Third_Left_Div_Middle",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdRightDivMiddle.png",
    key: uuid(),
    name: "Contemporary_Third_Right_Div_Middle",
  },

  {
    url: "/windows/contemporary/Window_Contemporary_ThirdRightDivThirds.png",
    key: uuid(),
    name: "Contemporary_Third_Right_Div_Thirds",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdLeftDivThirds.png",
    key: uuid(),
    name: "Contemporary_Third_Left_Div_Thirds",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdLeftThirdBottom.png",
    key: uuid(),
    name: "Contemporary_Third_Left_Third_Bottom",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdRightThirdBottom.png",
    key: uuid(),
    name: "Contemporary_Third_Right_Third_Bottom",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdLeftThirdTop.png",
    key: uuid(),
    name: "Contemporary_Third_Left_Third_Top",
  },
  {
    url: "/windows/contemporary/Window_Contemporary_ThirdRightThirdTop.png",
    key: uuid(),
    name: "Contemporary_Third_Right_Third_Top",
  },
  {
    url: "/windows/fixed/Window_Fixed_Circle.png",
    key: uuid(),
    name: "Fixed_Circle",
  },
  {
    url: "/windows/fixed/Window_Fixed_Thin_Circle.png",
    key: uuid(),
    name: "Fixed_Thin_Circle",
  },
  {
    url: "/windows/fixed/Window_Fixed_HalfMoon.png",
    key: uuid(),
    name: "Fixed_HalfMoon",
  },
  {
    url: "/windows/fixed/Window_Fixed_Thin_HalfMoon.png",
    key: uuid(),
    name: "Fixed_Thin_HalfMoon",
  },
  {
    url: "/windows/fixed/Window_Fixed_Rectangular.png",
    key: uuid(),
    name: "Fixed_Rectangular",
  },
  {
    url: "/windows/fixed/Window_Fixed_Rectangular12.png",
    key: uuid(),
    name: "Fixed_Rectangular12",
  },
  {
    url: "/windows/fixed/Window_Fixed_Rectangular23.png",
    key: uuid(),
    name: "Fixed_Rectangular23",
  },
  {
    url: "/windows/fixed/Window_Fixed_Square.png",
    key: uuid(),
    name: "Fixed_Square",
  },
  {
    url: "/windows/fixed/Window_Fixed_Thin_Square.png",
    key: uuid(),
    name: "Fixed_Thin_Square",
  },
  {
    url: "/windows/fixed/Window_Fixed_TriangleEqual.png",
    key: uuid(),
    name: "Fixed_TriangleEqual",
  },
  {
    url: "/windows/fixed/Window_Fixed_TriangleLeft.png",
    key: uuid(),
    name: "Fixed_TriangleLeft",
  },
  {
    url: "/windows/fixed/Window_Fixed_TriangleRight.png",
    key: uuid(),
    name: "Fixed_TriangleRight",
  },
];

export default windowsArray;
