import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { db, storage } from "../../base"; // Import storage from Firebase for media upload
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const AdminNewsModal = ({ show, handleClose }) => {
    const { t } = useTranslation(["translation"]);
    const [newNewsItem, setNewNewsItem] = useState({
        titlekey: '',
        descriptionkey: '',
        media: null, // Store the media file
    });
    const [showNewsAdminModal, setShowNewsAdminModal] = useState(false);

    const toggleAdminModal = () => {
        setShowNewsAdminModal(!showNewsAdminModal);
    };

    const handleFileChange = (e) => {
        // Handle file selection and set it to the state
        const file = e.target.files[0];
        setNewNewsItem({ ...newNewsItem, media: file });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Upload media to Firebase Storage if provided
        if (newNewsItem.media) {
            const storageRef = storage.ref();
            const mediaRef = storageRef.child(`newsMedia/${newNewsItem.media.name}`);
            await mediaRef.put(newNewsItem.media);
            const mediaUrl = await mediaRef.getDownloadURL();
            newNewsItem.media = mediaUrl; // Replace media with the download URL
        }
    
        // Add createdAt with a server timestamp
        newNewsItem.createdAt = serverTimestamp();
    
        const newsCollection = collection(db, "newsBanner");
        await addDoc(newsCollection, newNewsItem);
    
        // Reset the state
        setNewNewsItem({
            titlekey: '',
            descriptionkey: '',
            media: null,
        });
    
        toggleAdminModal();
    };

    return (
        <>
            <button onClick={toggleAdminModal}>{t('newsadmin')}</button>
            <Modal
             show={showNewsAdminModal}
             onHide={toggleAdminModal}
             dialogClassName="admin-modal"
             contentClassName="admin-modal-content"
             backdrop="static"
             keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>Add News Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={newNewsItem.title}
                                onChange={(e) => setNewNewsItem({ ...newNewsItem, titlekey: e.target.value })}
                                placeholder="Enter news title"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={newNewsItem.description}
                                onChange={(e) => setNewNewsItem({ ...newNewsItem, descriptionkey: e.target.value })}
                                placeholder="Enter news description"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Media (Optional)</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Add News
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AdminNewsModal;
