// ContentModal.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ContentModal = ({ show, title, content, handleAccept, handleDecline }) => {
    const { t, i18n } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return (
    <Modal show={show} onHide={() => navigate('/')}>
      <Modal.Header closeButton>
        {title}
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <a href={i18n.language === 'en' ? "/pdfs/englishTermsoct2023.pdf" : "/pdfs/frenchTermsoct2023.pdf"} download>
          <Button variant="info">{t("consent.downloadpdf")}</Button>
        </a>
        <Button variant="success" onClick={handleAccept}>{t("consent.accept")}</Button>
        <Button variant="danger" onClick={handleDecline}>{t("consent.decline")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContentModal;
