import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { storage, useAuth, auth } from "../../base";
import "../uploadImage/uploadImage.css";
import { toast } from "react-toastify";

function UploadImage(props) {
  const currentUser = useAuth();
  const loggeInUser = auth.currentUser.uid;
  const projectNameRef = useRef();
  const [canvasImage, setCanvasImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [progress, setProgress] = useState(0);
  const [imageURL, setImageURL] = useState("");
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState("");
  const [goUpload, setGoUpload] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);

  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    projectNameRef.current.focus();

    // Add event listener for beforeunload to prevent project creation on exit
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    if (!uploadSuccessful) {
      e.preventDefault();
      e.returnValue = ""; // This is required for Chrome to show the prompt
    }
  };

  const handleProjectName = (e) => {
    if (e.target.value) {
      setProjectName(e.target.value);
      props.setProjectName(e.target.value);
      props.setNewProjectCreationName(e.target.value);
    } else toast.warn(t("upload-enterprojectname"));
    projectNameRef.current.focus();
    return;
  };

  const handleCameraClick = () => {
    if (!projectName) {
      toast.warn(t("upload-enterprojectname"), {
        position: toast.POSITION.TOP_CENTER,
      });
      return; // If checkAndSetProjectName returns false, it means no project name was set
    }
  };

  const handleDefaultImageSelect = (imageSrc, imageName) => {
    if (!projectName) {
      toast.warn(t("upload-enterprojectname"), {
        position: toast.POSITION.TOP_CENTER,
      });
      return; // If checkAndSetProjectName returns false, it means no project name was set
    }

    setImageURL(imageSrc);
    setCanvasImage(imageSrc);
    setImageName(imageName);
    setGoUpload(true);
    handleUpload(imageSrc, imageName);
  };

  const handleChange = (e) => {
    if (e) {
      setGoUpload(true);
    }
    setError("");
    setCanvasImage("");
    setImageName("");
    const file = e.target.files[0];
    if (file) {
      const fileMaxWeight = 1024 * 1024 * 16;
      const fileWeight = file["size"];
      const fileType = file["type"];
      const validImageType = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
      ];

      if (!validImageType.includes(fileType) || fileWeight > fileMaxWeight) {
        if (fileWeight > fileMaxWeight) {
          setError(t("uploadImage.errorWeight"));
        }
        if (!validImageType.includes(fileType)) {
          setError(t("uploadImage.errorType"));
        }
        return; // Exit function if an error occurred
      }

      setCanvasImage(file);
      setImageName(file.name);
      setError("");
      setImageURL(URL.createObjectURL(file)); // This line updates the thumbnail preview
    }
  };

  const handleUpload = (canvasImage, imageName) => {
    props.setImageName(imageName)
    return new Promise((resolve, reject) => {
        if (!canvasImage || !imageName) {
            console.error('canvasImage or imageName is undefined');
            reject(new Error('canvasImage or imageName is undefined'));
            return;
        }

       props.showLoader();

        const uploadImage = (file, imageName) => {
            const imageRef = storage.ref(`usersProjectsImages/${loggeInUser}/canvasImages/${projectName.replaceAll(" ", "_") + "_" + loggeInUser}`);
            const uploadTask = imageRef.put(file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    if (snapshot.totalBytes > 0) {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgress(progress);
                    } else {
                        console.warn('Total bytes is zero');
                    }
                },
                (error) => {
                    console.log(error);
                    props.hideLoader();
                    reject(error);
                },
                () => {
                    imageRef.getDownloadURL()
                        .then((url) => {
                            setImageURL(url); //url for canvas background
                            props.setBackgroundImageStorageUrl(url);
                            // props.hideLoader();
                            setUploadSuccessful(true); // Set upload successful flag
                            props.closeUpload(false);

                            if (props.onImageUploadSuccess) {
                                props.onImageUploadSuccess(url);
                            }
                            resolve(url); // Return the URL upon successful upload.
                        })
                        .catch((error) => {
                            console.log(error);
                            props.hideLoader();
                            reject(error);
                        });
                }
            );

            setProgress(0);
            props.closeAccount(false);
        };

        if (typeof canvasImage === 'string') { // Default image selected
            fetch(canvasImage)
                .then((response) => response.blob())
                .then((blob) => {
                    const file = new File([blob], imageName, { type: blob.type });
                    uploadImage(file, imageName);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        } else { // User-selected file
            uploadImage(canvasImage, imageName);
        }
    });
  };

  const handleClose = () => {
    if (!uploadSuccessful) {
      // Do not proceed with project creation if upload was not successful
      props.setProjectName("");
    }
    props.closeUpload(false);
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("uploadImage.startProject")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="projectNameInput">
          <Form>
            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("uploadImage.projectNameLabel")}</Form.Label>
              <Form.Control
                className="inputProjectName mb-1"
                type="text"
                placeholder="Projet 1"
                ref={projectNameRef}
                autoComplete="off"
                name="pName"
                onChange={handleProjectName}
              />
            </Form.Group>
          </Form>
        </div>

        <div className="text-center">
          <div className="labelContainer w-100">
            <div className="uploadFromDevice">
              <label htmlFor="inputTag" className="mx-3 pt-2 fw-bold">
                <h3>{t("uploadImage.selectImage")}</h3>
                <br />
                <input
                  disabled={!projectName || progress}
                  id="inputTag"
                  type="file"
                  size="sm"
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <br />
                <span id="imageName">{imageName}</span>
                <div className="tooltip-container">
                  {!imageURL && <div className="arrow">⬇</div>}
                  <div className="faContainer" onClick={handleCameraClick}>
                    {imageURL ? (
                      <img className="thumbnail" src={imageURL} alt="Thumbnail" />
                    ) : (
                      <i className="fa fa-2x fa-camera"></i>
                    )}
                  </div>
                </div>
                <div className="buttonContainer text-center">
                  <Button
                    className="btn btn-secondary mt-2"
                    disabled={!goUpload || progress}
                    onClick={() => {
                      handleUpload(canvasImage, imageName).catch((error) => {
                        console.error("Error uploading image:", error);
                      });
                    }}
                  >
                    {t("uploadImage.upload")}
                  </Button>
                </div>
              </label>
            </div>

            {error && <div className="error">{error}</div>}
            {!imageURL && (
              <div className="weightAndType fw-bold">
                {t("uploadImage.maxWeightType")}
              </div>
            )}
            <div className="defaultImagesSection">
              <h4>{t("uploadImage.selectDefaultImage")}</h4>
              <div className="defaultImages">
                <img
                  src="maisons-modeles/third_ImageCanvassDuplexPro.jpg"
                  alt="Default Image 1"
                  onClick={() =>
                    handleDefaultImageSelect(
                      "maisons-modeles/ImageCanvassDuplexPro.jpg",
                      "ImageCanvassDuplexPro.jpg"
                    )
                  }
                />
                <img
                  src="maisons-modeles/third_ImageCanvassEtagesPro.jpg"
                  alt="Default Image 2"
                  onClick={() =>
                    handleDefaultImageSelect(
                      "maisons-modeles/ImageCanvassEtagesPro.jpg",
                      "ImageCanvassEtagesPro.jpg"
                    )
                  }
                />
              </div>
            </div>
            <div>
              <div className="titleNewProject my-4"></div>
              <br />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UploadImage;
