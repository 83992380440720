import { v1 as uuid } from "uuid";

export const allModelsDoorsArray = [
  {
    url: "/doors/single/Single_Door_EmbossedAAGlassAt1NoSidelightB.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed1ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed1ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2AGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2ANoGlassSidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2BGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2BNoGlassSidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed2BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3AGlassAt123Sidelight1A.png",
    key: uuid(),
  },
  // {
  //   url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3AGlassAt123SideLight3C.png",
  //   key: uuid(),
  // },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3AGlassAt123SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3AGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3AGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3BGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3BGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed3BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6AGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6BGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6CNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6CNoGlassSidelight3A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRLSidelight/Single_Door_Right_Left_SideLight_Embossed6CNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed1ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed1ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2AGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2AGlassAt2Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2AGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2ANoGlassSidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2BGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2BGlassAt2Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2BGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2BNoGlassSidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed2BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3AGlassAt123Sidelight1A.png",
    key: uuid(),
  },
  // {
  //   url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3AGlassAt123SideLight3C.png",
  //   key: uuid(),
  // },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3AGlassAt123SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3AGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3AGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3BGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3BGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed3BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6AGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6AGlassAt3456Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6AGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6BGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6BGlassAt3456Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6BGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6CNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6CNoGlassSidelight3A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_Embossed6CNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedCambreeLambrisNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedCambreeNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedCorriente4Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedCorriente4SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedCorriente5Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedCorriente5SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedFantasiaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedGendarmeSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedPlazaSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedPlazaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedOsoSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedSlabLineaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_EmbossedVogSlabSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_FlatWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_FlatWithSidelightFlat.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_FlatWithSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_LineaWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_OsoWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleRSidelight/Single_Door_Right_SideLight_VogWithSidelight1A.png",
    key: uuid(),
  },

  {
    url: "/doors/single/Single_Door_Embossed1AGlassAt1NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed1AGlassAt1NoSidelight_8ft.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_Embossed1ANoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_Embossed2AGlassAt2NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed2AGlassAt2NoSidelight_6ft.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_Embossed2ANoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_Embossed2ANoGlassNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed2BGlassAt2NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed2BGlassAt2NoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed2BGlassAt2NoSidelight_8ft.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_Embossed2BNoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_Embossed2BNoGlassNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed3AGlassAt123NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed3AGlassAt3NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed3BGlassAt3NoSidelight2.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_Embossed3ANoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_Embossed3ANoGlassNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed3BGlassAt3NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed3BGlassAt3NoSidelight_6ft.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_Embossed3BNoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_Embossed3BNoGlassNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed6AGlassAt3456NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed6AGlassAt3456NoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed6AGlassAt3456NoSidelight_8ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed6BGlassAt3456NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_Embossed6CNoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_Embossed6CNoGlassNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_Embossed6CNoGlassNoSidelight_8ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedCambreeLambrisNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedCorriente4NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedCorriente4NoSidelight2.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedCorriente5NoSidelight.png",
    key: uuid(),
  },
   { url: "/doors/single/Single_Door_EmbossedFantasiaNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_EmbossedFantasiaNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedGendarmeNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedLineaGlassNoSidelight.png",
    key: uuid(),
  },
  
   { url: "/doors/single/Single_Door_EmbossedSlabOsoNoGlassNoSidelight.png", key: uuid() },

  { url: "/doors/single/Single_Door_EmbossedPlazaNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_EmbossedShakerGlassAt4NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedShakerNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedSlabLineaNoGlassNoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/single/Single_Door_EmbossedTaoSlabNoSidelight.png", key: uuid() },
  {
    url: "/doors/single/Single_Door_EmbossedTeteCambreeNoGlassNoSidelight_6ft.png",
    key: uuid(),
  },
  {
    url: "/doors/single/Single_Door_EmbossedVogSlabNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
  },

  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2AGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2AGlassAt2Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2AGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2ANoGlassSidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2BGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2BGlassAt2Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2BGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2BNoGlassSidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed2BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3AGlassAt123Sidelight1A.png",
    key: uuid(),
  },
  // {
  //   url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3AGlassAt123SideLight3C.png",
  //   key: uuid(),
  // },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3AGlassAt123SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3AGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3AGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3BGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3BGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed3BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6AGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6AGlassAt3456Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6AGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6BGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6BGlassAt3456Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6BGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6CNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6CNoGlassSidelight3A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed6CNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedCambreeLambrisNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedCambreeNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedCorriente4Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedCorriente4SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedCorriente5Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedCorriente5SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedFantasiaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedGendarmeSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedPlazaSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedPlazaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedOsoSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedSlabLineaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_EmbossedVogSlabSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_FlatWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_FlatWithSidelightFlat.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_FlatWithSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_LineaWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_OsoWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_VogWithSidelight1A.png",
    key: uuid(),
  },

  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed1ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/singleLSidelight/Single_Door_Left_SideLight_Embossed1ANoGlassSidelightFull.png",
    key: uuid(),
  },

  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed1AGlassAt1SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed1ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed1ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2AGlassAt2Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2AGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2ANoGlassSidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2BGlassAt2Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2BGlassAt2SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2BNoGlassSidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed2BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3AGlassAt123Sidelight1A.png",
    key: uuid(),
  },
   // {
  //   url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3AGlassAt123SideLight3C.png",
  //   key: uuid(),
  // },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3AGlassAt123SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3AGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3AGlassAt3SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3ANoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3ANoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3BGlassAt3Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3BNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed3BNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6AGlassAt3456Sidelight2A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6AGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6BGlassAt3456Sidelight2B.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6BGlassAt3456SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6CNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6CNoGlassSidelight3A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_Embossed6CNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedCambreeLambrisNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedCambreeNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedCorriente4Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedCorriente4SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedCorriente5Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedCorriente5SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedFantasiaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedGendarmeSidelightFull.png",
    key: uuid(),
  },
    {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedPlazaSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedPlazaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedShakerGlassAt4Sidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedShakerGlassAt4SidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedShakerNoGlassSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedShakerNoGlassSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedOsoSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedSlabLineaSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedTaoSlabSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedTaoSlabSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_EmbossedVogSlabSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_FlatWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_FlatWithSidelightFlat.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_FlatWithSidelightFull.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_LineaWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_OsoWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/doubleRLSidelight/Double_Door_Right_Left_SideLight_VogWithSidelight1A.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_Embossed1AGlassAt1NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_Embossed1ANoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_Embossed2AGlassAt2NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_Embossed2ANoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_Embossed2BGlassAt2NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_Embossed2BNoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_Embossed3AGlassAt123NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_Embossed3AGlassAt3NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_Embossed3ANoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_Embossed3BGlassAt3NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_Embossed3BNoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_Embossed6AGlassAt3456NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_Embossed6BGlassAt3456NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_Embossed6CNoGlassNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_EmbossedCambreeLambrisNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedCambreeNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedCorriente4NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedCorriente5NoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_EmbossedFantasiaNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_EmbossedGendarmeNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedLineaGlassNoSidelight.png",
    key: uuid(),
  },
  
  { url: "/doors/double/Double_Door_EmbossedOsoGlassNoSidelight.png", key: uuid() },
  { url: "/doors/double/Double_Door_EmbossedPlazaNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_EmbossedShakerGlassAt4NoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedShakerNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedOsoNoGlassNoSidelight.png",
    key: uuid(),
  },
  {
    url: "/doors/double/Double_Door_EmbossedSlabLineaNoGlassNoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_Door_EmbossedTaoSlabNoSidelight.png", key: uuid() },
  {
    url: "/doors/double/Double_Door_EmbossedVogSlabNoGlassNoSidelight.png",
    key: uuid(),
  },
  { url: "/doors/double/Double_FlatNoSidelight.png", key: uuid() },
];

export default allModelsDoorsArray;
