 // we add a transum to the door or window

 import transumsArray from "../inventaire/transumsArray";

    //  /transums/Single_Door_Embossed_326_948_Rectangular_Transum_160_Lite.png
    
     // if (gridUrl.includes("transum")) {

    //   var obj = canvas.getActiveObject();
    //   // alert(obj.left + "," + obj.top);
    //   console.log(obj)

    //   fabric.Image.fromURL("/possibleBackgrounds/background_Standard_Transum_Single_16.png", function (oImg) {
    //     backgroundTransumImage = oImg;

    //     backgroundTransumImage.set({
    //       left: obj.left,
    //       top: obj.top -80,
    //       originX: "center",
    //       crossOrigin: "anonymous",
    //     });

    //     backgroundTransumImage.scaleToWidth(obj.width * obj.scaleX)


    //     canvas.sendToBack(backgroundTransumImage);
    //   },{ crossOrigin: 'anonymous'});


    //   fabric.Image.fromURL(
    //     gridUrl,
    //     function (oImg) {
    //       let transumImage = oImg;

    //       transumImage.set({
    //         crossOrigin: "anonymous",
    //         top: obj.top - 80 ,
    //         left: obj.left,
    //         originX: "center",

    //       });

    //       var filter = new fabric.Image.filters.BlendColor({
    //         color: dfItemColor,
    //         mode: "multiply",
    //         alpha: 0.95,
    //       });

    //       transumImage.filters.push(filter);
    //       transumImage.applyFilters();

    //       transumImage.scaleToWidth(obj.width * obj.scaleX)
    //       canvas.bringToFront(transumImage)
    //       obj.addWithUpdate(backgroundTransumImage);
    //       obj.addWithUpdate(transumImage);
    //       canvas.requestRenderAll();
    //     },
    //     { crossOrigin: "anonymous" }
    //   );
    // }

    function addTransum(url, type){

        var transumSize = url.split("Transum_");
  var TransumToBuild = transumSize[1].slice(0, -4);

  // var obj = canvas.getActiveObject();
  // alert(obj.left + "," + obj.top);
  //     console.log(obj.type)

        // let transUrl;

        // const itemName = url;
    
        // let tempTR = transumsArray.filter((item) =>
        //   item.name.includes(itemName)
        // );
        // transUrl = tempTR[0].url;
        console.log(TransumToBuild)
      }

      export default addTransum