

export const itemsBackgroundsArray = [
  {
    name: "Sash_Single_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Single.png",
  }, 
  {
    name: "Sash_Third_Left_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Triple.png",
  },
  {
    name: "Sash_Third_Right_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Triple.png",
  },
  {
    name: "Sash_Triple_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Triple.png",
  },
  {
    name: "Sash_Double_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Double.png",
  },
  {
    name: "Sash_Quadruple_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Quad.png",
  },
  {
    name: "Sash_Quinte_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Quint.png",
  },
  {
    name: "Sash_Quarter_Half_Quarter_Divider_Top_One_Third",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Quad.png",
  },
  {
    name: "Transum_Single",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Transum_Single_16.png",
  },
  {
    name: "Sash_Single",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Single.png",
  },
  {
    name: "Sash_Double",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Double.png",
  },
  {
    name: "Sash_Triple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Triple.png",
  },
  {
    name: "Sash_Quadruple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Quad.png",
  },
  {
    name: "Sash_Quintuple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Quint.png",
  },
  {
    name: "Sash_Quarter_Half_Quarter",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Quad.png",
  },
  {
    name: "Sash_Third_Right",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Triple.png",
  },
  {
    name: "Sash_Third_Left",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Sash_Triple.png",
  },
  {
    name: "Slider_Third_Left",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Slider_Triple.png",
  },
  {
    name: "Slider_Third_Right",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Slider_Triple.png",
  },
  {
    name: "Slider_Triple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Slider_Triple.png",
  },
  {
    name: "Slider_Quarter_Half_Quarter",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Slider_QuarterHalfQuarter.png",
  },
  {
    name: "Slider_Single",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Slider_Single.png",
  },
  {
    name: "Contemporary_Third_Left_Div_Middle",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Contemporary_Third_Right_Div_Middle",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Contemporary_Third_Left_Div_Thirds",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Contemporary_Third_Right_Div_Thirds",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Contemporary_Third_Right_Third_Bottom",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Contemporary_ThirdRightThirdBottom.png",
  },
  {
    name: "Contemporary_Third_Left_Third_Bottom",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Contemporary_ThirdRightThirdBottom.png",
  },
  {
    name: "Contemporary_Third_Right_Third_Top",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Contemporary_ThirdRightThirdBottom.png",
  },
  {
    name: "Contemporary_Third_Left_Third_Top",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Contemporary_ThirdRightThirdBottom.png",
  },
  

  {
    name: "Casement_Single",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Single.png",
  },
  {
    name: "Casement_Double",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Double.png",
  },
  {
    name: "Casement_Triple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Casement_Third_Left",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Casement_Third_Right",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Triple.png",
  },
  {
    name: "Casement_Quadruple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Quad.png",
  },
  {
    name: "Casement_Quintuple",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Quint.png",
  },
  {
    name: "Casement_Quarter_Half_Quarter",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Casement_Quad.png",
  },

  {
    name: "Fixed_Thin_Square",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Thin_Square.png",
  },
  {
    name: "Fixed_Square",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Thin_Square.png",
  },
  {
    name: "Fixed_Circle",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Circle.png",
  },
  {
    name: "Fixed_Thin_Circle",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Thin_Circle.png",
  },
  {
    name: "Fixed_HalfMoon",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_HalfMoon.png",
  },
  {
    name: "Fixed_Thin_HalfMoon",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Thin_HalfMoon.png",
  },
  {
    name: "Fixed_Rectangular",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Rectangular.png",
  },
  {
    name: "Fixed_Rectangular12",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Rectangular12.png",
  },
  {
    name: "Fixed_Rectangular23",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_Rectangular23.png",
  },
  {
    name: "Fixed_TriangleEqual",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_TriangleEqual.png",
  },
  {
    name: "Fixed_TriangleLeft",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_TriangleLeft.png",
  },
  {
    name: "Fixed_TriangleRight",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Fixed_TriangleRight.png",
  },

  {
    name: "Single_Door",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Single_Door.png",
  },

  {
    name: "Single_Door_AI",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Single_Door.png",
  },

  {
    name: "Single_Door_Embossed1AGlassAt1NoSidelight",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Single_Door.png",
  }, 

  {
    name: "Single_Door_E",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Single_Door.png",
  },

  {
    name: "Single_Door_Right_Left_SideLight_Full",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Right_Left_SideLight_Full.png",
  },

  {
    name: "Single_Door_Right_Left_SideLight",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Right_Left_SideLight.png",
  },
  {
    name: "Single_Door_Right_Left_SideLight_AI",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Right_Left_SideLight.png",
  },

  {
    name: "Single_Door_Right_SideLight_Full",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Right_SideLight_Full.png",
  },

  {
    name: "Single_Door_Right_SideLight",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Right_SideLight.png",
  },
  {
  name: "Single_Door_Left_SideLight_AI",
  type: "Light",
  bgUrl:
    "/possibleBackgrounds/background_Standard_Single_Door_Right_SideLight.png",
},
  {
    name: "Single_Door_Left_SideLight",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Left_SideLight.png",
  },
  {
    name: "Single_Door_Left_SideLight_Full",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Single_Door_Left_SideLight_Full.png",
  },
  {
    name: "Double_Door_E",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Double_Door.png",
  },
  {
    name: "Double_Door_E_AI",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Double_Door.png",
  },
  {
    name: "Double_Door",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Double_Door.png",
  },
  {
    name: "Double_Door_AI",
    type: "Light",
    bgUrl: "/possibleBackgrounds/background_Standard_Double_Door.png",
  },
  {
    name: "Double_Door_Right_Left_SideLight",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Double_Door_Right_Left_Sidelight.png",
  },
  {
    name: "Double_Door_Right_Left_SideLight_Full",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Double_Door_Right_Left_Sidelight_Full.png",
  },
  {
    name: "Garage_Door_Single_With_Glass",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Garage_Door_Single_With_Glass.png",
  },
  {
    name: "Garage_Door_Double_With_Glass",
    type: "Light",
    bgUrl:
      "/possibleBackgrounds/background_Standard_Garage_Door_Double_With_Glass.png",
  },
  
];

export default itemsBackgroundsArray;
