import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "tippy.js/dist/tippy.css";
import "./grids.css";
import { useTranslation } from "react-i18next";

function Grids(props) {

  const { t, i18n } = useTranslation(["translation"]);

  let gridOrGlass = props.windowURL && props.windowURL.includes("Door") && !props.windowURL.includes("NoGlassSidelightFull") ? true : false;

  const handleDropdownChange = (e) => {

    props.toggleOptionsPanel()


    var isSelect = props.checkForSeletedItems();
    var rType = props.checkSelectedObjectType();
    var isMix = rType.isMix;
    var isSame = rType.isSame;
    var type = rType.type;

    if (!e && !isSelect) {
      alert(t("grids-addItem"));
      {
        return;
      }
    } else if (!isSelect) {
      alert(t("grids-selectItem"));
      {
        return;
      }
    } else if (e && !isSelect) {
      alert(t("grids-selectItem"));
      {
        return;
      }
    } else if (!isSame && isMix) {
      alert(t("grids-selectSameItem"));
      props.toggleOptionsPanel()
      return;
    } else if (isSelect) {

    }

    props.handleOptionsPanel(e);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <DropdownButton
        title={
          <img
            className="optionsButton"
            src="./icons/optionsButton.png"
            height="40px"
            alt="Options"
            title={t("grids-options")}
          />
        }
        id="dropdown-button-dark"
        variant="secondary"
        menuVariant="dark"
        size="sm"
        onSelect={handleDropdownChange}
        drop="end"
        className="btn mt-1 btn-block pb-0 m-0"
      >

        `       <div className="dropdownSection">
          <div className="dropdownTitleSection">{t("grids-options")}</div>
          {!gridOrGlass && props.windowURL && !props.windowURL.includes("Contemporary") && !props.windowURL.includes("Fixed") && !props.windowURL.includes("Door") && (
            <Dropdown.Item className="itemTypeTitle" eventKey={props.windowURL}>
              {t("grids-grids")}
              <>
                <img
                  src="./thumbnails/Window_Casement_Double_Grid1.png"
                  height={20}
                  alt="Grid"
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </>
            </Dropdown.Item>
          )}
          {/* {!gridOrGlass && props.windowURL && !props.windowURL.includes("Door") && (
            <Dropdown.Item className="itemTypeTitle" eventKey={props.windowURL}>
              {t("grids-shutters")}
              <>
                <img
                  src="./thumbnails/shutter_standard_white.png"
                  height={30}
                  alt="Shutter"
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </>
            </Dropdown.Item>
          )} */}
          {gridOrGlass && !props.windowURL.includes("Sidelight3A") && !props.windowURL.includes("GarageDoor") && !props.windowURL.includes("SidelightFlat") && !props.windowURL.includes("FlatWithSidelightFull") && (
            <Dropdown.Item className="itemTypeTitle" eventKey={props.windowURL}>
              {t("grids-decorativeGlasses")}
              <>
                <img
                  src="./thumbnails/Vitrail_Novatech_Bolero_22x48_Thumbnail.png"
                  height={20}
                  alt="Decorative Glass"
                  style={{
                    paddingLeft: 10,
                  }}
                />
              </>
            </Dropdown.Item>
          )}

          {/* <Dropdown.Item className="itemTypeTitle" disabled eventKey="transum">
            {t("grids-transums")} To-do
            <>
              <img
                src="./thumbnails/Window_Fixed_HalfMoonWhite.png"
                height={20}
                alt="Transum"
                style={{
                  paddingLeft: 10,
                }}
              />
            </>
          </Dropdown.Item> */}

          {/* <Dropdown.Item className="itemTypeTitle" disabled eventKey="background">
            Background To-do{" "}
            <>
              <img
                src="./thumbnails/Window_Slider_Single.png"
                height={20}
                alt="Background"
                style={{
                  paddingLeft: 10,
                }}
              />
            </>
          </Dropdown.Item> */}
        </div>
      </DropdownButton>
    </>
  );
}

export default Grids;
