import React from 'react';
import { useTranslation } from 'react-i18next';

const CombinedCookiePolicy = () => {
  const { i18n } = useTranslation();

  if (i18n.language === 'en') {
  return (      
  <div>
  <h1>1. Introduction</h1>
  <p>
    Our website, <a href="https://facadewebapp.com">https://facadewebapp.com</a>
    (hereinafter: "the website") uses cookies and other related technologies (for simplicity, all these technologies are referred to as "cookies"). Cookies are also placed by third parties we have engaged. In the document below, we inform you about the use of cookies on our website.
  </p>

  <h2>2. What are cookies?</h2>
  <p>
    A cookie is a simple small file sent with the pages of this website and stored by your browser on the hard drive of your computer or another device. The information stored therein can be sent back to our servers or the servers of the relevant third parties during a subsequent visit.
  </p>

  <h2>3. What are scripts?</h2>
  <p>
    A script is a piece of code used to make our website function correctly and interactively. This code is executed on our server or on your device.
  </p>

  <h2>4. What is a web beacon?</h2>
  <p>
    A web beacon (or pixel tag) is a small piece of text or image that is invisible on a website, used to track traffic on a website. Various data about you is stored using web beacons.
  </p>

  <h2>5. Third Parties</h2>
  <p>
    We have made agreements about the use of cookies with other companies that place cookies. However, we cannot guarantee that these third parties handle your personal data in a reliable or secure manner. Parties like Google must be considered as independent data controllers. We recommend reading the privacy statements of these companies.
  </p>

  <h2>6. Cookies</h2>

  <h3>6.1 Technical or functional cookies</h3>
  <p>
    Some cookies ensure the correct functioning of certain parts of the website and taking into account your preferences as a user. By placing functional cookies, we make it easier for you to visit our website. This way, you do not have to repeatedly enter the same information when visiting our website and, for example, items remain in your cart until you pay.
  </p>

  <h3>6.2 Analytical cookies</h3>
  <p>
    We use analytical cookies to optimize the user experience on our website. With these analytical cookies, we get insights into the usage of our website.
  </p>

  <h3>6.3 Marketing/tracking cookies</h3>
  <p>
    Marketing/tracking cookies are cookies or any other form of local storage, used to create user profiles to display advertising or to track the user on this website or across several websites for similar marketing purposes.
  </p>

  <h3>6.4 Social Media</h3>
  <p>
    On our website, we have included content from Facebook and Instagram to promote web pages (e.g., "like," "pin") or share (e.g., "tweet") on social networks like Facebook and Instagram. This code places cookies. This content can store and process certain information for personalized advertising. Please read the privacy statement of these social networks (which can change regularly) to know what they do with your (personal) data processed using these cookies. The data is anonymized as much as possible. Facebook and Instagram are located in the United States.
  </p>

  <h2>7. Cookies Placed</h2>
  <ul>
    <li>Facebook: Marketing/Tracking, Functional</li>
    <li>Google Analytics: Statistics, Marketing/Tracking</li>
    <li>Instagram: Marketing/Tracking</li>
    <li>Google Maps: Marketing/Tracking</li>
    <li>Various: Consent to service</li>
  </ul>

  <h2>8. Consent</h2>
  <p>
    When you visit our website for the first time, we will show you a pop-up window explaining the cookies. You have the right to refuse and to object to any further use of non-functional cookies.
  </p>

  <h3>8.1 Manage your consent settings</h3>
  <p>
    Functional: Always activated<br />
    Statistics: Optional<br />
    Marketing: Optional<br />
    You can also disable the use of cookies by your browser, but please note that our website may no longer work properly.
  </p>

  <h2>9. Enable/Disable and Delete Cookies</h2>
  <p>
    You can use your internet browser to delete cookies automatically or manually. You can also specify that certain cookies may not be placed. Another option is to change the settings of your internet browser so that you receive a message each time a cookie is placed. For more information about these options, please refer to the instructions in the Help section of your browser. Or you can indicate your preferences on the following page: <a href="https://youradchoices.ca">https://youradchoices.ca</a>
  </p>

  <p>
    Please note that our website may not work properly if all cookies are disabled. If you delete the cookies in your browser, they will be placed again after your consent when you revisit our websites.
  </p>

  <h2>10. Your rights regarding personal data</h2>
  <p>
    You have the following rights concerning your personal data:
  </p>
  <ul>
    <li>You can submit a request for access to the data we process about you.</li>
    <li>You can object to the processing.</li>
    <li>You can request an overview, in a commonly used format, of the data we process about you.</li>
    <li>You can request for the correction or deletion of data if they are incorrect or not relevant. When appropriate, the modified information will be forwarded to third parties who have access to the information in question.</li>
    <li>You have the right to withdraw your consent at any time, subject to contractual or legal restrictions and reasonable notice. You will be informed of the implications of such withdrawal.</li>
    <li>You have the right to file a complaint to our organization about any non-compliance with data protection laws and, if the issue is not resolved, to the Privacy Commissioner of Canada.</li>
  </ul>

  <h2>11. Contact Details</h2>
  <p>
    For questions and/or comments about our cookie policy and this statement, please contact us using the following details:
  </p>
  <address>
    Applications Renotrend Inc<br />
    198 rue Saint-Hubert, Laval Qc.<br />
    Canada<br />
    Website: <a href="https://renotrend.com">Renotrend</a><br />
    Email: <a href="mailto:facadewebapp@renotrend.com">Information</a>
  </address>
</div>
);
} else if (i18n.language === 'fr') {
  return (  
    <div>
      <h1>1. Introduction</h1>
      <p>
        Notre site web, <a href="https://facadewebapp.com">https://facadewebapp.com</a> (ci-après : « le site web ») utilise des cookies et autres technologies liées (par simplification, toutes ces technologies sont désignées par le terme « cookies »). Des cookies sont également placés par des tierces parties que nous avons engagées. Dans le document ci-dessous, nous vous informons de l’utilisation des cookies sur notre site web.
      </p>
    
      <h2>2. Que sont les cookies ?</h2>
      <p>
        Un cookie est un petit fichier simple envoyé avec les pages de ce site web et stocké par votre navigateur sur le disque dur de votre ordinateur ou d’un autre appareil. Les informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs des tierces parties concernées lors d’une visite ultérieure.
      </p>
    
      <h2>3. Que sont les scripts ?</h2>
      <p>
        Un script est un élément de code utilisé pour que notre site web fonctionne correctement et de manière interactive. Ce code est exécuté sur notre serveur ou sur votre appareil.
      </p>
    
      <h2>4. Qu’est-ce qu’une balise invisible ?</h2>
      <p>
        Une balise invisible (ou balise web) est un petit morceau de texte ou d’image invisible sur un site web, utilisé pour suivre le trafic sur un site web. Pour ce faire, diverses données vous concernant sont stockées à l’aide de balises invisibles.
      </p>
    
      <h2>5. Tierces parties</h2>
      <p>
        Nous avons passé des accords à propos de l’utilisation des cookies avec d’autres entreprises plaçant des cookies. Cependant, nous ne pouvons garantir que ces tierces parties gèrent vos données personnelles de manière fiable ou sécurisée. Certaines parties telles que Google doivent être considérées comme des responsables de traitement des données indépendants. Nous vous recommandons de lire les déclarations de confidentialité de ces entreprises.
      </p>
    
      <h2>6. Cookies</h2>
    
      <h3>6.1 Cookies techniques ou fonctionnels</h3>
      <p>
        Certains cookies assurent le fonctionnement correct de certaines parties du site web et la prise en compte de vos préférences en tant qu’utilisateur. En plaçant des cookies fonctionnels, nous vous facilitons la visite de notre site web. Ainsi, vous n’avez pas besoin de saisir à plusieurs reprises les mêmes informations lors de la visite de notre site web et, par exemple, les éléments restent dans votre panier jusqu’à votre paiement.
      </p>
    
      <h3>6.2 Cookies statistiques</h3>
      <p>
        Nous utilisons des cookies statistiques afin d’optimiser l’expérience des internautes sur notre site web. Avec ces cookies statistiques, nous obtenons des observations sur l’utilisation de notre site web.
      </p>
    
      <h3>6.3 Cookies de marketing/suivi</h3>
      <p>
        Les cookies de marketing/suivi sont des cookies ou toute autre forme de stockage local, utilisés pour créer des profils d’utilisateurs afin d’afficher de la publicité ou de suivre l’utilisateur sur ce site web ou sur plusieurs sites web dans des finalités marketing similaires.
      </p>
    
      <h3>6.4 Réseaux sociaux</h3>
      <p>
        Sur notre site web, nous avons inclus du contenu provenant de Facebook et Instagram pour promouvoir des pages web (par exemple, « like », « pin ») ou les partager (par exemple, « tweet ») sur des réseaux sociaux comme Facebook et Instagram. Ce contenu est intégré grâce un code obtenu de Facebook et Instagram et place des cookies. Ce contenu peut stocker et traiter certaines informations à des fins de publicité personnalisée. Veuillez lire la déclaration de confidentialité de ces réseaux sociaux (qui peut être modifiée régulièrement) afin de savoir ce qu’ils font de vos données (personnelles) traitées en utilisant ces cookies. Les données récupérées sont anonymisées autant que possible. Facebook et Instagram se trouvent aux États-Unis.
      </p>
    
      <h2>7. Cookies placés</h2>
      <ul>
        <li>Facebook : Marketing/Suivi, Fonctionnel</li>
        <li>Consent to service Facebook</li>
        <li>Google Analytics : Statistiques, Marketing/Suivi</li>
        <li>Instagram : Marketing/Suivi</li>
        <li>Consent to service Instagram</li>
        <li>Google Maps : Marketing/Suivi</li>
        <li>Consent to service Google Maps</li>
        <li>Divers</li>
        <li>Consent to service Divers</li>
      </ul>
    
      <h2>8. Consentement</h2>
      <p>
        Lorsque vous visitez notre site web pour la première fois, nous vous afficherons une fenêtre contextuelle avec une explication sur les cookies. Vous avez le droit de les refuser et de vous opposer à toute autre utilisation de cookies non fonctionnels.
      </p>
    
      <h3>8.1 Gérez vos réglages de consentement</h3>
      <p>
        Fonctionnel : Toujours activé<br />
        Statistiques : Optionnel<br />
        Marketing : Optionnel<br />
        Vous pouvez également désactiver l’utilisation de cookies par votre navigateur, mais veuillez noter que notre site web risque de ne plus fonctionner correctement.
      </p>
    
      <h2>9. Activer/désactiver et supprimer les cookies</h2>
      <p>
        Vous pouvez utiliser votre navigateur internet pour supprimer automatiquement ou manuellement des cookies. Vous pouvez également préciser que certains cookies ne doivent pas être placés. Une autre option est de modifier les réglages de votre navigateur Internet afin de recevoir un message à chaque fois qu’un cookie est placé. Pour plus d’informations à propos de ces options, veuillez vous référer aux instructions dans la section Aide de votre navigateur. Ou vous pouvez indiquer vos préférences sur la page suivante : <a href="https://youradchoices.ca">https://youradchoices.ca</a>
      </p>
    
      <p>
        Veuillez noter que notre site web peut ne pas marcher correctement si tous les cookies sont désactivés. Si vous supprimez les cookies dans votre navigateur, ils seront de nouveau placés après votre consentement lorsque vous revisiterez nos sites web.
      </p>
    
      <h2>10. Vos droits concernant les données personnelles</h2>
      <p>
        Vous avez les droits suivants concernant vos données personnelles :
      </p>
      <ul>
        <li>vous pouvez envoyer une demande d’accès aux données vous concernant que nous traitons ;</li>
        <li>vous pouvez vous opposer au traitement ;</li>
        <li>vous pouvez demander une vue d’ensemble, sous un format communément utilisé, des données que nous traitons à votre sujet ;</li>
        <li>vous pouvez demander la correction ou la suppression des données si elles sont incorrectes ou si elles ne sont plus d’actualité. Lorsque cela est approprié, les informations modifiées seront transmises à des tierces parties ayant accès aux informations en question.</li>
        <li>Vous avez le droit de retirer votre consentement à tout moment, sujet à des restrictions contractuelles ou juridiques et un préavis raisonnable. Vous serez informé(e) des implications d’un tel retrait.</li>
        <li>Vous avez le droit d’adresser à notre organisation une objection concernant tout non respect de la LPRDE et, si le problème n’est pas résolu, au Commissariat à la protection de la vie privée du Canada.</li>
      </ul>
    
      <h2>11. Coordonnées</h2>
      <p>
        Pour des questions et/ou des commentaires sur notre politique de cookies et cette déclaration, veuillez nous contacter en utilisant les coordonnées suivantes :
      </p>
      <address>
        Applications Renotrend Inc<br />
        198 rue Saint-Hubert, Laval Qc.<br />
        Canada<br />
        Site web : <a href="https://renotrend.com">Renotrend</a><br />
        Email : <a href="mailto:facadewebapp@renotrend.com">Information</a>
      </address>
    </div>
    );
  } else {
    return null; // Or a default case
  }
};

export default CombinedCookiePolicy;
