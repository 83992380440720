import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../eraserDraw/eraserDraw.css";
import { useTranslation } from "react-i18next";
import ItemColors from "..//ItemColors"
import ReplayIcon from '@mui/icons-material/Replay';
import DoneIcon from '@mui/icons-material/Done';

const EraserDraw = ({ onWidthChange, handleActionChange, handleColorChange, mode }) => {

    const { t } = useTranslation(["translation"]);

  const [width, setWidth] = useState(10);
  
 
  const handleWidthChange = (event) => {
    const newWidth = parseInt(event.target.value, 10);
    setWidth(newWidth);
    onWidthChange(newWidth);
  };

  const handleReset = () => {
    setWidth(10);
    onWidthChange(10);
  };

    const [color, setColor] = useState('rgba(0, 0, 0, 1)');
  
    const handleColor = (newColor) => {
      setColor(newColor);
      handleColorChange(newColor.hex);
    };
    

    return (
      <div id="overlay" className="eraser-draw" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
        
        {/* Title */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3 className="toolTitle" id="overlay-title">
            {mode === 'draw' ? t("toolsSelection-drawMode") : mode === 'erase' ? t("toolsSelection-eraseMode") : ''}
          </h3>
        </div>
        
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {/* Label */}
  <label htmlFor="brush-width">{t("toolsSelection-width")}:</label>
  
  {/* Slider and Reset Button */}
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
    {/* Slider */}
    <input type="range" className='slider' id="brush-width" min="1" max="100" value={width} onChange={handleWidthChange} />
    
    {/* Reset Button */}
    <button title={t("toolsSelection-reset")} id="reset-button" style={{ marginLeft: '10px' }} onClick={handleReset}> 
      <ReplayIcon />
    </button>
  </div>

  {/* Brush Size */}
  <span id="brush-width-value">{width}</span>
</div>



        
        {/* Finish Button */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button title={t("toolsSelection-done")}id="finish-button" onClick={() => handleActionChange('undo')}><DoneIcon/></button>
        </div>
        
        {/* Color Picker */}
        {mode === 'draw' && 
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ItemColors parent="EraserDraw" color={color} handleColor={handleColor} />
          </div>
        }
        
      </div>
    );
        
    
};

export default EraserDraw;
