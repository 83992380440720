import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gallery.css';

const Gallery = ({ items, titleKey }) => {
    const { t } = useTranslation();
    const title = t(titleKey);

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const handleDragStart = (event) => {
        event.preventDefault();
    };
    function formatName(name) {
        if (!name) return ''; // Return an empty string if the name is undefined or empty
        return name
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
      
      


    return (
        <>
            {/* <div className="gallery-header">
                <h2>{title}</h2>
            </div> */}
            <Carousel>
                {items.map((item, index) => (
                    <Carousel.Item key={index}>
                         <p className="legend">{formatName(item.name)}</p>

                        <img
                            className="d-block w-100"
                            src={item.url}
                            alt={item.name || "Item"}
                            onContextMenu={handleContextMenu}
                            onDragStart={handleDragStart}
                            // style={{ maxHeight: '100px', width: 'auto', margin: '0 auto', display: 'block', height: '100%' }}
                        />
                        <Carousel.Caption>
                            {/* <p className="legend">{item.name}</p> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
};

export default Gallery;
