
import { toast } from "react-toastify";
import LoadingToast from "../toasts/LoadingToast";
import i18n from 'i18next';


// Function to show a loading toast with a custom message
function showLoadingToast(message) {
    return toast(<LoadingToast message={message} />, {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      position: "top-center",
    });
  }
  
  // Function to update the toast message and close it
  function updateToast(toastId, message, type) {
    toast.update(toastId, {
      render: <LoadingToast message={message} />,
      type: type,
      autoClose: 3000
    });
  }


// Global constants for real door size and the default pixel size of a door image
const realDoorSize = { width: 36, height: 82.5 }; // in inches
// scaleFactor.width = 573 / 36;
// const doorPixelSize = { width: 573, height: 1268 }; // in pixels

//Function to calculate the project-specific scale factor based on detected door size
function calculateProjectScaleFactor(doorDetection) {
    const detectedDoorWidth = doorDetection.tlbr[2] - doorDetection.tlbr[0];
    const detectedDoorHeight = doorDetection.tlbr[3] - doorDetection.tlbr[1];

    // This scale factor tells us how many pixels represent an inch
    return {
        width: detectedDoorWidth / realDoorSize.width,
        height: detectedDoorHeight / realDoorSize.height,
    };
}


// Criteria for determining window and door types based on their real-world dimensions
const typeCriteria = [
    {
        class: "window",
        tName: "Casement_Single",
        preferredWidthRange: { min: 16, max: 30 }, // inches
        possibleWidthRange: { min: 12, max: 36 }, // inches
        aspectRatioMin: 0.2, // Adjusted to allow square shapes
        aspectRatioMax: 1.2, // Adjusted to allow square shapes
    },
    {
        class: "window",
        tName: "Casement_Double",
        preferredWidthRange: { min: 24, max: 48 }, // inches
        possibleWidthRange: { min: 20, max: 52 }, // inches
        aspectRatioMin: 0.2, // Adjusted to allow square shapes
        aspectRatioMax: 1.2, // Adjusted to allow square shapes
    },
    
    {
        class: "window",
        tName: "Casement_Triple",
        preferredWidthRange: { min: 48, max: 96 }, // Example values
        possibleWidthRange: { min: 36, max:102 }, // Example values
        aspectRatioMin:.66, // Adjust based on expected shapes
        aspectRatioMax: 1.66, // Adjust based on expected shapes
    },
    {
        class: "window",
        tName: "Casement_Quad",
        preferredWidthRange: { min: 64, max: 120 }, // Example values
        possibleWidthRange: { min: 60, max: 130 }, // Example values
        aspectRatioMin: .87, // Adjust based on expected shapes
        aspectRatioMax: 2.2, // Adjust based on expected shapes
    },
    
    {
        class: "door",
        tName: "Single_Door_Embossed1AGlassAt1NoSidelight",
        preferredWidthRange: { min: 35, max: 40 }, // Example values
        possibleWidthRange: { min: 28, max: 42 }, // Example values
        aspectRatioMin: 0.36, aspectRatioMax: 0.47,
    },
    {
        class: "door",
        tName: "Single_Door_Left_SideLight_Embossed1AGlassAt1Sidelight1A",
        preferredWidthRange: { min: 42, max: 60 }, // Example values
        possibleWidthRange: { min: 42, max: 64 }, // Example values
        aspectRatioMin: 0.52, aspectRatioMax: .74,
    },
    {
        class: "door",
        tName: "Single_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A",
        preferredWidthRange: { min: 60, max: 68 }, // Example values
        possibleWidthRange: { min: 58, max: 70 }, // Example values
        aspectRatioMin: 0.73, aspectRatioMax: 0.83,
    },
    {
        class: "door",
        tName: "Double_Door_Embossed1AGlassAt1NoSidelight",
        preferredWidthRange: { min: 69, max: 72 }, // Example values
        possibleWidthRange: { min: 64, max: 72 }, // Example values
        aspectRatioMin: 0.84, aspectRatioMax: 0.87,
    },
];

function determineTNameFromDetectionWithAspectRatio(detection) {
    const width = detection.tlbr[2] - detection.tlbr[0];
    const height = detection.tlbr[3] - detection.tlbr[1];
    const aspectRatio = width / height;

    console.log(detection)

    const matchingCriteria = typeCriteria.find(criteria =>
        detection.class === criteria.class &&
        aspectRatio >= criteria.aspectRatioMin &&
        aspectRatio <= criteria.aspectRatioMax);

    if (!matchingCriteria) {
        console.log(`No matching type found for detection with aspect ratio: ${aspectRatio} and class: ${detection.class}`);
        return null;
    }

    return matchingCriteria.tName;
}


function determineTNameFromDetectionWithScaleFactor(detection, projectScaleFactor) {
    // Convert detection sizes from pixels to inches using the projectScaleFactor
    const widthInPixels = detection.tlbr[2] - detection.tlbr[0];
    const heightInPixels = detection.tlbr[3] - detection.tlbr[1];
    const realWidth = widthInPixels / projectScaleFactor.width;
    const realHeight = heightInPixels / projectScaleFactor.height;
    const aspectRatio = realWidth / realHeight;


    // Find a matching criterion based on real-world dimensions and aspect ratios
    const matchingCriteria = typeCriteria.find(criteria => {
        if (detection.class !== criteria.class) return false;

        // First, check if the real-world width fits within any specified ranges for this type
        const fitsPreferredWidthRange = realWidth >= criteria.preferredWidthRange.min && realWidth <= criteria.preferredWidthRange.max;
        const fitsPossibleWidthRange = realWidth >= criteria.possibleWidthRange.min && realWidth <= criteria.possibleWidthRange.max;

        // If aspect ratio is considered, check that too
        const fitsAspectRatio = aspectRatio >= criteria.aspectRatioMin && aspectRatio <= criteria.aspectRatioMax;

        return (fitsPreferredWidthRange || fitsPossibleWidthRange) && fitsAspectRatio;

    });

    if (!matchingCriteria) {
        console.log(`No matching type found for detection with aspect ratio: ${aspectRatio}, width: ${realWidth} inches, and class: ${detection.class}`);
        return null;
    }

    return matchingCriteria.tName;
}

function determineDoorOptionsFromAI(typeName) {
    let doorOptionsName;

    if (typeName.startsWith("Double_Door_Embossed")) {
        // Handles Double Door types
        doorOptionsName = "Double_Door_AI";
    } else if (typeName.includes("SideLight") && !typeName.includes("Right_Left_SideLight")) {
        // Handles Single Doors with SideLight (either right or left)
        doorOptionsName = "Single_Door_Left_SideLight_AI";
    } else if (typeName.includes("Right_Left_SideLight")) {
        // Handles standard Single Doors without 2 sidelights
        doorOptionsName = "Single_Door_Right_Left_SideLight_AI";
    }else if (typeName.startsWith("Single_Door_Embossed" && !typeName.includes("SideLight"))) {
        // Handles standard Single Doors without sidelights
        doorOptionsName = "Single_Door_AI";
    }else {
        // Default case to use the original typeName if no specific mapping applies
        doorOptionsName = typeName;
    }
    return doorOptionsName;
}

// Refactor the path construction logic into its own function
function constructPathForDetection(tName, detectionClass) {
    let localPath;
    if (detectionClass === "window") {
        localPath = `/windows/casement/Window_${tName}.png`;
    } else {
        if (tName.startsWith("Double_Door_")) {
            localPath = `/doors/double/${tName}.png`;
        } else if (tName.includes("SideLight") && !tName.includes("Right_Left")) {
            localPath = `/doors/singleLSidelight/${tName}.png`;
        } else if (tName.includes("Right_Left_SideLight")) {
            localPath = `/doors/singleRLSidelight/${tName}.png`;
        } else {
            localPath = `/doors/single/${tName}.png`;
        }
    }
    return localPath;
}

export async function triggerDetection(addItem, setTypeName, setWindowURL, fabricCanvas, imageUrl, projectScaleFactor, setProjectScaleFactor) {

const toastId = showLoadingToast(i18n.t("startingDetection"));

    try {

        const aiDetectionResults = await fetchNetlifyFunctionForAIDetection(imageUrl);
        const detections = aiDetectionResults[0]; // Assuming this contains your detection results

        if (!detections || detections.length === 0) {
            toast.update(toastId, {
                render: <LoadingToast message="No detections found." />,
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
            return;
        }

        let realScaleFactor;
        const doorDetection = detections.find(detection => detection.class === "door");
        let localScaleFactor = null;
        if (doorDetection) {
            const scaleX = fabricCanvas.backgroundImage ? fabricCanvas.backgroundImage.scaleX : 1;
            const widthInPixels = doorDetection.tlbr[2] - doorDetection.tlbr[0];
            const heightInPixels = doorDetection.tlbr[3] - doorDetection.tlbr[1];
            realScaleFactor = heightInPixels / 82 * scaleX;
            localScaleFactor = calculateProjectScaleFactor(doorDetection);
            setProjectScaleFactor(localScaleFactor); // Update state for future re-renders
        }

        detections.forEach(detection => {
            let tName;
            if (localScaleFactor && detection.class !== "door") {
                tName = determineTNameFromDetectionWithScaleFactor(detection, localScaleFactor);
            } else {
                tName = determineTNameFromDetectionWithAspectRatio(detection);
            }

            if (!tName) {
                console.log(`No matching type found for detection:`, detection);
                return;
            }

            const doorOptionsName = determineDoorOptionsFromAI(tName);
            const adjustedCoords = adjustDetectionCoordinates(detection, fabricCanvas);
            let localPath = constructPathForDetection(tName, detection.class); // Refactored path construction into a function

            setTypeName(tName);
            setWindowURL(localPath);
            addItem(localPath, doorOptionsName, adjustedCoords, true, localScaleFactor, realScaleFactor);
        });

        updateToast(toastId,(i18n.t("detectionEnded")), toast.TYPE.SUCCESS);

    } catch (error) {
        console.error("Error in AI detection:", error);
        updateToast(toastId,(i18n.t( `detectionFailed: ${error.message}`)), toast.TYPE.ERROR);
    }
}





// Function to fetch AI detection results from your Netlify function
async function fetchNetlifyFunctionForAIDetection(imageUrl) {
    const response = await fetch('/.netlify/functions/aiDetectionsServer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageUrl }),
    });
    if (!response.ok) {
        throw new Error('AI detection failed');
    }
    return await response.json();
}


function adjustDetectionCoordinates(detection, fabricCanvas) {
    if (!detection.tlbr || detection.tlbr.length < 4) {
        console.error("Invalid detection.tlbr:", detection.tlbr);
        return null;
    }

    let scaleX = fabricCanvas.backgroundImage.scaleX;
    let scaleY = fabricCanvas.backgroundImage.scaleY;
    let offsetX = 0;
    let offsetY = 0;

    const canvasWidth = fabricCanvas.width;
    const bgImgWidth = fabricCanvas.backgroundImage.width * scaleX;
    const canvasHeight = fabricCanvas.height;
    const bgImgHeight = fabricCanvas.backgroundImage.height * scaleX;


    // Center horizontally if the background image is narrower than the canvas
    if (bgImgWidth < canvasWidth) {
        offsetX = (canvasWidth - bgImgWidth) / 2;
    }
    if (bgImgWidth < canvasWidth) {
        offsetY = (canvasHeight - bgImgHeight) / 2;
    }

    // Adjust coordinates based on scaleX and apply offsetX if needed
    const adjustedCoords = {
        left: detection.tlbr[0] * scaleX + offsetX,
        top: detection.tlbr[1] * scaleY, // We use scaleY for vertical adjustments
        width: (detection.tlbr[2] - detection.tlbr[0]) * scaleX,
        height: (detection.tlbr[3] - detection.tlbr[1]) * scaleY, // We use scaleY for vertical size adjustments
        scaleX, // Return scale factors for further usage
        scaleY,
        offsetX, // Offset in case you need horizontal adjustments
        offsetY,

    };

    return adjustedCoords;
}











