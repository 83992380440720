import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import './CustomVideoModal.css'; // Ensure this path is correct

const CustomVideoModal = ({ videos, initialVideoIndex = 0, children }) => {
    const [show, setShow] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(initialVideoIndex);
    const [isLoading, setIsLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setIsLoading(true);
        setShow(true);
    };
    const { t, i18n } = useTranslation();

    const changeVideo = (index) => {
        if (index !== currentVideoIndex) {
            setIsLoading(true);
            setCurrentVideoIndex(index);
        }
    };

    const handleVideoLoad = () => {
        setIsLoading(false);
    };

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const getCurrentVideoSrc = (index) => {
        return isSafari ? videos[index].src.replace('.webm', '.mp4') : videos[index].src;
    };

    useEffect(() => {
        setIsLoading(true);
    }, [currentVideoIndex]);

    const languageCode = i18n.language === 'fr' ? '_fr' : '_en';
    const currentVideoSrc = getCurrentVideoSrc(currentVideoIndex);

    return (
        <>
            <span onClick={handleShow} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                {children}
            </span>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{videos[currentVideoIndex].title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', overflowY: 'auto' }}>
                    {isLoading && <Spinner animation="border" variant="primary" />}
                    <div className="video-container">
                        <video
                            key={currentVideoSrc} // Use key to force re-render
                            width="100%"
                            height="auto"
                            controls
                            onCanPlayThrough={handleVideoLoad}
                            style={{ display: isLoading ? 'none' : 'block' }}
                        >
                            <source src={currentVideoSrc} type={isSafari ? "video/mp4" : "video/webm"} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <div className="thumbnails">
                        {videos.slice(0, 3).map((video, index) => {
                            const localizedThumbnail = video.thumbnail.replace(/(\.[\w\d_-]+)$/i, `${languageCode}$1`);
                            return (
                                <div key={index} onClick={() => changeVideo(index)} className="thumbnail-item">
                                    <img src={localizedThumbnail} alt={video.title} />
                                    <p>{video.title}</p>
                                </div>
                            );
                        })}
                    </div>
                    <Button variant="secondary" onClick={handleClose} style={{ marginTop: '20px' }}>
                        {t("modal-close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomVideoModal;
