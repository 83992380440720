import { v1 as uuid } from "uuid";

export const accessoriesArray = [ 
  {
    name: "accessory_regularShutterType2",
    type: "Shutters",
    style: "regular",
    url: "/shutters/shutter_standard_white.png",
    key: uuid(),
  },
  {
    name: "accessory_panelShutter",
    type: "Shutters",
    style: "panel",
    url: "/shutters/shutter_louvre_white.png",
    key: uuid(),
  },

  {
    name: "accessory_regularShutter",
    type: "Shutters",
    style: "regular",
    url: "/shutters/shutter_standard2_white.png",
    key: uuid(),
  },
  {
    name: "accessory_doorKnocker1Silver",
    type: "Decorative",
    style: "regular",
    url: "/decorative/doorKnocker1_silver.png",
    key: uuid(),
  },

];

export default accessoriesArray;