import React, { useState, useEffect } from "react";
import windowsOptionsArray from "../../components/itemSelectionView/inventory/windowsOptionsArray";
import doorsDecorativeGlassArray from "../../components/itemSelectionView/inventory/doorsDecorativeGlassArray";
import novatechDecorativeGlassArray from "../../components/itemSelectionView/inventory/novatechDecorativeGlassArray";
import transumsArray from "../../components/itemSelectionView/inventory/transumsArray";
import sizeAndPosition from "../../otherJS/doorsSizeAndPosition";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";
import { storage, getDownloadURL, ref } from "../../base";
import "../optionsSelectionView/optionsSelectionView.css";
import { Dropdown, Button, Form } from "react-bootstrap";

function OptionsSelectionView(props) {

  const { t, i18n } = useTranslation(["translation"]);
  // const [glassManufacturer, setGlassManufacturer] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noOptions, setNoOptions] = useState("No Options Available");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocalURL, setSelectedLocalURL] = useState("");

  const manufacturerOptions = [
    { value: "Other", label: "Other" },
    { value: "Novatech", label: "Novatech" },
  ];


  const urlToFind = props.windowURL;
  const itemType = props.typeName;


  let glassToSearch;

  let noGlassTypes = [
    "NoGlass",
    "Oso",
    "Gendarme",
    "Era",
    "Linea",
    "Vog",
    "Flat",
    "Plaza",
    "Fantasia",
    "Tao"
  ];

  let includesValue = noGlassTypes.some(type => urlToFind.toLowerCase().includes(type.toLowerCase()));


  var doorsCutOut = urlToFind.lastIndexOf("/");
  var stringToSearch = urlToFind.substr(doorsCutOut + 1).slice(0, -4);

  var sideCutOut = urlToFind.lastIndexOf("S");
  var stringToSearchSide = urlToFind.substr(sideCutOut).slice(0, -4);


  if (urlToFind.includes("windows")) {
    glassToSearch = itemType;
  } else glassToSearch = sizeAndPosition(stringToSearch).cutoutSize;


  function arrayChoice() {
    if (props.isTransum) {
      return transumsArray;
    } else {
      switch (selectedManufacturer) {
        case "Novatech":
          return novatechDecorativeGlassArray;
        case "Other":
          return doorsDecorativeGlassArray;

        default:
          return windowsOptionsArray;
      }
    }
  }

  const fetchSingleImageFromFirebase = async (localUrl) => {
    const storageRef = storage.ref();

    const firebaseUrl = localUrl; // assuming same names and paths. If paths or names differ, this should be adjusted accordingly.

    try {
      const imageRef = ref(storageRef, firebaseUrl);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      console.error("File does not exist:", firebaseUrl);
      return null;
    }
  };

  const handleImageClick = async (localUrl) => {
    let firebaseURL = localUrl;

    if (
      selectedManufacturer !== "" &&
      localUrl.includes(selectedManufacturer)
    ) {
      const fileName = localUrl.substring(localUrl.lastIndexOf("/") + 1);
      const updatedFileName = `${selectedManufacturer}${fileName.substring(
        fileName.indexOf("_"),
        fileName.lastIndexOf(".")
      )}`;
      firebaseURL = localUrl.replace(
        /\/doors\/decorativeGlass\/\w+/,
        `/doors/decorativeGlass/${selectedManufacturer}/${updatedFileName}`
      );
    }

    const fetchedURL = await fetchSingleImageFromFirebase(firebaseURL);
    if (fetchedURL) {
      onClickEvent(fetchedURL);
    } else {
      console.error("Failed to fetch the image from Firebase");
    }
  }


  const [imageURLs, setImageURLs] = useState([]);

  const fetchImageURLs = async () => {

    document.body.style.overflow = "auto";

    setImageURLs([]); // Clear the state

    const storageRef = storage.ref();

    for (const item of arrayChoice()) {
      if (
        item.url.includes(glassToSearch) &&
        !item.url.includes("NoGrid") &&
        !props.windowURL.includes("Sidelight3A") &&
        !props.windowURL.includes("FlatWithSidelightFlat") &&
        !props.windowURL.includes("NoGlassSidelight3A") &&
        !props.windowURL.includes("NoGlassSidelightFlat") &&
        !item.url.includes("NoDecGlass") &&
        !urlToFind.includes("Plaza") &&
        !urlToFind.includes("Fantasia")
      ) {
        if (
          selectedManufacturer !== "" &&
          item.url.includes(selectedManufacturer)
        ) {
          const fileName = item.url.substring(item.url.lastIndexOf("/") + 1);
          const updatedFileName = `${selectedManufacturer}${fileName.substring(
            fileName.indexOf("_"),
            fileName.lastIndexOf(".")
          )}`;
          const updatedUrl = item.url.replace(
            /\/doors\/decorativeGlass\/\w+/,
            `/doors/decorativeGlass/${selectedManufacturer}/${updatedFileName}`
          );

          try {
            const imageRef = ref(storageRef, updatedUrl);
            const downloadURL = await getDownloadURL(imageRef);
            setImageURLs((prevURLs) => [...prevURLs, downloadURL]);
          } catch (error) {
            console.error("File does not exist:", updatedUrl);
          }
        } else {
          const imageRef = ref(storageRef, item.url);
          const downloadURL = await getDownloadURL(imageRef);

          // Filter the URLs based on the condition
          if (urlToFind.includes("Divider")) {
            if (downloadURL.includes("Divider")) {
              setImageURLs((prevURLs) => [...prevURLs, downloadURL]);
            }
          } else {
            if (!downloadURL.includes("Divider")) {
              setImageURLs((prevURLs) => [...prevURLs, downloadURL]);
            }
          }

        }
      }
    }

    setIsLoading(false);

  };

  useEffect(() => {



    // Assuming arrayChoice provides the local URLs
    const localImageURLs = arrayChoice();


    // Check if not a window
    if (!urlToFind.includes("Window")) {
      if (glassToSearch.includes("0_0")) {
        setIsLoading(false);
        return;
      }
    }

    // Filtering logic to mimic the previous condition where you checked imageURLs
    const filteredLocalURLs = localImageURLs.filter((item) => {
      return item.url.includes(glassToSearch) &&
        !item.url.includes("NoGrid") &&
        !item.url.includes("NoDecGlass");
    });

    // Check if the filtered array is empty
    if (!isLoading && filteredLocalURLs.length === 0) {
      setNoOptions(t("optionSelection-noPossibleOptions"));
    }
    else if (includesValue) {
      setNoOptions(t("optionSelection-noPossibleOptions"));
    } else {
      setNoOptions("");
    }

  }, [glassToSearch, includesValue, urlToFind, isLoading]);





  useEffect(() => {

    if (selectedManufacturer || !props.windowURL.includes("doors")) {
      // Mimic the behavior of an async data fetch but for local data
      setTimeout(() => {
        setIsLoading(false); // Indicate that "loading" is complete
      }, 100);
    }
  }, [glassToSearch, selectedManufacturer]);

  useEffect(() => {
    if (urlToFind.includes("Door")) {
      setSelectedManufacturer("Novatech");
    } else setSelectedManufacturer("");



  }, [imageURLs, urlToFind]);

  function onClickEvent(gridUrl) {
    document.body.style.overflow = "auto";
    props.setIsTransum(false);
    if (gridUrl.includes("transum")) {
      props.addTransum(gridUrl, itemType);
    } else {
      props.addOption(gridUrl);
    }
    props.setGlassToSearch(glassToSearch);
    props.setStringToSearch(stringToSearch);
    props.setStringToSearchSide(stringToSearchSide);
    document.body.style.overflow = "auto";
  }

  function noGridOption() {

    if (props.windowURL.includes("windows")) {

      let noGridUrl = windowsOptionsArray.find(
        (item) =>
          item.url.includes("NoGrid") && item.url.includes(props.typeName)
      );

      props.addOption(noGridUrl.url);
    }

    if (props.windowURL.includes("doors")) {
      let noDecGlass = doorsDecorativeGlassArray.find(
        (item) =>
          item.url.includes("NoDecGlass") && item.url.includes(glassToSearch)
      );

      props.addOption(noDecGlass.url);
    }
  }

  // const getCategory = (url) => {
  //   const startIdx = url.indexOf("decorativeGlass%2F") + "decorativeGlass%2F".length;
  //   const endIdx = url.indexOf("_", startIdx);
  //   const category = url.substring(startIdx, endIdx);
  //   return category.charAt(0).toUpperCase() + category.slice(1).replace(/_/g, " ");
  // };

  const getGridName = (url) => {
    const startIdx = url.lastIndexOf("_", url.lastIndexOf("_") - 1);
    const endIdx = url.lastIndexOf("_GridOnly");
    const gridName = url.substring(startIdx + 1, endIdx);
    return gridName.charAt(0).toUpperCase() + gridName.slice(1);
  };

  const getName = (url) => {
    const startIdx =
      url.indexOf("decorativeGlass%2F") + "decorativeGlass%2F".length;
    const categoryEndIdx = url.indexOf("_", startIdx);
    const nameStartIdx = categoryEndIdx + 1;
    const nameEndIdx = url.lastIndexOf("_", url.lastIndexOf("_") - 1);
    const name = url.substring(nameStartIdx, nameEndIdx).replace(/_/g, " ");
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <>
      <div className="closeBtn">
        <button className="btn btn-primary"
          onClick={() => {
            props.setIsOptionsPanelVisible(false);
            document.body.style.overflow = "auto";
          }}
        >
          {t("optionSelection-close")}
        </button>
      </div>
      <div className="optionsTitle">{t("optionSelection-availableOption")}</div>
      <div className="list bg-transparent">
        {urlToFind.includes("Door") && (
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
          </Form>
        )}

        {urlToFind.includes("Door") && (
          <div className="manufacturerDropdown">
            <Dropdown>
              <Dropdown.Toggle
                variant="dark"
                id="manufacturerDropdown"
                className="custom-toggle"
              >
                {"Select a manufacturer"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {manufacturerOptions.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    onClick={() => setSelectedManufacturer(option.value)}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <div
          className="noGrid"
          onClick={noGridOption}
          style={{ cursor: "pointer" }}
        >
          {noOptions ? noOptions : t("optionSelection-noOption")}

        </div>

        {isLoading ? (
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
            <div className="dot3"></div>
          </div>
        ) : (
          <ListGroup className="optionsListItems" variant="flush">
            {props.windowURL && (
              arrayChoice().length > 0
                ? arrayChoice()
                  .filter((item) => {
                    const doesUrlIncludeDivider = item.url.includes("Divider");
                    // Filter based on the urlToFind value and the presence of "Divider" in the item's URL.
                    return urlToFind.includes("Divider")
                      ? doesUrlIncludeDivider
                      : !doesUrlIncludeDivider;
                  })
                  .filter((item) => {
                    // Adjust your conditions for local files here.
                    // For simplicity, I'm assuming you can use the same conditions.
                    return item.url.includes(glassToSearch) &&
                      !item.url.includes("NoGrid") &&
                      !item.url.includes("NoDecGlass");
                  })
                  .map((item, index) => {
                    const localUrl = `${process.env.PUBLIC_URL + item.url}`; // Adjust path accordingly
                    const name = item.url.includes("Window")
                      ? getGridName(item.url)
                      : getName(item.url);
                    return (
                      <ListGroup.Item key={index} className="bg-transparent">
                        <div className="optionInfo">
                          <span>{selectedManufacturer}</span>
                        </div>
                        <div className="optionImage" style={{ position: 'relative', cursor: 'pointer' }} onClick={() => handleImageClick(item.url)}>
                          <img
                            src={localUrl}
                            alt={name}
                            title={name}
                            style={{ maxWidth: '100%', maxHeight: '160px' }}
                          />
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0,0,0,0)', // Transparent overlay
                              zIndex: 2
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                          ></div>
                        </div>
                        <div className="optionInfo">
                          <span>{name}</span>
                        </div>
                      </ListGroup.Item>
                    );

                  })
                : <ListGroup.Item className="bg-transparent">
                  <div className="noOptionsMessage text-info">{noOptions}</div>
                </ListGroup.Item>
            )}
          </ListGroup>
        )}

      </div>
    </>
  );
}

export default OptionsSelectionView;
