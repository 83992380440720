import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation, Trans } from "react-i18next";
import { Routes, Route, Link } from 'react-router-dom';
import "../homepage/home.css";
import FeatureSection from './FeatureSection';
import Contact from "./Contact";
import PricingSection from "../../plans/PricingSection";

function Home(props) {
  const { i18n, t } = useTranslation();
  const [key, setKey] = useState(0);
  const [videoSources, setVideoSources] = useState({ webm: "", mp4: "" });

  const getMediaSource = (language) => {
    const mediaPaths = {
      en: {
        webm: "/site-images-videos/aiDetect_en.webm",
        mp4: "/site-images-videos/aiDetect_en.mp4"
      },
      fr: {
        webm: "/site-images-videos/aiDetect_fr.webm",
        mp4: "/site-images-videos/aiDetect_fr.mp4"
      }
    };

    const currentLanguage = language.startsWith('fr') ? 'fr' : 'en';
    return mediaPaths[currentLanguage];
  };

  useEffect(() => {
    const sources = getMediaSource(i18n.language);
    setVideoSources(sources);
  }, [i18n.language]);

  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    "/maisons-modeles/converted_images/maison_demo_App-min_old2.webp",
    "/maisons-modeles/converted_images/test_erase_1-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (1)-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (2)-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (3)-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (4)-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (5)-min.webp",
  ];

  useEffect(() => {
    document.body.style.overflow = "auto";
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    // Update the state to trigger a re-render
    setKey(prevKey => prevKey + 1);
  }, [i18n.language]);

  return (
    <div>
      <header className="masthead pb-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 text-center">
              <h2 className="text-white pt-3">
                <Trans>{t('masthead.transformYourHome')}</Trans>
              </h2>
              <h1 className="text-light pt-4">
                {t('masthead.dontJustImagine')}
              </h1>
              {/* <p className="text-white fw-lighter pt-2">
                {t('masthead.seeItBefore')}
              </p> */}
              <a
                href="/try"
                className="tryApp btn btn-accent mt-1"
                onClick={(e) => {
                  e.preventDefault(); // prevent the default navigation behavior
                  props.closeSignup(true);
                }}
              >
                {t('masthead.tryForFree')}
              </a>
            </div>
            <div className="col-md-5">
              <video key={videoSources.webm} autoPlay muted loop className="feature-video">
                <source src={videoSources.webm} type="video/webm" />
                <source src={videoSources.mp4} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </header>
      <section>
        <div className="container">
          {/* Feature section with a single image */}
          <div key={key}>

            {/* Additional Feature Sections */}
            <FeatureSection
              titleKey="feature2.title"
              descriptionKey="feature2.description"
              galleryItems="doors"
              reverse={true}
            />

            <FeatureSection
              titleKey="feature5.title"
              descriptionKey="feature5.description"
              galleryItems="allDoors"
            />

            <FeatureSection
              titleKey="feature4.title"
              descriptionKey="feature4.description"
              galleryItems="doorsOptions"
              altKey="homepage.testimonials.altText"
              reverse={true}
            />

            <FeatureSection
              titleKey="feature6.title"
              descriptionKey="feature6.description"
              galleryItems="windows"
            />
            <FeatureSection
              titleKey="feature7.title"
              descriptionKey="feature7.description"
              galleryItems="windowsOptions"
              reverse={true}
            />

            <FeatureSection
              titleKey="feature3.title"
              descriptionKey="feature3.description"
              mediaKey="feature3.mediaPath"
              altKey="feature3.title"
              isVideo={true}
              videoWidth="40%"
              videoHeight="auto"
            />

            <div id="howitworks">
            <h1 className="pt-4">
                {t('homepage.how_it_works.title')}
              </h1>
             <FeatureSection
              titleKey="homepage.how_it_works.title"
              descriptionKeys={[
                "homepage.how_it_works.steps.step1",
                "homepage.how_it_works.steps.step2",
                "homepage.how_it_works.steps.step3",
                "homepage.how_it_works.steps.step4",
                "homepage.how_it_works.steps.step5",
                "homepage.how_it_works.steps.step6",
                "homepage.how_it_works.steps.step7",
                "homepage.how_it_works.steps.step8",
                "homepage.how_it_works.steps.step9",
              ]}
              closeSignup={props.closeSignup}
              mediaKey="feature1.mediaPath"
              altKey="homepage.how_it_works.title"
              isVideo={true}
              videoWidth="100%"  // Adjusted here to fill the container width controlled by CSS
              videoHeight="auto"
              showIcons={true}
              className="feature-video"  // Ensure this is applied if using custom video tag
            />
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <Contact />
        <section id="designerBot">
        {/* <Contact /> */}
      </section>
      </section>
      {/* <section id="pricing">
        <PricingSection />
      </section> */}
    </div>
  );
}

export default Home;
