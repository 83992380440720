import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });

      if (response.ok) {
        toast.success(t("toast.successMessage"));
      } else {
        toast.warn(t("toast.errorMessage"));
        setError('Error sending feedback');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  return (
    <div className="container contactContainer mt-5">
      <h2 className="text-center mb-4">{t('contactForm.title')}</h2>
      <div className="row">
        {/* Left Section: Contact Form */}
        <div className="col-md-6">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t('contactForm.name')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleChange}
                value={formData.name}
                placeholder={t("contactForm.namePlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('contactForm.email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                placeholder={t("contactForm.emailPlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('contactForm.message')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                onChange={handleChange}
                value={formData.message}
                placeholder={t("contactForm.messagePlaceholder")}
                required
              />
            </Form.Group>
            <div className = "contactFormButton">
            <Button variant="primary" type="submit">
              {t('submit')}
            </Button>
            </div>
            {error && <p className="text-danger">{error}</p>}
          </Form>
        </div>
        
        {/* Right Section: Help Options */}
        <div className="col-md-6">
          <h3>{t('needHelp')}</h3>
          <p>{t('getHelpOption')}</p>
          <ul>
            <li>{t('faqOption')}</li>
            <li>{t('supportOption')}</li>
            <li>{t('callOption')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
